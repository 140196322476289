import './swipe-events';
import App from './App.svelte';

window.addEventListener('resize', resetSize);

function resetSize() {
  document.body.style.height = window.innerHeight + 'px';
  document.body.style.width = window.innerWidth + 'px';
}

resetSize();

const app = new App({
  target: document.body
});

export default app;
