<style lang="scss">

</style>

<script>
  import { TweenMax, TimelineLite } from 'gsap';
  import { getGtag } from '../tracking';
  import Button from './Button.svelte';

  async function trackEventOnce(category, action) {
    if (typeof gtag === 'undefined') {
      gtag = await getGtag();
    }

    gtag.trackEventOnce(category, action);
  }

  let sharingButton;
  let isMobile;

  // const isAndoid = !!navigator.userAgent.match(/android/i);

  isMobile = !window.matchMedia('(min-width: 1024px)').matches;

  function share() {
    if (navigator.share) {
      navigator
        .share({
          text: 'Рух друзів ЛГБТК+ на підтримку рівності для всіх.',
          url: appUrl
        })
        .then(() => console.log('Successful share'))
        .catch(error => console.log('Error sharing', error));
    } else {
      window.open('https://www.facebook.com/sharer/?u=https%3A%2F%2Fwww.riznirivni.com.ua');
    }
  }

  function shareLinkClick() {
    if (isMobile) {
      share();
    }
    if (!isMobile) {
      window.open('https://www.facebook.com/sharer/?u=https%3A%2F%2Fwww.riznirivni.com.ua');
    }
  }
</script>

<Button text="Поділитись" width="240" bind:this="{sharingButton}" on:click="{shareLinkClick}" />
