<style lang="scss">
  .scroll-arrow {
    position: fixed;
    bottom: 16px;
    right: 27px;
    cursor: pointer;
    z-index: 999999;
    width: 9vh;
    height: 9vh;

    svg {
      width: 100%;
      height: 100%;
    }
  }
</style>

<script>
  import { TimelineMax } from 'gsap';
  import { createEventDispatcher, onMount } from 'svelte';

  let scrollArrowRef;

  const dispatch = createEventDispatcher();

  onMount(() => {
    const tl = new TimelineMax();

    tl.to(scrollArrowRef, 0.2, { y: '-50%' }, 4)
      .to(scrollArrowRef, 0.2, { y: '0' }, 4.2)
      .to(scrollArrowRef, 0.2, { y: '-50%' }, 4.4)
      .to(scrollArrowRef, 0.2, { y: '0' }, 4.6);

    tl.repeat(99999);
  });
</script>

<div class="scroll-arrow" on:click="{() => dispatch('click')}">
  <svg
    bind:this="{scrollArrowRef}"
    width="20vw"
    height="10vh"
    viewBox="0 0 47 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M22.2322 1.13299C25.0904 1.13299 27.9657 2.40194 30.5523 4.95117L45.3869 18.5867L42.5287
        21.5835L23.9471 3.44429L24.2414 40.8387L20.8115 40.8613L20.5172 3.44429L4.68076
        19.7141L1.681 17.0232C1.681 17.0232 8.13897 11.0353 13.6517 5.26275C16.2666 2.51524 19.2437
        1.13299 22.2322 1.13299ZM22.2322 0C18.8758 0 15.627 1.54653 12.831 4.47532C7.40318 10.1573
        0.973507 16.1338 0.911247 16.1905L0 17.0345L0.922567 17.8616L3.92233 20.5525L4.73169
        21.2776L5.49012 20.5015L19.4079 6.20313L19.6796 40.867L19.6909 42L20.8229 41.9943L24.2528
        41.9717L25.3848 41.966L25.3734 40.833L25.1018 6.15781L41.7363 22.3992L42.557 23.198L43.3493
        22.3709L46.2076 19.3742L47.0057 18.5414L46.1567 17.7596L31.3333 4.12975C28.5883 1.42757
        25.4414 0 22.2322 0Z"
        fill="#141414"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="47" height="42" fill="white"></rect>
      </clipPath>
    </defs>
  </svg>
</div>
