<style lang="scss">
  .sharing {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
    .text {
      align-self: center;
      margin-bottom: 12px;
      font-family: Montserrat;
      letter-spacing: 0.04em;
      color: #000000;
      opacity: 0.8;
      max-width: 270px;
      font-size: 10px;
      line-height: 12px;
      opacity: 0.8;
    }
  }

  .icons {
    display: flex;
    margin-bottom: 17px;
    @media (min-width: 800px) {
      margin-bottom: 17px;
    }
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 33px;
      height: 33px;
      padding: 4px;
      background: #94b6c5;
      border-radius: 50%;
      margin-left: 18px;
      &:first-child {
        margin-left: 0;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
    .yellow {
      background: #feb903;
    }
  }
</style>

<script>
  import { TweenMax, TimelineLite } from 'gsap';
  import { getGtag } from '../tracking';
  export let text = '';

  async function trackEventOnce(category, action) {
    if (typeof gtag === 'undefined') {
      gtag = await getGtag();
    }

    gtag.trackEventOnce(category, action);
  }
</script>

<div class="sharing">
  {#if text !== ''}
    <div class="text">{text}</div>
  {/if}
  <div class="icons">
    <a
      class="icon"
      href="https://www.facebook.com/rizni.rivni"
      target="_blank"
      on:click="{() => trackEventOnce('footer', 'fb_click')}"
    >
      <img src="/images/facebook.svg" alt="facebook" />
    </a>
    <a
      class="icon yellow"
      href="https://www.instagram.com/rizni.rivni/"
      target="_blank"
      on:click="{() => trackEventOnce('footer', 'ig_click')}"
    >
      <img src="/images/instagram.svg" alt="instagram" />
    </a>
  </div>
</div>
