<style lang="scss">
  @import '../styles/main.scss';

  .wrapper {
    display: flex;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    background: $white;
    // overflow: hidden;

    .inner-content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      .logo {
        grid-column: span 1;
        display: flex;
        height: 100%;
        width: 100%;
        max-width: 640px;
        img {
          width: 100%;
          height: 100%;
          object-position: center;
          object-fit: cover;
          max-width: 640px;
        }
      }

      .content {
        grid-column: span 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 27px;

        align-items: center;
        justify-content: center;
        padding-top: 40px;
        height: 100%;

        .description {
          letter-spacing: 0.04em;
          font-size: 24px;
          line-height: 35px;
          color: #000000;
          font-family: Montserrat;
          align-self: flex-start;
        }

        .created-by {
          font-size: 12px;
          line-height: 15px;
          letter-spacing: 0.03em;
          color: rgba(0, 0, 0, 0.7);

          a {
            text-decoration: none;
            color: rgba(0, 0, 0, 0.7);
          }
        }
      }
    }
    .square {
      margin-left: 20px;
    }
    .map-button {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin: 20px 0;
      max-width: 480px;
      height: 130px;
      min-height: 110px;
      border: 1px solid $black;
      border-radius: 83px;
      box-sizing: border-box;
      overflow: hidden;
      cursor: pointer;
      text-decoration: none;
      padding: 0px 10px;
      .map-button-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 40px;
        width: 96%;
        height: 80%;
        position: relative;
        border: 1px solid $black;
        font-family: 'Transgender Grotesk';
        justify-content: center;
        .map-button-text {
          font-size: 13px;
          line-height: 20px;
          text-align: center;
          letter-spacing: 0.25em;
          text-transform: uppercase;
          color: $black;
          align-items: center;
          white-space: nowrap;
          justify-content: center;
          text-align: center;
        }
      }
    }
    .qr {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin: 60px 0 37px;
      width: 100%;
      max-width: 700px;

      height: 150px;
      min-height: 130px;
      border-radius: 40px;
      box-sizing: border-box;

      .qr-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        position: relative;
        font-family: $transFont;

        .text {
          display: flex;
          flex-direction: column;
          max-width: 300px;
          font-family: Montserrat;
          font-size: 18px;
          line-height: 27px;
          color: #000000;
          a {
            color: #000;
          }
        }
      }
    }
  }
</style>

<script>
  import { initSocket } from '../helpers/websocket';
  import { onMount } from 'svelte';
  import { getGtag } from '../tracking';
  import gsap, { TweenMax } from 'gsap';
  import Footer from './Footer.svelte';

  let socket;
  let counter = '';
  let href = appUrl + `/map`;
  let wrapperRef;

  export async function enterAnimation() {
    gtag = await getGtag();
    // gtag.page('/museum');
    TweenMax.to(wrapperRef, 0.4, { opacity: '1' });
  }

  export async function connectToSocket() {
    socket = initSocket(onMessage);
    let gtag = await getGtag();
    gtag.page('/stub');
  }

  function onMessage(e) {
    if (e.type === 'counter') {
      counter = e.data;
    }
  }
</script>

<div class="wrapper" bind:this="{wrapperRef}">
  <div class="inner-content">
    <a href="https://logo.riznirivni.com.ua" target="_blank" class="logo">
      <img src="/images/collection-page-image.png" alt="" />
    </a>
    <div class="content">
      <span class="description">
        Перша в світі колекцiя рiвностi від LITKOVSKAYA, KSENIASCHNAIDER і BEVZA. Її не можна
        придбати. Лише отримати, якщо підтримуєш рівні права для ЛГБТК+ спільноти.
      </span>
      <div class="qr">
        <div class="qr-inner">
          <span class="text">
            Щоб отримати колекцію,
            <br />
            переходь на мобільну
            <br />
            версію сайту
            <br />
            <a href="https://www.riznirivni.com.ua/">www.riznirivni.com.ua</a>
          </span>
          <div class="arrow">
            <svg
              class="arrow"
              width="60"
              height="60"
              viewBox="0 0 85 78"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <path
                  d="M76.8 54.3C82 48.8 83.7 44.3 84 38.6C83.7 32.9 82 28.4 76.8 22.9L54.8
                  0.700012L48.7 6.80001L75.4 34.6H0.5V38.7V42.8H75.3L48.6 70.5L54.7 76.6L76.8 54.3Z"
                  stroke="black"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="84.5" height="77.3" fill="white"></rect>
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="square">
            <svg
              class="qr-code"
              width="145"
              height="145"
              viewBox="0 0 200 200"
              version="1.1"
              baseProfile="tiny"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect shape-rendering="optimizeSpeed" x="0" y="0" fill="white"></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="0"
                y="0"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="5"
                y="0"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="10"
                y="0"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="15"
                y="0"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="20"
                y="0"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="25"
                y="0"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="30"
                y="0"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="45"
                y="0"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="50"
                y="0"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="55"
                y="0"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="65"
                y="0"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="70"
                y="0"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="75"
                y="0"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="85"
                y="0"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="90"
                y="0"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="105"
                y="0"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="125"
                y="0"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="150"
                y="0"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="155"
                y="0"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="160"
                y="0"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="165"
                y="0"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="170"
                y="0"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="175"
                y="0"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="180"
                y="0"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="0"
                y="5"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="30"
                y="5"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="55"
                y="5"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="60"
                y="5"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="75"
                y="5"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="85"
                y="5"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="90"
                y="5"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="100"
                y="5"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="110"
                y="5"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="130"
                y="5"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="140"
                y="5"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="150"
                y="5"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="180"
                y="5"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="0"
                y="10"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="10"
                y="10"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="15"
                y="10"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="20"
                y="10"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="30"
                y="10"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="45"
                y="10"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="50"
                y="10"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="55"
                y="10"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="70"
                y="10"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="80"
                y="10"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="95"
                y="10"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="100"
                y="10"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="105"
                y="10"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="135"
                y="10"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="140"
                y="10"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="150"
                y="10"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="160"
                y="10"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="165"
                y="10"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="170"
                y="10"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="180"
                y="10"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="0"
                y="15"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="10"
                y="15"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="15"
                y="15"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="20"
                y="15"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="30"
                y="15"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="40"
                y="15"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="50"
                y="15"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="55"
                y="15"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="70"
                y="15"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="75"
                y="15"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="85"
                y="15"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="95"
                y="15"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="105"
                y="15"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="125"
                y="15"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="150"
                y="15"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="160"
                y="15"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="165"
                y="15"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="170"
                y="15"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="180"
                y="15"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="0"
                y="20"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="10"
                y="20"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="15"
                y="20"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="20"
                y="20"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="30"
                y="20"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="40"
                y="20"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="50"
                y="20"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="60"
                y="20"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="65"
                y="20"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="70"
                y="20"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="75"
                y="20"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="90"
                y="20"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="100"
                y="20"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="115"
                y="20"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="125"
                y="20"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="140"
                y="20"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="150"
                y="20"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="160"
                y="20"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="165"
                y="20"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="170"
                y="20"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="180"
                y="20"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="0"
                y="25"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="30"
                y="25"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="45"
                y="25"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="50"
                y="25"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="65"
                y="25"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="70"
                y="25"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="80"
                y="25"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="90"
                y="25"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="100"
                y="25"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="110"
                y="25"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="120"
                y="25"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="130"
                y="25"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="140"
                y="25"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="150"
                y="25"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="180"
                y="25"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="0"
                y="30"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="5"
                y="30"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="10"
                y="30"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="15"
                y="30"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="20"
                y="30"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="25"
                y="30"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="30"
                y="30"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="40"
                y="30"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="50"
                y="30"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="60"
                y="30"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="70"
                y="30"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="80"
                y="30"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="90"
                y="30"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="100"
                y="30"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="110"
                y="30"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="120"
                y="30"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="130"
                y="30"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="140"
                y="30"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="150"
                y="30"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="155"
                y="30"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="160"
                y="30"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="165"
                y="30"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="170"
                y="30"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="175"
                y="30"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="180"
                y="30"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="45"
                y="35"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="60"
                y="35"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="65"
                y="35"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="100"
                y="35"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="110"
                y="35"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="115"
                y="35"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="130"
                y="35"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="135"
                y="35"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="140"
                y="35"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="0"
                y="40"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="5"
                y="40"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="25"
                y="40"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="30"
                y="40"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="35"
                y="40"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="45"
                y="40"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="50"
                y="40"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="60"
                y="40"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="70"
                y="40"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="75"
                y="40"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="85"
                y="40"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="95"
                y="40"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="105"
                y="40"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="110"
                y="40"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="120"
                y="40"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="130"
                y="40"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="140"
                y="40"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="160"
                y="40"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="165"
                y="40"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="15"
                y="45"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="20"
                y="45"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="35"
                y="45"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="50"
                y="45"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="70"
                y="45"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="85"
                y="45"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="90"
                y="45"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="95"
                y="45"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="105"
                y="45"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="115"
                y="45"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="120"
                y="45"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="125"
                y="45"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="135"
                y="45"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="140"
                y="45"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="155"
                y="45"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="160"
                y="45"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="175"
                y="45"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="5"
                y="50"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="10"
                y="50"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="20"
                y="50"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="30"
                y="50"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="35"
                y="50"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="40"
                y="50"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="50"
                y="50"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="55"
                y="50"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="60"
                y="50"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="110"
                y="50"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="125"
                y="50"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="130"
                y="50"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="135"
                y="50"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="140"
                y="50"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="160"
                y="50"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="165"
                y="50"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="170"
                y="50"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="180"
                y="50"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="0"
                y="55"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="5"
                y="55"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="40"
                y="55"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="55"
                y="55"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="70"
                y="55"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="75"
                y="55"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="80"
                y="55"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="100"
                y="55"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="110"
                y="55"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="120"
                y="55"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="130"
                y="55"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="135"
                y="55"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="140"
                y="55"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="145"
                y="55"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="150"
                y="55"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="175"
                y="55"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="5"
                y="60"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="20"
                y="60"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="30"
                y="60"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="35"
                y="60"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="65"
                y="60"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="70"
                y="60"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="95"
                y="60"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="115"
                y="60"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="140"
                y="60"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="150"
                y="60"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="165"
                y="60"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="175"
                y="60"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="180"
                y="60"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="0"
                y="65"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="10"
                y="65"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="20"
                y="65"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="35"
                y="65"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="40"
                y="65"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="50"
                y="65"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="60"
                y="65"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="65"
                y="65"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="70"
                y="65"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="95"
                y="65"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="100"
                y="65"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="105"
                y="65"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="115"
                y="65"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="120"
                y="65"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="130"
                y="65"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="135"
                y="65"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="145"
                y="65"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="155"
                y="65"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="165"
                y="65"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="170"
                y="65"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="175"
                y="65"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="0"
                y="70"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="15"
                y="70"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="30"
                y="70"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="35"
                y="70"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="45"
                y="70"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="50"
                y="70"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="65"
                y="70"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="70"
                y="70"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="75"
                y="70"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="80"
                y="70"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="90"
                y="70"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="100"
                y="70"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="105"
                y="70"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="110"
                y="70"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="120"
                y="70"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="125"
                y="70"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="135"
                y="70"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="140"
                y="70"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="145"
                y="70"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="150"
                y="70"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="155"
                y="70"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="170"
                y="70"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="175"
                y="70"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="180"
                y="70"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="5"
                y="75"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="20"
                y="75"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="35"
                y="75"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="45"
                y="75"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="60"
                y="75"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="65"
                y="75"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="70"
                y="75"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="80"
                y="75"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="90"
                y="75"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="115"
                y="75"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="120"
                y="75"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="140"
                y="75"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="170"
                y="75"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="180"
                y="75"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="15"
                y="80"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="25"
                y="80"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="30"
                y="80"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="35"
                y="80"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="40"
                y="80"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="45"
                y="80"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="60"
                y="80"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="70"
                y="80"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="75"
                y="80"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="85"
                y="80"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="100"
                y="80"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="110"
                y="80"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="125"
                y="80"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="130"
                y="80"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="135"
                y="80"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="145"
                y="80"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="150"
                y="80"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="160"
                y="80"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="165"
                y="80"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="170"
                y="80"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="180"
                y="80"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="15"
                y="85"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="35"
                y="85"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="50"
                y="85"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="80"
                y="85"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="90"
                y="85"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="95"
                y="85"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="100"
                y="85"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="115"
                y="85"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="120"
                y="85"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="125"
                y="85"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="135"
                y="85"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="140"
                y="85"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="155"
                y="85"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="165"
                y="85"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="170"
                y="85"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="175"
                y="85"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="5"
                y="90"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="10"
                y="90"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="15"
                y="90"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="20"
                y="90"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="25"
                y="90"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="30"
                y="90"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="35"
                y="90"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="40"
                y="90"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="45"
                y="90"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="60"
                y="90"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="65"
                y="90"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="70"
                y="90"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="80"
                y="90"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="85"
                y="90"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="90"
                y="90"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="95"
                y="90"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="100"
                y="90"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="105"
                y="90"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="115"
                y="90"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="120"
                y="90"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="135"
                y="90"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="155"
                y="90"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="180"
                y="90"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="5"
                y="95"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="10"
                y="95"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="15"
                y="95"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="20"
                y="95"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="25"
                y="95"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="35"
                y="95"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="45"
                y="95"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="50"
                y="95"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="55"
                y="95"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="70"
                y="95"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="75"
                y="95"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="80"
                y="95"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="95"
                y="95"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="100"
                y="95"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="110"
                y="95"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="120"
                y="95"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="130"
                y="95"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="140"
                y="95"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="155"
                y="95"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="160"
                y="95"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="165"
                y="95"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="180"
                y="95"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="0"
                y="100"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="5"
                y="100"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="15"
                y="100"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="30"
                y="100"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="40"
                y="100"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="45"
                y="100"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="50"
                y="100"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="55"
                y="100"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="80"
                y="100"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="85"
                y="100"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="110"
                y="100"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="125"
                y="100"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="130"
                y="100"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="135"
                y="100"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="140"
                y="100"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="145"
                y="100"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="150"
                y="100"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="160"
                y="100"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="170"
                y="100"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="175"
                y="100"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="180"
                y="100"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="0"
                y="105"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="5"
                y="105"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="20"
                y="105"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="25"
                y="105"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="35"
                y="105"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="40"
                y="105"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="45"
                y="105"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="60"
                y="105"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="65"
                y="105"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="80"
                y="105"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="85"
                y="105"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="90"
                y="105"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="95"
                y="105"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="100"
                y="105"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="110"
                y="105"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="115"
                y="105"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="120"
                y="105"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="135"
                y="105"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="155"
                y="105"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="160"
                y="105"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="165"
                y="105"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="170"
                y="105"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="175"
                y="105"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="0"
                y="110"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="5"
                y="110"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="25"
                y="110"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="30"
                y="110"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="35"
                y="110"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="40"
                y="110"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="50"
                y="110"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="65"
                y="110"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="70"
                y="110"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="75"
                y="110"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="80"
                y="110"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="90"
                y="110"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="105"
                y="110"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="110"
                y="110"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="120"
                y="110"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="130"
                y="110"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="135"
                y="110"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="160"
                y="110"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="170"
                y="110"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="175"
                y="110"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="180"
                y="110"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="5"
                y="115"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="10"
                y="115"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="15"
                y="115"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="35"
                y="115"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="40"
                y="115"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="45"
                y="115"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="50"
                y="115"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="60"
                y="115"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="65"
                y="115"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="95"
                y="115"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="115"
                y="115"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="120"
                y="115"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="130"
                y="115"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="135"
                y="115"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="140"
                y="115"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="145"
                y="115"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="150"
                y="115"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="160"
                y="115"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="165"
                y="115"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="180"
                y="115"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="10"
                y="120"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="15"
                y="120"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="20"
                y="120"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="25"
                y="120"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="30"
                y="120"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="35"
                y="120"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="55"
                y="120"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="60"
                y="120"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="65"
                y="120"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="75"
                y="120"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="80"
                y="120"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="95"
                y="120"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="100"
                y="120"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="115"
                y="120"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="140"
                y="120"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="145"
                y="120"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="150"
                y="120"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="180"
                y="120"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="0"
                y="125"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="5"
                y="125"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="10"
                y="125"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="20"
                y="125"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="35"
                y="125"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="40"
                y="125"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="45"
                y="125"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="50"
                y="125"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="80"
                y="125"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="90"
                y="125"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="95"
                y="125"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="100"
                y="125"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="105"
                y="125"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="115"
                y="125"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="120"
                y="125"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="130"
                y="125"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="135"
                y="125"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="155"
                y="125"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="165"
                y="125"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="175"
                y="125"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="0"
                y="130"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="10"
                y="130"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="25"
                y="130"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="30"
                y="130"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="35"
                y="130"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="45"
                y="130"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="50"
                y="130"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="55"
                y="130"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="60"
                y="130"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="85"
                y="130"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="100"
                y="130"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="105"
                y="130"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="120"
                y="130"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="125"
                y="130"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="135"
                y="130"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="140"
                y="130"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="145"
                y="130"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="155"
                y="130"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="170"
                y="130"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="175"
                y="130"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="180"
                y="130"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="0"
                y="135"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="15"
                y="135"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="20"
                y="135"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="25"
                y="135"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="55"
                y="135"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="75"
                y="135"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="115"
                y="135"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="120"
                y="135"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="130"
                y="135"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="165"
                y="135"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="170"
                y="135"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="180"
                y="135"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="0"
                y="140"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="20"
                y="140"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="30"
                y="140"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="40"
                y="140"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="50"
                y="140"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="55"
                y="140"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="85"
                y="140"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="110"
                y="140"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="125"
                y="140"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="130"
                y="140"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="140"
                y="140"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="145"
                y="140"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="150"
                y="140"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="155"
                y="140"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="160"
                y="140"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="170"
                y="140"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="40"
                y="145"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="60"
                y="145"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="65"
                y="145"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="70"
                y="145"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="85"
                y="145"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="90"
                y="145"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="95"
                y="145"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="100"
                y="145"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="110"
                y="145"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="115"
                y="145"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="140"
                y="145"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="160"
                y="145"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="165"
                y="145"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="0"
                y="150"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="5"
                y="150"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="10"
                y="150"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="15"
                y="150"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="20"
                y="150"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="25"
                y="150"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="30"
                y="150"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="40"
                y="150"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="45"
                y="150"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="50"
                y="150"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="55"
                y="150"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="75"
                y="150"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="80"
                y="150"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="95"
                y="150"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="105"
                y="150"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="115"
                y="150"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="120"
                y="150"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="130"
                y="150"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="140"
                y="150"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="150"
                y="150"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="160"
                y="150"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="180"
                y="150"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="0"
                y="155"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="30"
                y="155"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="40"
                y="155"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="45"
                y="155"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="60"
                y="155"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="65"
                y="155"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="75"
                y="155"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="90"
                y="155"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="95"
                y="155"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="100"
                y="155"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="110"
                y="155"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="115"
                y="155"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="120"
                y="155"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="130"
                y="155"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="135"
                y="155"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="140"
                y="155"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="160"
                y="155"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="175"
                y="155"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="0"
                y="160"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="10"
                y="160"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="15"
                y="160"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="20"
                y="160"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="30"
                y="160"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="45"
                y="160"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="50"
                y="160"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="60"
                y="160"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="70"
                y="160"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="75"
                y="160"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="80"
                y="160"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="85"
                y="160"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="90"
                y="160"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="100"
                y="160"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="110"
                y="160"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="125"
                y="160"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="130"
                y="160"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="135"
                y="160"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="140"
                y="160"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="145"
                y="160"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="150"
                y="160"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="155"
                y="160"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="160"
                y="160"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="165"
                y="160"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="170"
                y="160"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="175"
                y="160"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="0"
                y="165"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="10"
                y="165"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="15"
                y="165"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="20"
                y="165"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="30"
                y="165"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="45"
                y="165"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="70"
                y="165"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="75"
                y="165"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="85"
                y="165"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="95"
                y="165"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="105"
                y="165"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="110"
                y="165"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="115"
                y="165"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="130"
                y="165"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="135"
                y="165"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="145"
                y="165"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="150"
                y="165"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="165"
                y="165"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="180"
                y="165"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="0"
                y="170"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="10"
                y="170"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="15"
                y="170"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="20"
                y="170"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="30"
                y="170"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="45"
                y="170"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="55"
                y="170"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="60"
                y="170"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="75"
                y="170"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="80"
                y="170"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="100"
                y="170"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="105"
                y="170"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="110"
                y="170"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="130"
                y="170"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="140"
                y="170"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="155"
                y="170"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="165"
                y="170"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="170"
                y="170"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="175"
                y="170"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="180"
                y="170"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="0"
                y="175"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="30"
                y="175"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="40"
                y="175"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="55"
                y="175"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="70"
                y="175"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="100"
                y="175"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="110"
                y="175"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="130"
                y="175"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="140"
                y="175"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="150"
                y="175"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="160"
                y="175"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="165"
                y="175"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="180"
                y="175"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="0"
                y="180"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="5"
                y="180"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="10"
                y="180"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="15"
                y="180"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="20"
                y="180"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="25"
                y="180"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="30"
                y="180"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="40"
                y="180"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="45"
                y="180"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="65"
                y="180"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="70"
                y="180"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="75"
                y="180"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="110"
                y="180"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="130"
                y="180"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="140"
                y="180"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="155"
                y="180"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="160"
                y="180"
                width="5"
                height="5"
                fill="black"
              ></rect>
              <rect
                shape-rendering="optimizeSpeed"
                x="180"
                y="180"
                width="5"
                height="5"
                fill="black"
              ></rect>
            </svg>
          </div>
        </div>

      </div>
      <div class="">
        <Footer />
      </div>
      <!-- <span class="created-by">
        ПРОЄКТ РІЗНІ.РІВНІ РЕАЛІЗОВАНО ЗА ІНІЦІАТИВИ ГРОМАДСЬКИХ ОРГАНІЗАЦІЙ: Гендер Зед, Інша,
        КиївПрайд, Ліга, Сфера, Освітній Центр з Прав Людини у Львові за підтримки Національного
        Демократичного Інституту в Україні та урядів Великобританії, Швеції та Канади.
        <br />
        ІДЕЯ ТА РОЗРОБКА ПРОЄКТУ: Технологічна креативна агенція
        <a href="https://isd-group.com/" target="_blank">[isdgroup]</a>
        .
      </span> -->
    </div>
  </div>
</div>
