<style lang="scss">
  @import '../../styles/variables.scss';

  .recorder-wrapper {
    position: relative;
    height: 100vh;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    background-image: url('/images/recorder-bg.jpg');
    background-size: cover;
  }

  .popup-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(#3f3f3f, 0.8);
    z-index: 10;

    .popup {
      @include flex-column(center, center);
      padding: 42px 24px;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 90%;
      max-width: 380px;
      background: #fafafa;
      transform: translate(-50%, -50%);
      box-shadow: 0px -1px 10px rgba(182, 182, 182, 0.25);
      border-radius: 20px;
    }

    .text {
      text-align: center;
    }

    .button-wrapper {
      margin-top: 24px;
    }
  }

  .recorder-countdown {
    @media (orientation: landscape) {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    line-height: 1;
    text-align: center;
  }

  .hidden {
    visibility: hidden;
  }

  .recorder-content {
    padding: 25px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .recorder-count {
    position: absolute;
    color: white;
    font-size: 260px;
    top: 50%;
    left: 50%;
    color: rgba(255, 255, 255, 0.6);
    transform: translate(-50%, -50%);
  }

  .recorder-text {
    display: flex;
    justify-content: space-between;
    color: #444444;
    font-size: 13px;
    height: 50vh;

    @media (orientation: portrait) {
      text-align: center;
      flex-direction: column;
      align-items: center;
    }
  }

  .recorder-circles {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    position: relative;
  }

  .recorder-cancel {
    color: #737272;
    opacity: 0.6;
    font-size: 12px;
    cursor: pointer;
    margin-top: 12px;
  }

  .recorder-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 180px;

    @media (orientation: portrait) {
      margin-bottom: 0;
      flex-grow: 1;
    }
  }

  .recorder-description {
    margin-top: 0;
    line-height: 28px;
    display: flex;
  }
</style>

<script>
  import Button from '../Button.svelte';
  import AudioWave from './AudioWave.svelte';
  import { onMount, createEventDispatcher } from 'svelte';
  import { fade } from 'svelte/transition';
  import { TweenMax } from 'gsap';
  import Countdown from './Countdown.svelte';
  import { getGtag } from '../../tracking';
  import Recorder from './RecorderService';

  const dispatch = createEventDispatcher();

  const recordingStates = {
    none: 'none',
    recording: 'recording',
    recorded: 'recorded'
  };

  let state = recordingStates.none;
  let points = [];
  let streamRef;
  let gtag;

  const recorder = new Recorder();

  /**
   * Refs
   */
  let mediaRecorderRef;
  let countdownRef;
  let buttonRef;
  let audioWaveRef;
  let wrapperRef;
  let audioRef;

  let recordedAudio;
  let isShowPopup = false;
  let recordedAudioSrc;

  const reader = new FileReader();

  function setZIndex() {
    TweenMax.set(wrapperRef, { zIndex: -1 });
  }

  onMount(async () => {
    document.addEventListener('recording', value => {
      const { detail } = value;
      recordedAudio = detail.recording.blob;
    });
    TweenMax.set(wrapperRef, { opacity: 0, y: '100%' });
    checkDeviceAndBrowser();
  });

  function checkDeviceAndBrowser() {
    const webkit = !!navigator.userAgent.match(/WebKit/i);
    const iOS = !!navigator.userAgent.match(/iPad/i) || !!navigator.userAgent.match(/iPhone/i);
    const safari = webkit && !/(Chrome|CriOS|OPiOS)/.test(navigator.userAgent);

    if (iOS && !safari) {
      isShowPopup = true;
    }

    return isShowPopup;
  }

  function closePopup() {
    isShowPopup = false;
  }

  function audioRecorded() {
    reader.readAsDataURL(recordedAudio);
    reader.onloadend = () => {
      TweenMax.to(wrapperRef, 0.3, {
        opacity: 0,
        onComplete: () => {
          recordedAudioSrc = reader.result;
          dispatch('recorded', reader.result);
        }
      });
    };
  }

  function send() {
    TweenMax.to(wrapperRef, 0.3, {
      opacity: 0,
      onComplete: () => {
        dispatch('recorded', recordedAudio);
      }
    });
  }

  function setValueToStorage() {
    sessionStorage.setItem('onAudioRecorder', 'true');
  }

  async function trackEventOnce(category, action) {
    if (typeof gtag === 'undefined') {
      gtag = await getGtag();
    }

    gtag.trackEventOnce(category, action);
  }

  export async function init() {
    setValueToStorage();
    TweenMax.set(wrapperRef, { opacity: 0, y: 0 });
    TweenMax.to(wrapperRef, 0.4, { opacity: 1 });
    gtag = await getGtag();
    gtag.page('/record');
  }

  async function startRecording(recording) {
    if (!recording) {
      return;
    }

    state = recordingStates.recording;
    trackEventOnce('record', 'mic_allow');
    audioWaveRef.startRecording();
    countdownRef.start();
  }

  function restart() {
    state = recordingStates.recording;
    trackEventOnce('record', 'again');
    audioWaveRef.startRecording();
    countdownRef.restart();
    recorder.startRecording();
  }

  function onButtonClick() {
    if (checkDeviceAndBrowser()) {
      return;
    }

    if (state === recordingStates.recorded) {
      trackEventOnce('record', 'save');
      return audioRecorded();
    }

    trackEventOnce('record', 'agree');

    try {
      recorder
        .startRecording()
        .then(startRecording)
        .catch(e => {
          console.log('ERROR ->', e);
          TweenMax.to(wrapperRef, 0.3, {
            opacity: 0,
            onComplete: () => {
              setZIndex();
              trackEventOnce('record', 'mic_deny');
              dispatch('not-allowed');
            }
          });
        });
    } catch (e) {
      TweenMax.to(wrapperRef, 0.3, {
        opacity: 0,
        onComplete: () => {
          console.log('ERROR ->', e);
          setZIndex();
          trackEventOnce('record', 'mic_deny');
          dispatch('not-allowed');
        }
      });
    }
  }

  function stopRecording() {
    audioWaveRef.stopRecording();
    recorder.stopRecording();
    state = recordingStates.recorded;
  }

  function cancelRecord() {
    trackEventOnce('record', 'disagree');
    dispatch('cancel-record');
  }
  //
  // function requestMediaDevices() {
  //   try {
  //     return navigator.mediaDevices.getUserMedia({ audio: true, video: false }).catch(() => {
  //       TweenMax.to(wrapperRef, 0.3, {
  //         opacity: 0,
  //         onComplete: () => {
  //           dispatch('not-allowed');
  //           setZIndex();
  //         }
  //       });
  //     });
  //   } catch (e) {
  //     TweenMax.to(wrapperRef, 0.3, {
  //       opacity: 0,
  //       onComplete: () => {
  //         dispatch('not-allowed');
  //         setZIndex();
  //       }
  //     });
  //     return Promise.resolve();
  //   }
  // }
</script>

<div class="recorder-wrapper" bind:this="{wrapperRef}">
  {#if isShowPopup}
    <div class="popup-wrapper">
      <div class="popup">
        <span class="text">Щоб записати слова підтримки, відкрийте сайт у браузері Safari</span>
        <div class="button-wrapper">
          <Button class="button" on:click="{closePopup}" text="ОК" />
        </div>
      </div>
    </div>
  {/if}
  <div class="recorder-content">
    <div style="max-height: 50vh;">
      <div class="recorder-countdown">
        <Countdown on:countdown-end="{stopRecording}" bind:this="{countdownRef}" />
      </div>
      <div class="recorder-circles">
        <AudioWave {points} bind:this="{audioWaveRef}" />
      </div>
    </div>
    <div class="recorder-text">
      <p class="recorder-description">
        {#if state === recordingStates.none}
          <span transition:fade>
            Зараз в тебе буде 10 секунд
            <br />
            щоб висловити підтримку
            <br />
            і стати частиною маніфесту
          </span>
        {/if}
      </p>
      <div class="recorder-button">
        {#if state === recordingStates.none}
          <div style="height: 56px;" transition:fade>
            <Button bind:this="{buttonRef}" on:click="{onButtonClick}" text="ПОЧАТИ" />
          </div>
        {/if}
        {#if state === recordingStates.recorded && recordedAudio}
          <div style="height: 56px;" transition:fade>
            <Button
              active="{true}"
              bind:this="{buttonRef}"
              on:click="{onButtonClick}"
              text="ЗБЕРЕГТИ"
            />
          </div>
        {/if}
        {#if state === recordingStates.recorded && recordedAudio}
          <p class="recorder-cancel" on:click="{restart}">перезаписати</p>
        {/if}
        {#if state === recordingStates.none}
          <p class="recorder-cancel" on:click="{cancelRecord}">не маю часу</p>
        {/if}
      </div>
    </div>
  </div>
</div>
