<style lang="scss">
  @import '../styles/main.scss';

  .form-desktop {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .wrapper {
    max-width: 1050px;
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;

    .inner-content {
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      width: 100%;
      position: relative;
      margin-bottom: 30px;
      .image-wrapper {
        position: absolute;
        top: 0;
        right: -10%;
        width: 400px;
        height: 637px;
        img {
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .content {
        grid-column: span 10;
        padding-top: 60px;
        max-width: 600px;
        &-title {
          font-size: 24px;
          line-height: 140%;
          color: #000000;
          font-size: 24px;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          margin-bottom: 34px;
          max-width: 350px;
        }
        &-wrapper {
          margin-left: 57px;
          margin-top: 100px;
          height: 100vh;

          &__description {
            font-family: Montserrat;
            font-size: 20px;
            line-height: 161.5%;
            letter-spacing: 0.02em;
            color: #000000;
            margin-bottom: 34px;
          }
        }
      }
    }
  }
  .footer-wrapper {
    margin-top: auto;
  }
  .img {
    opacity: 0;
    transition: opacity 1s ease;
    &-1 {
      opacity: 1;
    }
  }
  .visible {
    opacity: 1;
  }
</style>

<script>
  import { FirebaseApp, Collection } from 'sveltefire';
  import firebase from '../helpers/firebase';
  import { initSocket } from '../helpers/websocket';
  import { createEventDispatcher } from 'svelte';
  import { onMount, tick, onDestroy } from 'svelte';
  import { getGtag } from '../tracking';
  import gsap, { TweenMax, TimelineMax } from 'gsap';
  import Footer from './Footer.svelte';
  import Form from './Form.svelte';
  import ArrowBack from './ArrowBack.svelte';

  let socket;
  let counter = '';
  let href = appUrl + `/map`;
  let wrapperRef;
  let imgOne;
  let imgTwo;
  let imgThree;
  const dispatch = createEventDispatcher();

  export async function enterAnimation() {
    gtag = await getGtag();
    // gtag.page('/museum');
    TweenMax.to(wrapperRef, 0.4, { opacity: '1' });
  }

  export async function connectToSocket() {
    let gtag = await getGtag();
    gtag.page('/stub');
  }

  function fadeIn(el) {
    gsap.fromTo(el, { opacity: 0 }, { opacity: 1 });
  }
  function fadeOut(el) {
    gsap.fromTo(el, { opacity: 1 }, { opacity: 0 });
  }

  async function goToWaitList() {
    // socket = await initSocket();
    // socket.sendAction();
    // dispatch('success');
    dispatch('goToMain');
  }

  function onMessage(e) {
    if (e.type === 'counter') {
      counter = e.data;
    }
  }
  function back() {
    history.back();
  }

  let interval;
  let active = 1;
  function animate() {
    interval = setInterval(() => {
      active = active === 3 ? 1 : active + 1;
      switch (active) {
        case 1: {
          fadeOut(imgThree);
          fadeIn(imgOne);
          break;
        }
        case 2: {
          fadeOut(imgOne);
          fadeIn(imgTwo);
          break;
        }
        case 3: {
          fadeOut(imgTwo);
          fadeIn(imgThree);
          break;
        }
      }
    }, 4000);
  }
  onMount(() => {
    animate();
  });
  onDestroy(() => {
    if (interval) {
      clearInterval(interval);
    }
  });
</script>

<FirebaseApp {firebase}>
  <div class="form-desktop">
    <div class="wrapper" bind:this="{wrapperRef}">
      <div class="inner-content">
        <div class="image-wrapper">
          <img src="/images/image-1.jpg" alt="" class="img img-1" bind:this="{imgOne}" />
          <img src="/images/image-2.jpg" alt="" class="img img-2" bind:this="{imgTwo}" />
          <img src="/images/image-3.jpg" alt="" class="img img-3" bind:this="{imgThree}" />
        </div>
        <div class="content">
          <div class="content-title">
            <ArrowBack on:back="{back}" title="Про колекцiю рiвностi" />
          </div>
          <div class="content-wrapper">
            <!-- <span class="content-wrapper__description">
              Перша в світі колекцiя рiвностi від Litkovskaya, KseniaSchnaider і BEVZA. Її не можна
              придбати. Лише отримати, якщо підтримуєш рівні права для ЛГБТК+ спільноти.
            </span> -->
            <div class="form">
              <Form on:success="{goToWaitList}" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-wrapper">
      <Footer />
    </div>
  </div>
</FirebaseApp>
