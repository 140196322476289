<style lang="scss">
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
    padding: 24px 20px 24px;
    background: rgba(#ebebeb, 0.8);
    width: 100%;
    min-height: fit-content;

    @media (min-width: 800px) {
      padding: 17px 20px;
    }
    &-sharing {
      align-self: center;
    }
    .text {
      font-family: Montserrat;
      font-size: 9px;
      line-height: 12px;
      letter-spacing: 0.03em;
      color: rgba(26, 26, 26, 0.6);
      margin-bottom: 6px;
      max-width: 900px;
      margin: 0 auto;

      a {
        text-decoration: underline;
        font-family: Montserrat;
        font-size: 9px;
        line-height: 12px;
        letter-spacing: 0.03em;
        color: rgba(26, 26, 26, 0.6);
        text-transform: none;
      }
    }
    @media (orientation: portrait) {
      flex-direction: column;
      align-items: center;
      background: #ebebeb;
    }
  }
</style>

<script>
  import { TweenMax, TimelineLite } from 'gsap';
  import { getGtag } from '../tracking';
  import SharingIcons from './SharingIcons.svelte';

  async function trackEventOnce(category, action) {
    if (typeof gtag === 'undefined') {
      gtag = await getGtag();
    }

    gtag.trackEventOnce(category, action);
  }
</script>

<div class="footer {$$props.class}">
  <div class="footer-sharing">
    <SharingIcons />
  </div>
  <p class="text">
    ПРОЄКТ РІЗНІ.РІВНІ РЕАЛІЗОВАНО ЗА ІНІЦІАТИВИ ГРОМАДСЬКИХ ОРГАНІЗАЦІЙ: Гендер Зед, Інша,
    КиївПрайд, Ліга, Сфера, Освітній Центр з Прав Людини у Львові за підтримки Національного
    Демократичного Інституту в Україні та урядів Великобританії, Швеції та Канади. ІДЕЯ ТА РОЗРОБКА
    ПРОЄКТУ: Технологічна креативна агенція
    <a href="https://isd-group.com/" target="_blank">[isdgroup]</a>
  </p>
</div>
