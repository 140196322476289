<style lang="scss">
  label {
    margin-bottom: 4px;
    font-size: 12px;
    font-family: Montserrat;
    @media (min-width: 1000px) {
      height: fit-content;
    }
  }

  .relative {
    position: relative;
  }

  .pre {
    left: 16px;
    bottom: 14px;
    font-size: 12px;
    position: absolute;
    font-family: Montserrat;
    color: #1a1a1a;
    opacity: 0.5;
    transition: all 0.15s ease-in-out;
  }

  .small-text {
    font-size: 10px;
    font-family: Montserrat;
    text-align: center;
    color: #999999;
    margin-top: 16px;
  }

  .transition {
    transition: all 0.15s ease-in-out;
  }
  .form {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 36px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: space-between;
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    height: 100%;

    @media (min-width: 1000px) {
      position: relative;
      transform: none;
      top: auto;
      left: auto;
      padding: 32px 0;
      align-items: flex-start;
      justify-content: center;
      max-width: 600px;
      width: 100%;
      margin: 0;
    }

    &-error {
      color: #fd4802;
      opacity: 0.7;
      font-family: Montserrat, sans-serif;
      font-size: 8px;
      // position: absolute;
      // bottom: -14px;

      width: 100%;
      // margin-left: 12px;

      &.absolute-error {
        position: absolute;
        bottom: -14px;
        left: 0;
      }
    }
    &-title {
      font-size: 14px;
      line-height: 23px;
      letter-spacing: 0.05em;
      display: flex;
      align-items: center;
      align-self: center;
      color: #1a1a1a;
      margin-bottom: 34px;
      width: 250px;
      text-transform: uppercase;
      text-align: center;
      font-family: Transgender Grotesk;

      @media (min-width: 1000px) {
        grid-column: span 2;
        width: auto;
        font-family: 'Montserrat-Medium';
        font-weight: 500;
        text-transform: initial;
        font-size: 18px;
        line-height: 27px;
        text-align: left;
        margin-bottom: 32px;
        align-self: flex-start;
      }
    }
    &-inputs {
      width: 100%;
      margin-bottom: 40px;
      @media (min-width: 1000px) {
        margin-bottom: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 45px;
        align-items: center;
      }
      input {
        font-family: Montserrat, sans-serif;
        padding: 16px;
        background: #ebebeb;
        border-radius: 8px;
        border: none;
        width: 100%;
        font-size: 12px;
        line-height: 12px;
        font-weight: normal;
        color: #1a1a1a;

        &:focus {
          outline: none;

          + .pre {
            opacity: 1;
          }
        }

        &.insta {
          // padding: 16px 16px 16px 130px;
          padding: 16px;
        }

        &.phone {
          padding: 16px 16px 16px 50px;
        }
      }
      ::placeholder {
        font-size: 12px;
        font-family: Montserrat, sans-serif;
        line-height: 12px;
        color: rgba(26, 26, 26, 0.5);
      }
    }

    .input-group {
      margin-bottom: 24px;
      width: 100%;
      position: relative;
      @media (min-width: 1000px) {
        margin-bottom: 24px;
        max-width: 450px;
      }
    }
    &-checkbox {
      &__item {
        display: flex;
        font-size: calc(1vh + 1vw);
        line-height: 12px;
        color: #1a1a1a;
        margin-bottom: 16px;
        position: relative;
        @media (min-width: 1000px) {
          font-size: 10px;
          line-height: 12px;
          margin-bottom: 16px;

          &:last-child {
            margin-bottom: 0;
          }
        }
        a {
          color: #1a1a1a;
          text-decoration: underline;
        }
        &:last-child {
          margin-bottom: 0;
        }
        label {
          font-family: Montserrat, sans-serif;
          &:focus {
            outline: none;
          }
        }
      }
      @media (min-width: 1000px) {
        margin-bottom: 12px;
        align-self: flex-start;
        display: flex;
        flex-direction: column;
        max-width: 450px;
      }
    }
    &-button {
      // margin-top: auto;
      // margin-top: 40px;
      cursor: pointer;
      pointer-events: auto;
      @media (min-width: 1000px) {
        margin-top: 0;
      }
    }
  }
  .custom-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  .custom-checkbox + label {
    display: inline-flex;
    align-items: flex-start;
    user-select: none;
    @media (min-width: 1000px) {
      font-size: 10px;
    }
  }
  .custom-checkbox + label::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid rgba(148, 182, 197, 0.2);
    border-radius: 0.25em;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }
  .custom-checkbox:checked + label::before {
    border-color: #94b6c5;
    background-color: #94b6c5;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  }

  .opacity {
    opacity: 50%;
  }
  .opacity-20 {
    opacity: 0.2;
    cursor: default !important;
    pointer-events: none;
  }

  .form-finish {
    &__title {
      font-family: Transgender Grotesk;
      font-size: 29px;
      line-height: 39px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: rgba(26, 26, 26, 0.8);

      @media screen and (max-width: 780px) {
        font-size: 14px;
        line-height: 23px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #1a1a1a;
      }
    }
    &__description {
      font-family: Montserrat;
      font-size: 20px;
      line-height: 27px;
      letter-spacing: 0.02em;
      color: #1a1a1a;
      margin-top: 29px;
      @media screen and (max-width: 780px) {
        font-size: 14px;
        line-height: 135.4%;
        letter-spacing: 0.02em;
      }
    }
    &__button {
      margin-top: 62px;
    }
  }
</style>

<script>
  import gsap, { TweenMax, Back, TimelineLite } from 'gsap';
  import { createEventDispatcher, onMount } from 'svelte';
  import { respondH } from '../helpers/vh';
  import Button from './Button.svelte';
  import { getGtag } from '../tracking';
  import Menu from './HeaderMenu.svelte';
  import firebase from '../helpers/firebase';
  import PhoneNumber from 'awesome-phonenumber';

  async function trackEventOnce(category, action) {
    if (typeof gtag === 'undefined') {
      gtag = await getGtag();
    }

    gtag.trackEventOnce(category, action);
  }

  async function trackEvent(category, action) {
    if (typeof gtag === 'undefined') {
      gtag = await getGtag();
    }

    gtag.trackEvent(category, action);
  }

  const dispatch = createEventDispatcher();

  let gtag;
  let stepText;
  let figureContainer;
  let mask;
  let figure;
  let timeline;
  let infinityTimeline;
  let buttons;
  let button;
  let wrapperRef;
  let socket;

  let fullName = '';
  let phone = '';
  let link = '';
  let showError = false;
  let fullNameTouched = false;
  let phoneTouched = false;
  let personal = true;
  let support = true;
  let agree = true;
  let phoneExist = false;
  let linkTouched = false;
  let linkExist = false;

  let submitted = false;

  let isMobile;
  isMobile = !window.matchMedia('(min-width: 1024px)').matches;

  export async function enterAnimation() {
    gtag = await getGtag();
    gtag.page('/form');
    TweenMax.to(wrapperRef, 0.4, { opacity: '1' });
  }

  async function onSuccess() {
    if (submitted) {
      return;
    }

    submitted = true;

    blurField('phone');
    blurField('link');
    blurField('name');

    if (!isMobile) {
      if (
        !nameValid(fullName) ||
        !phone ||
        !phoneValid(phone) ||
        !personal ||
        !support ||
        !link ||
        !linkValid(link)
      ) {
        submitted = false;
        return trackEvent('form', 'click_button_error');
      }
    }
    if (
      !nameValid(fullName) ||
      !phone ||
      !phoneValid(phone) ||
      !personal ||
      !link ||
      !linkValid(link)
    ) {
      submitted = false;
      return trackEvent('form', 'click_button_error');
    }

    const snapshot = await firebase
      .firestore()
      .collection('forms')
      .get();

    const phoneValue = `+380${phone.split(' ').join('')}`;

    const linkValue = `https://${
      link
        .replace('https://', '')
        .replace('www.', '')
        .split('?')[0]
    }`;

    const docs = snapshot.docs.map(doc => doc.data());

    const phones = docs.map(doc => doc.phone).map(p => p.split(' ').join(''));
    const links = docs.map(doc => doc.link);

    if (phones.includes(phoneValue)) {
      phoneExist = true;
    }

    if (links.includes(link)) {
      linkExist = true;
    }

    if (phoneExist || linkExist) {
      submitted = false;
      return trackEvent('form', 'click_button_error');
    }

    trackEvent('form', 'click_button_done');

    if (isMobile) {
      fbq('track', 'CompleteRegistration', { content_name: 'mobile' });
    }
    if (!isMobile) {
      fbq('track', 'CompleteRegistration', { content_name: 'desktop' });
    }

    firebase
      .firestore()
      .collection('forms')
      .add({
        fullName,
        phone: phoneValue,
        link: linkValue,
        timestamp: Date.now()
      });

    submitted = false;
    dispatch('success');
  }

  function toMainPage() {
    dispatch('goToMain');
  }

  function nameValid(value) {
    value = value.split(' ').join('');
    // let name = /^[а-яА-ЯіІїЇєЄa-zA-Z]+$/;
    let symbols = /[0-9!@#$%^&*()_=+/?.,><:";{}[`~]/gi;

    let validName = value.match(symbols);

    if (validName == null) {
      return true;
    }
    return false;
  }

  function linkValid(value) {
    value.split(' ').join('');

    value.replace('https://', '');
    value.replace('www.', '');

    let lowercaseValue = value.toLowerCase();

    if (lowercaseValue.includes('facebook.com') || lowercaseValue.includes('instagram.com')) {
      return true;
    } else {
      return false;
    }
  }

  //   value.addEventListener('keydown', (e) => {
  //   if(['1','2','3','4','5', 'N', 'O', 'A', 'B', 'C'].indexOf(e.key) !== -1){
  //     logMessage(`Key "${e.key}" pressed`);
  //   } else {
  //     logMessage(`Key "${e.key}" not allowed`);
  //     e.preventDefault();
  //   }
  // });

  //   link: `https://www.instagram.com/${link}`,
  //  if (links.includes(`https://www.instagram.com/${link}`)) {
  //       linkExist = true;
  //     }

  function phoneValid(value) {
    value = value.split(' ').join('');
    const number = new PhoneNumber(`+380${value}`, 'UA');
    return number.isValid();
  }

  onMount(async () => {
    TweenMax.set(wrapperRef, { opacity: '1' });
    if (
      !phone ||
      !phoneValid(phone) ||
      !personal ||
      !agree ||
      !fullName ||
      !nameValid(fullName) ||
      !link ||
      !linkValid(link)
    ) {
      button.classList.add('opacity-20');
    } else {
      button.classList.remove('opacity-20');
    }
  });

  function blurField(field) {
    if (field === 'phone') {
      phoneTouched = true;

      if (!phone || !phoneValid(phone)) {
        trackEvent('form', 'error_phone');
      }
    }

    if (field === 'name' || !fullName) {
      fullNameTouched = true;

      if (!fullName) {
        trackEvent('form', 'error_name');
      }
    }

    if (field === 'link' || !linkValid(link)) {
      linkTouched = true;

      if (!link) {
        trackEvent('form', 'error_username');
      }
    }
  }

  function onCheckBox(event) {
    personal = event.target.checked;
    if (!personal) {
      trackEvent('form', 'error_checkbox');
    }
  }
  function onCheckBoxSecond(event) {
    support = event.target.checked;
    if (!support) {
      trackEvent('form', 'error_checkbox');
    }
  }
</script>

<div class="form" bind:this="{wrapperRef}">
  {#if true}
    <div class="form-finish__title">Друзі, останній дроп Колекції Рівності закінчився</div>
    <div class="form-finish__description">
      Реєстрація закрита, скоро тут з’являться переможці, що отримають дизайнерський одяг. Та
      зможуть самостійно передати його далі Різним і Рівним — як і свою підтримку ЛГБТК+ спільноти
    </div>
    {#if isMobile}
      <div class="form-finish__button">
        <Button width="160" text="до головної" on:click="{toMainPage}" />
      </div>
    {/if}
  {/if}

  {#if false}
    <div class="form-title">Заповнюй форму, щоб отримати річ з колекції</div>
    <div class="form-inputs">
      <div class="input-group">
        <label for="name">Iм’я та прiзвище</label>
        <input id="name" type="text" bind:value="{fullName}" on:blur="{() => blurField('name')}" />
        {#if fullNameTouched && !fullName}
          <span class="form-error absolute-error">Введи ім’я та прізвище</span>
        {/if}
        {#if fullName && !nameValid(fullName)}
          <span class="form-error absolute-error">Невірний формат імені</span>
        {/if}
      </div>
      <div class="input-group">
        <label for="name">Номер телефону</label>
        <div class="relative">
          <input
            type="text"
            class="phone"
            bind:value="{phone}"
            on:input="{() => (phoneExist = false)}"
            on:blur="{() => blurField('phone')}"
            maxlength="9"
          />
          <span class="pre">+380</span>
        </div>
        {#if phoneTouched && !phone}
          <span class="form-error absolute-error">Введи номер</span>
        {/if}
        {#if phoneTouched && phone && !phoneValid(phone)}
          <span class="form-error absolute-error">Невiрний формат номеру</span>
        {/if}
        {#if phoneTouched && phoneExist}
          <span class="form-error absolute-error">
            Користувач з цим номером телефону вже зареєстрований
          </span>
        {/if}
      </div>
      <div class="input-group">
        <label for="name">Посилання на твій Facebook чи Instagram</label>
        <div class="relative">
          <input
            id="test"
            class="insta"
            type="text"
            bind:value="{link}"
            on:input="{() => (linkExist = false)}"
            on:blur="{() => blurField('link')}"
          />
          <!-- <span class="pre">instragram.com/@</span> -->
        </div>
        {#if linkTouched && !link}
          <span class="form-error absolute-error">Введи посилання</span>
        {/if}
        {#if linkTouched && linkExist}
          <span class="form-error absolute-error">
            Користувач з таким посиланням вже зареєстрований
          </span>
        {/if}
        {#if link && !linkValid(link)}
          <span class="form-error absolute-error">Невірний формат посилання</span>
        {/if}
      </div>
      <div class="form-checkbox">
        {#if !isMobile}
          <div class="form-checkbox__item">
            <input
              class="custom-checkbox"
              type="checkbox"
              id="support"
              name="support"
              checked
              on:change="{onCheckBoxSecond}"
            />
            <label for="support">
              <div class="">Підтримую рівні права для ЛГБТК+ спільноти</div>
            </label>
            {#if !support}
              <span class="form-error absolute-error">Обов’язково</span>
            {/if}
          </div>
        {/if}

        <div class="form-checkbox__item">
          <input
            class="custom-checkbox"
            type="checkbox"
            id="rules"
            name="rules"
            checked
            on:change="{onCheckBox}"
          />
          <label for="rules">
            <div class="">
              Даю згоду на
              <a
                href="https://drive.google.com/file/d/1nigljPHD8miB9SSKLh7e6MeT0fTUFdCl/view"
                target="_blank"
              >
                обробку моїх персональних даних
              </a>
              та погоджуюсь з
              <a
                href="https://docs.google.com/document/d/1mg1mAPIj1eTrBU4CQDlG9-uZ_xKEQn2TZO38wU4pjfU/edit?usp=sharing"
                target="_blank"
              >
                правилами акції
              </a>
            </div>
          </label>
          {#if !personal}
            <span class="form-error absolute-error">Обов’язково</span>
          {/if}
        </div>
      </div>
    </div>

    {#if !isMobile}
      <div
        class="{!phoneValid(phone) || !personal || !support || !agree || !fullName || !linkValid(link) ? 'opacity-20' : ''}
        transition form-button"
        bind:this="{button}"
      >
        <Button width="240" text="готово!" on:click="{onSuccess}" />
      </div>
    {:else}
      <div
        class="{!phoneValid(phone) || !personal || !agree || !fullName || !linkValid(link) ? 'opacity-20' : ''}
        transition form-button"
        bind:this="{button}"
      >
        <Button width="240" text="готово!" on:click="{onSuccess}" />
      </div>
    {/if}
  {/if}
</div>
