import * as THREE from 'three';
import ukraine from '../img/ua.jpg';
import ray from '../img/ray.png';
import alpha from '../img/alpha.png';
import gsap from 'gsap';
import { Line2 } from './lines/Line2';
import { LineMaterial } from './lines/LineMaterial';
import { LineGeometry } from './lines/LineGeometry';
import GetText from './getText';
import { getRandomWaveEvent } from './get-random-event';
import _ from 'lodash';

import fragmentParticles from './shader/fragment.glsl';
import vertexParticles from './shader/vertexParticles.glsl';
import orbitControls from 'three-orbit-controls';

let OrbitControls = orbitControls(THREE);
function lerp(a, b, t) {
  return a * (1 - t) + b * t;
}
export default class Sketch {
  constructor(container) {
    this.scene = new THREE.Scene();

    this.mockRays = [];
    this.rays = [];
    this.shownCities = [];


    this.renderer = new THREE.WebGLRenderer();
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.width, this.height);
    this.renderer.setClearColor(0x191114, 1);
    this.renderer.setClearColor(0xebebeb, 1);

    this.container = container;
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    this.container.appendChild(this.renderer.domElement);

    // post processing
    // this.composer =  new EffectComposer(this.renderer);
    // this.composer.setSize( this.width, this.height );
    // this.clock = new THREE.Clock();

    // this.effectPass = new EffectPass(this.camera, new BloomEffect());
    // this.effectPass.renderToScreen = true;

    // this.composer.addPass(new RenderPass(this.scene, this.camera));
    // this.composer.addPass(this.effectPass);
    //

    this.camera = new THREE.PerspectiveCamera(
      70,
      window.innerWidth / window.innerHeight,
      0.0001,
      1000
    );

    window.camera = this.camera;

    // var frustumSize = 10;
    // var aspect = window.innerWidth / window.innerHeight;
    // this.camera = new THREE.OrthographicCamera( frustumSize * aspect / - 2, frustumSize * aspect / 2, frustumSize / 2, frustumSize / - 2, -1000, 1000 );
    this.camera.position.set(0, 0.3, 1.1);
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    // this.controls.minPolarAngle = 0.2; // radians
    // this.controls.maxPolarAngle = 0.9*Math.PI / 2; // radians
    this.controls.maxPolarAngle = (0.9 * Math.PI) / 2;
    this.controls.maxDistance = 3;

    this.time = 0;

    this.paused = false;
    this.currentWave = 0;
    this.cities = [];

    this.setupResize();

    // this.addRay();
    this.addObjects();

    this.resize();
    this.render();
    this.settings();
  }

  destroy() {
    function clearThree(obj) {
      while (obj.children.length > 0) {
        clearThree(obj.children[0]);
        obj.remove(obj.children[0]);
      }
      if (obj.geometry) obj.geometry.dispose();

      if (obj.material) {
        //in case of map, bumpMap, normalMap, envMap ...
        Object.keys(obj.material).forEach(prop => {
          if (!obj.material[prop]) return;
          if (typeof obj.material[prop].dispose === 'function') obj.material[prop].dispose();
        });
        obj.material.dispose();
      }
    }

    if(this.interval) {
      clearInterval(this.interval);
    }
    this.renderer.dispose();
    clearThree(this.scene);
    cancelAnimationFrame(this.frame);
    this.container.innerHTML = '';
    this.renderer = null;
    this.scene = null;
  }

  settings() {
    this.settings = {
      intensity: 1.7,
      waveHeight: 0.01,
      waveDistance: 0.53,
      wavePeriod: 30,
      waveSpeed: 1,
      particleSize: 1.28,
      particlePulse: 1
    };
  }

  getRegionData(region) {
    const regions = {
      5: {
        coords: new THREE.Vector3(-0.2, 0, -0.05),
        title: 'Вінниця'
      },
      7: {
        coords: new THREE.Vector3(-0.6, 0, -0.45),
        title: 'Луцьк'
      },
      9: {
        coords: new THREE.Vector3(0.8, 0, -0.05),
        title: 'Луганськ'
      },
      12: {
        coords: new THREE.Vector3(0.5, 0, 0.05),
        title: 'Дніпро'
      },
      14: {
        coords: new THREE.Vector3(0.75, 0, 0.1),
        title: 'Донецьк'
      },
      18: {
        coords: new THREE.Vector3(-0.15, 0, -0.25),
        title: 'Житомир'
      },
      21: {
        coords: new THREE.Vector3(-0.95, 0, -0.03),
        title: 'Ужгород'
      },
      23: {
        coords: new THREE.Vector3(0.5, 0, 0.175),
        title: 'Запоріжжя'
      },
      26: {
        coords: new THREE.Vector3(-0.65, 0, -0.05),
        title: 'Івано-Франківськ'
      },
      32: {
        coords: new THREE.Vector3(0, 0, -0.3),
        title: 'Київ'
      },
      35: {
        coords: new THREE.Vector3(0.225, 0, 0.075),
        title: 'Кропивницький\t'
      },
      43: {
        coords: new THREE.Vector3(0.4, 0, 0.6),
        title: 'Сімферополь'
      },
      46: {
        coords: new THREE.Vector3(-0.7, 0, -0.2),
        title: 'Львів'
      },
      48: {
        coords: new THREE.Vector3(0.15, 0, 0.25),
        title: 'Миколаїв'
      },
      51: {
        coords: new THREE.Vector3(-0.05, 0, 0.4),
        title: 'Одеса'
      },
      53: {
        coords: new THREE.Vector3(0.3, 0, -0.1),
        title: 'Полтава'
      },
      56: {
        coords: new THREE.Vector3(-0.4, 0, -0.325),
        title: 'Рівне'
      },
      59: {
        coords: new THREE.Vector3(0.35, 0, -0.35),
        title: 'Суми'
      },
      61: {
        coords: new THREE.Vector3(-0.55, 0, -0.1),
        title: 'Тернопіль'
      },
      63: {
        coords: new THREE.Vector3(0.5, 0, -0.225),
        title: 'Харків'
      },
      65: {
        coords: new THREE.Vector3(0.25, 0, 0.35),
        title: 'Херсон'
      },
      68: {
        coords: new THREE.Vector3(-0.35, 0, -0.15),
        title: 'Хмельницький'
      },
      71: {
        coords: new THREE.Vector3(0.15, 0, -0.05),
        title: 'Черкаси'
      },
      74: {
        coords: new THREE.Vector3(0.07, 0, -0.45),
        title: 'Чернігів'
      },
      77: {
        coords: new THREE.Vector3(-0.6, 0, 0.08),
        title: 'Чернівці'
      },
      100: {
        coords: new THREE.Vector3(-.8, 0, -0.5),
      },
      200: {
        coords: new THREE.Vector3(.3, 0, -0.6),
      },
      300: {
        coords: new THREE.Vector3(.3, 0, -0.3),
      }
    };

    return regions[region] || regions[32];
  }

  showHistory(history) {
    this.history = history;
    this.flyOutHistoryWaves(false);
  }

  async flyOutHistoryWaves(withInterval = true) {
      setTimeout(async() => {
        if(this.history.length === 1) {
          const [{ region, city }] = this.history;
          await this.showCityLine({ region, city }, false, true);
        }

        if(this.history.length === 2) {
          const [historyOne, historyTwo] = this.history;
          this.showCityLine(historyOne, false, true);
          await this.showCityLine(historyTwo, false);
        }

        if(this.history.length === 3) {
          const [historyOne, historyTwo, historyThree] = this.history;
          this.showCityLine(historyOne, false, true);
          this.showCityLine(historyTwo, false, );
          await this.showCityLine(historyThree, false);
        }
        
        if(withInterval) {
          this.interval = getRandomWaveEvent((regionData, first) => this.showCityLine(regionData, true, first), true);
        }
      }, 1000)
  }

  setMapCenter(history) {
    console.log(history);
    this.history = history;
    this.onlyMap = true;
    const start = new THREE.Vector3(0, .3, .9);
    this.camera.position.copy(start);
    this.camera.lookAt(start.x, start.y, start.z);
    this.controls.update();
    // this.controls.enabled = false;
  }


  setStartPosition({ region, city }, top = 0.45, history = []) {
    /**
     * FIXME: For debugging purpose
     */
    // region = getRandomRegion();
    this.history = history;
    this.region = this.getRegionData(region);
    this.shownCities = [{ region: region.toString(), city: this.region.title || city  }];
    this.addRay(this.region, city);
    const start = this.region.coords;
    const { z } = start;
    var realstart = start;
    // realstart.z += 0.4;
    this.camera.position.copy(realstart);
    this.camera.lookAt(start.x, top, realstart.z);
    this.particles.position.y = -0.6;
    gsap.set(this.mainRay.scale, { y: 0 });
  } 

  flyout() {
    return new Promise(resolve => {
      // set campos
      // this.drawRay(1);
      // this.drawRay(1);
      const { x, z } = this.region.coords;
      // let start = new THREE.Vector3(x, 0.45, z > 0 ? z + 0.2 : 0);
      let start = new THREE.Vector3(x, 0.45, z + 0.4);
      this.camera.position.copy(start);
      // this.camera.position.set(0, 0.3, 1.1);
      // this.camera.lookAt(start.x, start.y, start.z);

      let o = { progress: 0 };
      let o1 = { progress: 0 };

      gsap.to(this.mainRay.scale, {
        duration: 1,
        x: 1
      });

      gsap.to(o, {
        progress: 1,
        duration: 1.5,
        onComplete: () => {
          this.mainCity.visible = true;
          gsap.set(this.mainRay.scale, {
            y: 10
          });
        },
        onUpdate: () => {
          this.particles.position.y = lerp(-0.6, 0, o.progress);
          // this.camera.position.set(0, start.y, lerp(start.z,1.1,o.progress));
          this.camera.position.set(start.x, start.y - o.progress * start.y * 0.5, start.z);
          // this.camera.lookAt(0,start.y - o.progress*start.y*0.5,0)
          // console.log(start.y - o.progress*start.y*0.5,'progress');
          // console.log(this.controls.target,'target');

          this.controls.target = new THREE.Vector3(
            start.x,
            start.y - o.progress * start.y * 0.5,
            0
          );
          this.controls.update();
        }
      });

      gsap.to(this.camera.position, {
        delay: 1,
        duration: 3,
        z: start.z + 1,
        // y: 0.6,
        onUpdate: () => {
          this.controls.update();
        },
        ease: 'expo.inOut'
      });

      gsap.to(this.camera.position, {
        delay: 2,
        duration: 3.5,
        y: 2.6,
        onUpdate: () => {
          this.controls.update();
        },
        ease: 'expo.inOut'
      });

      gsap.to(this.camera.position, {
        delay: 5,
        duration: 3,
        y: 0.6,
        onStart: () => {
          setTimeout(() => {
            this.createWave(this.mainRay.position);
          }, 1200);

          setTimeout(() => {
            this.flyOutHistoryWaves();
          }, 3000)
        },
        onUpdate: () => {
          this.controls.update();
        },
        ease: 'expo.inOut'
      });

      gsap.to(this.scene.rotation, {
        delay: 1.5,
        duration: 6,
        y: 2 * Math.PI,
        onUpdate: () => {
          this.controls.update();
        },
        onComplete: () => {
          resolve();
        },
        ease: 'expo.inOut'
      });
    });
  }

  createWave(position) {
    const waveIndex = this.currentWave;

    this.material.uniforms.wPosition.value[waveIndex] = position;

    const fake = {
      wHeight: 0.04,
      wTime: 0.0,
      wLength: 0.15
    };

    gsap.to(fake, {
      duration: 5,
      wHeight: 0,
      wTime: 0.7,
      wLength: 0.3,
      onUpdate: () => {
        this.material.uniforms.wHeight.value[waveIndex] = fake.wHeight;
        this.material.uniforms.wTime.value[waveIndex] = fake.wTime;
        this.material.uniforms.wLength.value[waveIndex] = fake.wLength;
      }
    });

    this.currentWave = (this.currentWave + 1) % 10;
  }

  setupResize() {
    window.addEventListener('resize', this.resize.bind(this));
  }

  resize() {
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    this.renderer.setSize(this.width, this.height);
    this.camera.aspect = this.width / this.height;

    // image cover
    this.imageAspect = 853 / 1280;
    let a1;
    let a2;
    if (this.height / this.width > this.imageAspect) {
      a1 = (this.width / this.height) * this.imageAspect;
      a2 = 1;
    } else {
      a1 = 1;
      a2 = this.height / this.width / this.imageAspect;
    }

    this.material.uniforms.resolution.value.x = this.width;
    this.material.uniforms.resolution.value.y = this.height;
    this.material.uniforms.resolution.value.z = a1;
    this.material.uniforms.resolution.value.w = a2;
    this.camera.updateProjectionMatrix();
  }

  createRay(region) {
    this.spriteSize = 0.97;
    let textSize = 0.2;


    let spriteMap = new THREE.TextureLoader().load(ray);
    let alphaMap = new THREE.TextureLoader().load(alpha);
    spriteMap.min = spriteMap.mag = THREE.LinearFilter;
    spriteMap.wrapS = THREE.RepeatWrapping;
    spriteMap.wrapT = THREE.RepeatWrapping;
    spriteMap.repeat = new THREE.Vector2(4,1);
    alphaMap.min = alphaMap.mag = THREE.LinearFilter;
    const rayElement = new THREE.Mesh(
      // new THREE.PlaneGeometry(0.04,this.spriteSize),
      // new THREE.BoxGeometry(0.04,1.,0.04),
      new THREE.CylinderGeometry(0.04,0.04,this.spriteSize,4,1,1),
      new THREE.MeshBasicMaterial({
        map: spriteMap,
        alphaMap: alphaMap,
        color: 0xffffff,
        transparent: true,
        side: THREE.DoubleSide,
      })
    );

    rayElement.position.copy(region.coords);
    return rayElement;
  }

  getRandomColor() {
    const colors = ['#FD4802', '#FEB903', '#FCAACE'];
    return colors[_.random(2)];
  }

  showCityLine({ region, city }, mock, noDelay) {
    if(!this.scene) {
      return;
    }
    return new Promise(resolve => {
      const regionData = this.getRegionData(region);
      const hasCity = !!this.shownCities.find(data => region.toString() === data.region && data.city === (regionData.title || city));
      if (!hasCity) {
        this.shownCities.push({ region: region.toString(), city: regionData.title || city });
      }
      let pos = new THREE.Vector3(0, 0, 0).copy(regionData.coords);

      if (hasCity) {
        const multiplier = Math.random() < 0.5 ? -1 : 1;
        pos.set(
          (multiplier * Math.random()) / 15 + regionData.coords.x,
          0,
          (multiplier * Math.random()) / 15 + regionData.coords.z
        );
      }

      let height = 2.5;
      let maxcount = 1200;

      const outerWidth = window.outerWidth;

      const matLine = new LineMaterial({
        color: this.getRandomColor(),
        linewidth: outerWidth < 450 ? 0.005 : outerWidth / (outerWidth / 320 * 100)  * 0.00045,
        dashed: false
      });

      // @todo - many more points here, with lerp
      let linePoints = [];
      for (let i = 0; i <= maxcount; i++) {
        linePoints.push(pos.x * 0, (height * i) / maxcount, pos.z * 0);
      }

      let newline = new Line2(new LineGeometry().setPositions(linePoints), matLine);
      newline.geometry.maxInstancedCount = 0;
      let newlineWrap = new THREE.Object3D();
      newlineWrap.position.copy(pos);
      newlineWrap.add(newline);
      this.scene.add(newlineWrap);

      let matCity;

      // animate here
      const duration = _.random(4, 8);
      const delay = noDelay ? 0 : _.random(1, 5);

      gsap.to(newline.geometry, {
        duration,
        delay,
        maxInstancedCount: maxcount,
        onStart: () => {
          this.createWave(pos);
        },
        onUpdate: () => {
          if (!hasCity && newline.geometry.maxInstancedCount >= 400 && !matCity && this.scene && !mock) {
            matCity = this.createTitle(regionData, false, regionData.title || city);
            this.scene.add(matCity);
          }

          if(newline.geometry.maxInstancedCount >= 1000) {
            resolve();
          }
        },  
        onComplete: () => {
          gsap.to(newlineWrap.position, duration, {
            y: 3,
            onStart: () => {
              if (matCity) {
                matCity.visible = false;
                this.shownCities = this.shownCities.filter(
                  data => data.region !== region.toString() && data.city !== (regionData.title || city)
                );
              }
            },
          })
        }
      });
    });
  }

  createTitle(region, main, title = '') {
    const height = .9;
    const textSize = this.onlyMap ? .4 : .8;
    let Citytext = new GetText();
    Citytext.update(title.toUpperCase());
    let texture = new THREE.Texture(Citytext.element);
    texture.needsUpdate = true;

    const { coords } = region;
    let pos = new THREE.Vector3(main ? coords.x : coords.x - 0.02, 0, coords.z);

    let matCity = new THREE.MeshBasicMaterial({
      map: texture,
      transparent: true,
      color: 0xff0000
    });

    let geoCity = new THREE.PlaneBufferGeometry(textSize, textSize)
      .rotateZ(Math.PI / 2)
      .applyMatrix(new THREE.Matrix4().makeTranslation(0, height - textSize / (this.onlyMap ? .55 : 2), 0));
    let city = new THREE.Mesh(geoCity, matCity);
    // city.rotation.z = Math.PI/2
    city.position.copy(pos);
    city.position.y += 0.05;

    return city;
  }

  addRay(region, city) {
    this.mainRay = this.createRay(region);
    this.scene.add(this.mainRay);
    this.mainCity = this.createTitle(region, true, this.region.title || city);
    this.mainCity.visible = false;
    this.scene.add(this.mainCity);
    this.cities.push(this.mainCity);
    this.cities.push(this.mainRay);
    gsap.set(this.mainRay.rotation, { y: .25 * Math.PI });
    gsap.set(this.mainRay.scale, { x: 1.5 });
  }

  drawRay(duration) {
    const { x, z } = this.region.coords;
    // let start = new THREE.Vector3(x, 0.45, z > 0 ? z + 0.2 : 0);
    let start = new THREE.Vector3(x, 0.475, z + 0.4);
    this.camera.position.copy(start);
    this.camera.lookAt(start);

    return new Promise(onComplete => {
      let dur = 1;
      gsap.fromTo(
        this.mainRay.position,
        {
          y: 0.45
        },
        {
          y: 0,
          duration: dur,
          ease: 'power2.in'
        }
      );
      gsap.fromTo(
        this.mainRay.scale,
        {
          y: 0
        },
        {
          y: 1,
          duration: dur,
          ease: 'power2.in',
          onComplete: () => {
            onComplete();
            console.log('COMPLETE');
          }
        }
      );
    });
  }

  addObjects() {
    let wTime = [];
    let wHeight = [];
    let wLength = [];
    let wPosition = [];

    for (let i = 0; i < 10; i++) {
      wTime.push(0);
      wLength.push(0);
      wHeight.push(0);
      wPosition.push(new THREE.Vector3(0, 0, 0));
    }


    this.material = new THREE.ShaderMaterial({
      // side: THREE.DoubleSide,
      uniforms: {
        time: { type: 'f', value: 0 },
        ukrainemap: { type: 't', value: new THREE.TextureLoader().load(ukraine) },
        u_intensity: { type: 'f', value: 5.72 },
        u_hue: { type: 'f', value: 0.1 },
        u_saturation: { type: 'f', value: 0.7 },
        waveHeight: { type: 'f', value: 0.7 },
        waveDistance: { type: 'f', value: 0.7 },
        wavePeriod: { type: 'f', value: 0.7 },
        waveSpeed: { type: 'f', value: 0.7 },
        particleSize: { type: 'f', value: 1 },
        particlePulse: { type: 'f', value: 1 },

        //manual wave
        wTime: { type: 'fv1', value: wTime },
        wHeight: { type: 'fv1', value: wHeight },
        wLength: { type: 'fv1', value: wLength },
        wPosition: { type: 'v3v', value: wPosition },

        u_lightness: { type: 'f', value: 0.45 },
        resolution: { type: 'v4', value: new THREE.Vector4() }
      },
      // wireframe: true,
      // transparent: true,
      vertexShader: vertexParticles,
      fragmentShader: fragmentParticles
      // blending: THREE.MultiplyBlending,
      // depthTest: false,
    });

    this.pointsGeo = new THREE.BufferGeometry();
    let vertices = [];
    let rands = [];

    this.count = 200;
    for (let i = -this.count / 2; i < this.count / 2; i++) {
      for (let j = -this.count / 2; j < this.count / 2; j++) {
        vertices.push((2 * i) / this.count, 0, (2 * j) / this.count);
        rands.push(Math.random());
      }
    }

    this.pointsGeo.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
    this.pointsGeo.setAttribute('randoms', new THREE.Float32BufferAttribute(rands, 1));

    this.particles = new THREE.Points(this.pointsGeo, this.material);

    // this.test = new THREE.Mesh(new THREE.PlaneBufferGeometry( 0.3,0.3 ), new THREE.MeshBasicMaterial( {color: 0x00ff00}))

    // this.scene.add(this.test)
    this.scene.add(this.particles);
    // this.particles.rotation.x = 0.3
  }

  stop() {
    this.paused = true;
  }

  play() {
    this.paused = false;
    this.render();
  }

  render() {
    if (this.paused && !this.renderer) return;
    this.time += 0.05;

    // this.test.quaternion.copy(this.camera.quaternion);
    // this.text.quaternion.copy(this.camera.quaternion);

    // this.cities.forEach(c => {
    //   c.quaternion.copy(this.camera.quaternion);
    // });
    this.material.uniforms.time.value = this.time;
    this.material.uniforms.u_intensity.value = this.settings.intensity;
    this.material.uniforms.waveHeight.value = this.settings.waveHeight;
    this.material.uniforms.waveDistance.value = this.settings.waveDistance;
    this.material.uniforms.wavePeriod.value = this.settings.wavePeriod;
    this.material.uniforms.waveSpeed.value = this.settings.waveSpeed;
    this.material.uniforms.particleSize.value = this.settings.particleSize;
    this.material.uniforms.particlePulse.value = this.settings.particlePulse;

    this.frame = requestAnimationFrame(this.render.bind(this));
    if (!this.renderer) {
      return;
    }
    this.renderer.render(this.scene, this.camera);

    // this.composer.render(this.clock.getDelta());
  }
}
