<style lang="scss">
  @import '../styles/variables.scss';

  .wrapper {
    padding: 24px 0 30px;
    height: 100vh;
    text-align: center;
    overflow: auto;

    .title {
      margin-top: 34px;
      padding: 0 20px;
      text-align: center;
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 0.045em;
    }

    .video-wrapper {
      position: relative;
      margin-top: 34px;
      width: 100%;

      .slider-wrapper {
        position: absolute;
        width: 90%;
        z-index: 30;
        left: 5%;
        bottom: 10%;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        height: 8px;

        .progress-time {
          position: absolute;
          left: 0;
          top: 0;
          display: inline-block;
          height: 8px;
          background: #94b6c5;
          cursor: pointer;
        }

        .timeline {
          position: absolute;
          display: inline-block;
          top: 50%;
          left: 0;
          height: 4px;
          transform: translateY(-50%);
          background: rgba(#e9e9e9, 0.5);
          width: 100%;
          z-index: -1;
        }

        .slider {
          -webkit-appearance: none;
          width: 100%;
          height: 8px;
          outline: none;
          opacity: 0;
          -webkit-transition: 0.2s;
          transition: opacity 0.2s;
          z-index: 1;
          cursor: pointer;
        }

        .slider::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 8px;
          height: 8px;
          cursor: pointer;
          z-index: 1;
        }
      }

      .play-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .video {
        width: 100%;
      }
    }

    .bottom-wrapper {
      margin-top: 28px;
      padding: 0 20px;
      text-align: center;

      .text {
        display: inline-block;
        font-size: 12px;
        line-height: 19px;
        letter-spacing: 0.045em;
      }

      .redirect-button-wrapper {
        margin-top: 20px;
      }

      .share-links {
        width: max-content;
        margin: 20px auto 0;
      }
    }
  }
</style>

<script>
  import gsap, { TweenMax, TimelineLite } from 'gsap';
  import { createEventDispatcher, onMount } from 'svelte';
  import { initSocket } from '../helpers/websocket';
  import Button from './Button.svelte';
  import ShareLinks from './ShareLinks.svelte';
  import { getGtag } from '../tracking';

  const dispatch = createEventDispatcher();
  let counter = '';
  let wrapperRef;
  let videoRef;
  let inputSlider;
  let isPaused = true;
  let gtag;
  export let sharing = false;
  export let videoParams;

  let videoPath;
  let shareLinksRef;

  export function setFigureInitialPosition() {}

  async function toggleVideo() {
    videoRef.play();
  }

  function slideTimeUpdate() {
    const newTime = videoRef.currentTime * (100 / videoRef.duration);
    inputSlider.value = newTime;
  }

  function videoSlide() {
    const slideTo = videoRef.duration * (inputSlider.value / 100);
    videoRef.currentTime = slideTo;
  }

  export function enterAnimation() {}

  export function exitAnimation() {}

  function onMessage(e) {
    if (e.type === 'counter') {
      counter = e.data;
    }
  }

  onMount(async () => {
    initSocket(onMessage);
    setFigureInitialPosition();

    if (!sharing) {
      history.replaceState(null, null, window.location.href.split('?')[0]);
      localStorage.setItem(`${videoParams}_self`, true);
      shareLinksRef.assignSupportLgbt();
      gtag = await getGtag();
      gtag.page('/screen2');
    }

    videoPath = `${appUrl}/video/${videoParams.slice(0, 2)}/${videoParams}.mp4`;

    fetch(videoPath, { method: 'HEAD' }).then(response => {
      if (response.status == 404) {
        toManifest();
      }
    });

    videoRef.onpause = function() {
      isPaused = true;
    };

    videoRef.onplay = function() {
      isPaused = false;
    };
  });

  function toManifest() {
    console.log('toManifest?');
    window.location.href = appUrl;
  }
</script>

<div class="wrapper" bind:this="{wrapperRef}">
  <img class="logo" src="/images/mobile-logo.svg" alt="logo" />
  {#if sharing}
    <h4 class="title">Твій друг – один з {counter} толерантних людей України</h4>
  {:else}
    <h4 class="title">
      Ти – один з {counter}
      <br />
      Різних та Рівних
    </h4>
  {/if}
  <div class="video-wrapper">
    <video
      class="video"
      poster="images/video-poster.jpg"
      src="{videoPath}"
      preload
      controls
      on:click="{toggleVideo}"
      bind:this="{videoRef}"
    ></video>
  </div>
  <div class="bottom-wrapper">
    {#if sharing}
      <span class="text">
        Ти теж можеш створити власну колаборацію із зірками і долучитися до Маніфесту «Різні.рівні».
        Для цього перейди на сайт
      </span>
      <div class="redirect-button-wrapper">
        <Button width="155" text="перейти" on:click="{toManifest}" />
      </div>
    {:else}
      <span class="text">Поділитися моїм відео</span>
      <div class="share-links">
        <ShareLinks bind:this="{shareLinksRef}" />
      </div>
    {/if}
  </div>
</div>
