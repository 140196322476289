<style lang="scss">
  .audio-lines {
    height: 25vh;
    @media (orientation: landscape) {
      height: 50vh;
    }
  }

  .circle {
    fill: white;
    transition: all 0.2s linear;

    .recording & {
      fill: #fd4802;
    }
  }
</style>

<script>
  import gsap, { TimelineLite, Power1 } from 'gsap';
  import MorphSVGPlugin from '../../vendor/gsap/MorphSVGPlugin';
  import { onMount, createEventDispatcher, beforeUpdate } from 'svelte';
  import _ from 'lodash';

  gsap.registerPlugin(MorphSVGPlugin);

  let points = [];
  $: animate = animateSVG(points);

  const dispatch = createEventDispatcher();

  let timelineRef;
  let groups;
  let rootSVG;
  let recording;
  let tl;
  let interval;

  onMount(() => {
    MorphSVGPlugin.convertToPath('circle, ellipse');
    groups = _.map(rootSVG.children).reverse();
  });

  export function startRecording() {
    recording = true;

    if (interval) {
      return;
    }

    interval = setInterval(() => {
      points = _.shuffle(_.range(20).map(() => _.random(1, 5)));
    }, 250);
  }

  export function stopRecording() {
    clearInterval(interval);
    interval = null;
    reset();
  }

  function animateSVG(points) {
    if (!points.length) {
      return;
    }

    if (!recording) {
      return;
    }

    tl = new TimelineLite({ defaults: { ease: Power1.easeInOut } });

    groups.forEach((group, index) => {
      const children = _.map(group.children).reverse();
      const target = children[points[index]];
      tl.to(children[0], 0.25, { morphSVG: target, fill: target.getAttribute('fill') }, 0);
    });
    timelineRef = tl;
  }

  export function enterAnimation() {
    animateSVG();
  }

  export function play() {
    enterAnimation();
  }

  export function reset() {
    timelineRef.pause();
    const resetTimeline = new TimelineLite({
      defaults: { ease: Power1.easeInOut, fill: '#FD4802' }
    });

    groups.forEach((group, index) => {
      const [circle] = _.map(group.children).reverse();
      resetTimeline.to(circle, 0.25, { morphSVG: circle, fill: circle.getAttribute('fill') }, 0);
    });

    dispatch('reset');
  }
</script>

<div class="equalizer">
  <div class="audio-wrapper">
    <svg
      bind:this="{rootSVG}"
      class="{`audio-lines ${recording ? 'recording' : ''}`}"
      width="531"
      height="25vh"
      viewBox="0 0 531 179"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          class="hidden"
          d="M530.055 90.1553C530.055 258.232 527.684 133.432 521.055 133.432C514.427 133.432
          511.555 258.5 512.055 90.1553C512.564 -80.94 514.972 45.8716 521.055 45.8716C527.139
          45.8716 530.055 -82.4505 530.055 90.1553Z"
          fill="#FEB903"
        ></path>
        <path
          class="hidden"
          d="M529.055 89.6741C529.055 195.5 526.948 127 521.055 127C515.163 127 513.055 195.5
          513.055 89.6741C513.055 -20.4998 516.389 52.5001 521.055 52.5001C525.722 52.5001 529.055
          -20.4998 529.055 89.6741Z"
          fill="#FEB903"
        ></path>
        <path
          class="hidden"
          d="M529.055 88.2416C529.055 149.408 525.916 117.873 521.427 117.873C516.939 117.873
          514.055 148.891 514.055 88.2416C514.055 29.9871 517.308 61.5225 521.427 61.5225C525.546
          61.5225 529.055 29.987 529.055 88.2416Z"
          fill="#FEB903"
        ></path>
        <path
          class="hidden"
          d="M526.055 90.1672C526.055 116.5 523.918 104 520.971 104C518.025 104 516.055 116.5
          516.055 90.1673C516.055 60.0005 517.14 74.5006 521.055 74.5006C524.971 74.5006 526.055
          60.0005 526.055 90.1672Z"
          fill="#FEB903"
        ></path>
        <path
          class="hidden"
          d="M526.055 89.8101C526.055 101.152 523.702 93.4393 521.492 93.4393C519.283 93.4393
          517.055 101.152 517.055 89.8101C517.055 79.6783 519.465 85.8783 521.492 85.8783C523.52
          85.8783 526.055 79.6783 526.055 89.8101Z"
          fill="#FEB903"
        ></path>
        <circle class="circle" cx="521.055" cy="90.0005" r="3" fill="#FD4802"></circle>
      </g>
      <g>
        <path
          class="hidden"
          d="M496.604 33.3174C496.604 59.8793 496.461 117.449 496.604 145C496.604 178 505.5 178 494
          178C482.5 178 491.396 178 491.396 145C491.539 117.449 491.396 59.8793 491.396
          33.3174C491.396 2.5 482.238 1 494 1C505.762 1 496.604 1 496.604 33.3174Z"
          fill="#FD4802"
        ></path>
        <path
          class="hidden"
          d="M496.333 53C496.333 71.008 496.206 107.322 496.333 126C496.333 145.5 506.01 149 494.01
          149C482.01 149 491.687 145 491.687 126C491.815 107.322 491.687 74.5 491.687 53C491.687
          31.5 482.01 29 494.01 29C507.128 29 496.333 31.5 496.333 53Z"
          fill="#FD4802"
        ></path>
        <path
          class="hidden"
          d="M496.185 70.5C496.185 81.4549 496.063 95.6373 496.185 107C496.185 121.5 501.349 126
          493.971 126C486.593 126 491.758 121.5 491.758 107C491.879 95.6373 491.758 81.4549 491.758
          70.5C491.758 56 486.593 53 493.971 53C501.349 53 496.185 56 496.185 70.5Z"
          fill="#FD4802"
        ></path>
        <path
          class="hidden"
          d="M495.66 84C495.66 88.8021 495.569 91.2448 495.66 96.2257C495.66 103.5 499.534 107 494
          107C488.466 107 492.34 103.5 492.34 96.2257C492.431 91.2448 492.34 88.8021 492.34
          84C492.34 73 488.466 71 494 71C499.534 71 495.66 73 495.66 84Z"
          fill="#FD4802"
        ></path>
        <path
          class="hidden"
          d="M494.83 87.8323C494.83 89.6645 494.785 90.6734 494.83 92.1677C494.83 94 496.767 96 494
          96C491.233 96 493.17 94 493.17 92.1677C493.216 90.6734 493.17 89.6645 493.17
          87.8323C493.17 86 491.233 84 494 84C496.767 84 494.83 86 494.83 87.8323Z"
          fill="#FD4802"
        ></path>
        <path
          class="circle"
          d="M497 90C497 91.6569 495.657 93 494 93C492.343 93 491 91.6569 491 90C491 88.3431 492.343
          87 494 87C495.657 87 497 88.3431 497 90Z"
          fill="#FD4802"
        ></path>
      </g>
      <g>
        <path
          class="hidden"
          d="M470 90C470 148.5 475 169.5 475 178C471.5 178 470.375 167 467 167C463.625 167 462 178
          459 178C459 168.5 464 148.5 464 90C464 29 459 15.1012 459 0.999878C462.38 0.999981 464.652
          12 467 12C468.984 12 470.878 0.999861 475 0.999986C475 15.1012 470 29 470 90Z"
          fill="#FCAACE"
        ></path>
        <path
          class="hidden"
          d="M470 89.339C470 129 475 143.237 475 149C471.5 149 470.375 138.542 467 138.542C463.625
          138.542 462 149 459 149C459 142.559 464 129 464 89.339C464 47.9831 459 38.5602 459
          29C462.38 29.0001 464.652 39.4577 467 39.4577C470 39.4577 470.878 29 475 29.0001C475
          38.5602 470 47.9831 470 89.339Z"
          fill="#FCAACE"
        ></path>
        <path
          class="hidden"
          d="M470 89.2034C470 113 475 122.542 475 126C471 126 470.375 113 467 113C463.625 113 462
          126 459 126C459 122.136 464 113 464 89.2034C464 64.3899 460 58.5 459 53C462 53 464.652 66
          467 66C468.984 66 470.878 53 475 53C473.5 58.5 470 64.3899 470 89.2034Z"
          fill="#FCAACE"
        ></path>
        <path
          class="hidden"
          d="M470 88.8537C470 100.589 475 105.295 475 107C471 107 470.375 100.589 467
          100.589C463.625 100.589 462 107 459 107C459 105.094 464 100.589 464 88.8537C464 76.6169
          460 73.7123 459 71C462 71 464.652 77.411 467 77.411C468.984 77.411 470.878 71 475 71C473.5
          73.7123 470 76.6169 470 88.8537Z"
          fill="#FCAACE"
        ></path>
        <path
          class="hidden"
          d="M469.25 89.9512C469.25 93.863 473 95.4316 473 96C470 96 469.531 93.863 467
          93.863C464.469 93.863 463.25 96 461 96C461 95.3648 464.75 93.863 464.75 89.9512C464.75
          85.8723 461.75 84.9041 461 84C463.25 84 465.239 86.137 467 86.137C468.488 86.137 469.909
          84 473 84C471.875 84.9041 469.25 85.8723 469.25 89.9512Z"
          fill="#FCAACE"
        ></path>
        <circle class="circle" cx="467" cy="89.9996" r="3" fill="#FD4802"></circle>
      </g>
      <g>
        <path
          class="hidden"
          d="M445.47 90C445.47 103.014 442.798 114.872 442.486 126C442.249 134.461 446.976 142
          446.976 149C446.976 167 440 178 440 178C440 178 433.024 167 433.024 149C433.024 142
          437.747 134.487 437.514 126C437.211 115.002 434.53 102.819 434.53 90C434.53 76.9862
          437.514 64 437.514 53C437.514 44 432.617 36.0232 433.024 29C434.022 11.7673 440 1 440
          1C440 1 445.978 11.7673 446.976 29C447.383 36.0232 442.486 44 442.486 53C442.486 64 445.47
          76.9862 445.47 90Z"
          fill="#FEB903"
        ></path>
        <path
          class="hidden"
          d="M443.907 89.339C443.907 98.1619 441.999 106.201 441.776 113.746C441.607 119.482 444.983
          124.593 444.983 129.339C444.983 141.542 440 149 440 149C440 149 435.017 141.542 435.017
          129.339C435.017 124.593 438.391 119.499 438.224 113.746C438.008 106.289 436.093 98.03
          436.093 89.339C436.093 80.5161 438.224 71.7119 438.224 64.2542C438.224 58.1525 434.727
          52.7445 435.017 47.9831C435.73 36.2999 440 29 440 29C440 29 444.27 36.2999 444.983
          47.9831C445.273 52.7445 441.776 58.1525 441.776 64.2542C441.776 71.7119 443.907 80.5161
          443.907 89.339Z"
          fill="#FEB903"
        ></path>
        <path
          class="hidden"
          d="M443.126 89.7062C443.126 95.0735 441.599 94.9103 441.421 99.5C441.285 102.99 443.986
          111.153 443.986 114.04C443.986 121.463 440 126 440 126C440 126 436.014 121.463 436.014
          114.04C436.014 111.153 438.713 103 438.579 99.5C438.406 94.964 436.874 94.9932 436.874
          89.7062C436.874 84.3389 438.579 83.5367 438.579 79C438.579 75.2881 435.781 67.4446 436.014
          64.548C436.584 57.4408 440 53 440 53C440 53 443.416 57.4408 443.986 64.548C444.219 67.4446
          441.421 75.2881 441.421 79C441.421 83.5367 443.126 84.3389 443.126 89.7062Z"
          fill="#FEB903"
        ></path>
        <path
          class="hidden"
          d="M440.486 89.1017C440.486 91.7486 441.585 91.6681 441.407 93.9315C441.272 95.6524
          443.973 99.678 443.973 101.102C443.973 105 439.986 107 439.986 107C439.986 107 436 104.763
          436 101.102C436 99.678 438.699 95.6576 438.566 93.9315C438.392 91.6946 439.486 91.709
          439.486 89.1017C439.486 86.4548 438.566 86.0592 438.566 83.8219C438.566 81.9914 436 79 436
          76.6949C436 73 439.986 71 439.986 71C439.986 71 443.973 73 443.973 76.6949C443.973 79
          441.407 81.9914 441.407 83.8219C441.407 86.0592 440.486 86.4548 440.486 89.1017Z"
          fill="#FEB903"
        ></path>
        <path
          class="hidden"
          d="M440.002 90.0339C440.002 90.9162 440.282 91 440.926 91.6438C441.282 92 442 93.5593 442
          94.0339C442 95.3333 439.5 96 439.5 96C439.5 96 437 95.2542 437 94.0339C437 93.5593 437.718
          92 438.074 91.6438C438.718 91 438.998 90.903 438.998 90.0339C438.998 89.1516 438.602
          88.8013 438.074 88.274C437.5 87.6996 437 86.6667 437 85.8983C437 84.6667 439.5 84 439.5
          84C439.5 84 442 84.6667 442 85.8983C442 86.6667 441.5 87.6997 440.926 88.274C440.398
          88.8013 440.002 89.1516 440.002 90.0339Z"
          fill="#FEB903"
        ></path>
        <path
          d="M443 90C443 91.6569 441.657 93 440 93C438.343 93 437 91.6569 437 90C437 88.3431 438.343
          87 440 87C441.657 87 443 88.3431 443 90Z"
          class="circle"
          fill="#FD4802"
        ></path>
      </g>
      <g>
        <ellipse class="hidden" cx="413" cy="89.5" rx="9" ry="88.5" fill="#FD4802"></ellipse>
        <ellipse class="hidden" cx="413" cy="89" rx="7" ry="60" fill="#FD4802"></ellipse>
        <ellipse class="hidden" cx="413" cy="89.5" rx="7" ry="36.5" fill="#FD4802"></ellipse>
        <ellipse class="hidden" cx="413" cy="89" rx="5" ry="18" fill="#FD4802"></ellipse>
        <ellipse class="hidden" cx="413" cy="90" rx="3" ry="6" fill="#FD4802"></ellipse>
        <circle class="circle" cx="413" cy="90" r="3" fill="#FD4802"></circle>
      </g>
      <g>
        <path
          class="hidden"
          d="M388.604 33.3174C388.604 59.8793 388.461 117.449 388.604 145C388.604 178 397.5 178 386
          178C374.5 178 383.396 178 383.396 145C383.539 117.449 383.396 59.8793 383.396
          33.3174C383.396 2.5 374.238 1 386 1C397.762 1 388.604 1 388.604 33.3174Z"
          fill="#FCAACE"
        ></path>
        <path
          class="hidden"
          d="M388.333 53C388.333 71.008 388.206 107.322 388.333 126C388.333 145.5 398.01 149 386.01
          149C374.01 149 383.687 145 383.687 126C383.815 107.322 383.687 74.5 383.687 53C383.687
          31.5 374.01 29 386.01 29C399.128 29 388.333 31.5 388.333 53Z"
          fill="#FCAACE"
        ></path>
        <path
          class="hidden"
          d="M388.185 70.5C388.185 81.4549 388.063 95.6373 388.185 107C388.185 121.5 393.349 126
          385.971 126C378.593 126 383.758 121.5 383.758 107C383.879 95.6373 383.758 81.4549 383.758
          70.5C383.758 56 378.593 53 385.971 53C393.349 53 388.185 56 388.185 70.5Z"
          fill="#FCAACE"
        ></path>
        <path
          class="hidden"
          d="M387.66 84C387.66 88.8021 387.569 91.2448 387.66 96.2257C387.66 103.5 391.534 107 386
          107C380.466 107 384.34 103.5 384.34 96.2257C384.431 91.2448 384.34 88.8021 384.34
          84C384.34 73 380.466 71 386 71C391.534 71 387.66 73 387.66 84Z"
          fill="#FCAACE"
        ></path>
        <path
          class="hidden"
          d="M386.83 87.8323C386.83 89.6645 386.785 90.6734 386.83 92.1677C386.83 94 388.767 96 386
          96C383.233 96 385.17 94 385.17 92.1677C385.216 90.6734 385.17 89.6645 385.17
          87.8323C385.17 86 383.233 84 386 84C388.767 84 386.83 86 386.83 87.8323Z"
          fill="#FCAACE"
        ></path>
        <path
          d="M389 90C389 91.6569 387.657 93 386 93C384.343 93 383 91.6569 383 90C383 88.3431 384.343
          87 386 87C387.657 87 389 88.3431 389 90Z"
          fill="#FD4802"
          class="circle"
        ></path>
      </g>
      <g>
        <path
          class="hidden"
          d="M368.055 90.1553C368.055 258.232 365.684 133.432 359.055 133.432C352.427 133.432
          349.555 258.5 350.055 90.1553C350.564 -80.94 352.972 45.8716 359.055 45.8716C365.139
          45.8716 368.055 -82.4505 368.055 90.1553Z"
          fill="#FEB903"
        ></path>
        <path
          class="hidden"
          d="M367.055 89.6741C367.055 195.5 364.948 127 359.055 127C353.163 127 351.055 195.5
          351.055 89.6741C351.055 -20.4998 354.389 52.5001 359.055 52.5001C363.722 52.5001 367.055
          -20.4998 367.055 89.6741Z"
          fill="#FEB903"
        ></path>
        <path
          class="hidden"
          d="M367.055 88.2416C367.055 149.408 363.916 117.873 359.427 117.873C354.939 117.873
          352.055 148.891 352.055 88.2416C352.055 29.9871 355.308 61.5225 359.427 61.5225C363.546
          61.5225 367.055 29.987 367.055 88.2416Z"
          fill="#FEB903"
        ></path>
        <path
          class="hidden"
          d="M364.055 90.1672C364.055 116.5 361.918 104 358.971 104C356.025 104 354.055 116.5
          354.055 90.1673C354.055 60.0005 355.14 74.5006 359.055 74.5006C362.971 74.5006 364.055
          60.0005 364.055 90.1672Z"
          fill="#FEB903"
        ></path>
        <path
          class="hidden"
          d="M364.055 89.8101C364.055 101.152 361.702 93.4393 359.492 93.4393C357.283 93.4393
          355.055 101.152 355.055 89.8101C355.055 79.6783 357.465 85.8783 359.492 85.8783C361.52
          85.8783 364.055 79.6783 364.055 89.8101Z"
          fill="#FEB903"
        ></path>
        <circle class="circle" cx="359.055" cy="90.0005" r="3" fill="#FD4802"></circle>
      </g>
      <g>
        <path
          class="hidden"
          d="M334.289 89.5C334.289 163 348.712 178 332.789 178C316.865 178 330.289 157 330.289
          89.5C330.289 22 317.365 1 332.789 1C348.212 1 334.289 22 334.289 89.5Z"
          fill="#FD4802"
        ></path>
        <path
          class="hidden"
          d="M334.352 89.7469C334.352 128.585 343.962 149 332.389 149C320.815 149 330.473 126.593
          330.473 89.7469C330.473 47.4232 319.889 29 332.389 29C345.938 29 334.352 44.9336 334.352
          89.7469Z"
          fill="#FD4802"
        ></path>
        <path
          class="hidden"
          d="M334.352 89.5C334.352 110 342.508 126 332.352 126C322.196 126 330.352 110.5 330.352
          89.5C330.352 64 323.451 53 332.64 53C341.829 53 334.352 65.5 334.352 89.5Z"
          fill="#FD4802"
        ></path>
        <path
          class="hidden"
          d="M333.302 89C333.302 102.135 338.42 107 332.352 107C326.284 107 331.196 102.622 331.196
          89C331.196 75.3784 326.862 71 332.352 71C337.842 71 333.302 75.3784 333.302 89Z"
          fill="#FD4802"
        ></path>
        <path
          class="hidden"
          d="M333.223 90C333.223 93.3137 338.446 96 332.352 96C326.258 96 331.191 93.3137 331.191
          90C331.191 86.6863 326.839 84 332.352 84C337.865 84 333.223 86.6863 333.223 90Z"
          fill="#FD4802"
        ></path>
        <path
          d="M335.241 90C335.241 91.6569 333.898 93 332.241 93C330.584 93 329.241 91.6569 329.241
          90C329.241 88.3431 330.584 87 332.241 87C333.898 87 335.241 88.3431 335.241 90Z"
          class="circle"
          fill="#FD4802"
        ></path>
      </g>
      <g>
        <path
          class="hidden"
          d="M308 90C308 148.5 313 169.5 313 178C309.5 178 308.375 167 305 167C301.625 167 300 178
          297 178C297 168.5 302 148.5 302 90C302 29 297 15.1012 297 0.999878C300.38 0.999981 302.652
          12 305 12C306.984 12 308.878 0.999861 313 0.999986C313 15.1012 308 29 308 90Z"
          fill="#FCAACE"
        ></path>
        <path
          class="hidden"
          d="M308 89.339C308 129 313 143.237 313 149C309.5 149 308.375 138.542 305 138.542C301.625
          138.542 300 149 297 149C297 142.559 302 129 302 89.339C302 47.9831 297 38.5602 297
          29C300.38 29.0001 302.652 39.4577 305 39.4577C308 39.4577 308.878 29 313 29.0001C313
          38.5602 308 47.9831 308 89.339Z"
          fill="#FCAACE"
        ></path>
        <path
          class="hidden"
          d="M308 89.2034C308 113 313 122.542 313 126C309 126 308.375 113 305 113C301.625 113 300
          126 297 126C297 122.136 302 113 302 89.2034C302 64.3899 298 58.5 297 53C300 53 302.652 66
          305 66C306.984 66 308.878 53 313 53C311.5 58.5 308 64.3899 308 89.2034Z"
          fill="#FCAACE"
        ></path>
        <path
          class="hidden"
          d="M308 88.8537C308 100.589 313 105.295 313 107C309 107 308.375 100.589 305
          100.589C301.625 100.589 300 107 297 107C297 105.094 302 100.589 302 88.8537C302 76.6169
          298 73.7123 297 71C300 71 302.652 77.411 305 77.411C306.984 77.411 308.878 71 313 71C311.5
          73.7123 308 76.6169 308 88.8537Z"
          fill="#FCAACE"
        ></path>
        <path
          class="hidden"
          d="M307.25 89.9512C307.25 93.863 311 95.4316 311 96C308 96 307.531 93.863 305
          93.863C302.469 93.863 301.25 96 299 96C299 95.3648 302.75 93.863 302.75 89.9512C302.75
          85.8723 299.75 84.9041 299 84C301.25 84 303.239 86.137 305 86.137C306.488 86.137 307.909
          84 311 84C309.875 84.9041 307.25 85.8723 307.25 89.9512Z"
          fill="#FCAACE"
        ></path>
        <circle class="circle" cx="305" cy="89.9996" r="3" fill="#FD4802"></circle>
      </g>
      <g>
        <path
          class="hidden"
          d="M283.47 90C283.47 103.014 280.798 114.872 280.486 126C280.249 134.461 284.976 142
          284.976 149C284.976 167 278 178 278 178C278 178 271.024 167 271.024 149C271.024 142
          275.747 134.487 275.514 126C275.211 115.002 272.53 102.819 272.53 90C272.53 76.9862
          275.514 64 275.514 53C275.514 44 270.617 36.0232 271.024 29C272.022 11.7673 278 1 278
          1C278 1 283.978 11.7673 284.976 29C285.383 36.0232 280.486 44 280.486 53C280.486 64 283.47
          76.9862 283.47 90Z"
          fill="#FEB903"
        ></path>
        <path
          class="hidden"
          d="M281.907 89.339C281.907 98.1619 279.999 106.201 279.776 113.746C279.607 119.482 282.983
          124.593 282.983 129.339C282.983 141.542 278 149 278 149C278 149 273.017 141.542 273.017
          129.339C273.017 124.593 276.391 119.499 276.224 113.746C276.008 106.289 274.093 98.03
          274.093 89.339C274.093 80.5161 276.224 71.7119 276.224 64.2542C276.224 58.1525 272.727
          52.7445 273.017 47.9831C273.73 36.2999 278 29 278 29C278 29 282.27 36.2999 282.983
          47.9831C283.273 52.7445 279.776 58.1525 279.776 64.2542C279.776 71.7119 281.907 80.5161
          281.907 89.339Z"
          fill="#FEB903"
        ></path>
        <path
          class="hidden"
          d="M281.126 89.7062C281.126 95.0735 279.599 94.9103 279.421 99.5C279.285 102.99 281.986
          111.153 281.986 114.04C281.986 121.463 278 126 278 126C278 126 274.014 121.463 274.014
          114.04C274.014 111.153 276.713 103 276.579 99.5C276.406 94.964 274.874 94.9932 274.874
          89.7062C274.874 84.3389 276.579 83.5367 276.579 79C276.579 75.2881 273.781 67.4446 274.014
          64.548C274.584 57.4408 278 53 278 53C278 53 281.416 57.4408 281.986 64.548C282.219 67.4446
          279.421 75.2881 279.421 79C279.421 83.5367 281.126 84.3389 281.126 89.7062Z"
          fill="#FEB903"
        ></path>
        <path
          class="hidden"
          d="M278.486 89.1017C278.486 91.7486 279.585 91.6681 279.407 93.9315C279.272 95.6524
          281.973 99.678 281.973 101.102C281.973 105 277.986 107 277.986 107C277.986 107 274 104.763
          274 101.102C274 99.678 276.699 95.6576 276.566 93.9315C276.392 91.6946 277.486 91.709
          277.486 89.1017C277.486 86.4548 276.566 86.0592 276.566 83.8219C276.566 81.9914 274 79 274
          76.6949C274 73 277.986 71 277.986 71C277.986 71 281.973 73 281.973 76.6949C281.973 79
          279.407 81.9914 279.407 83.8219C279.407 86.0592 278.486 86.4548 278.486 89.1017Z"
          fill="#FEB903"
        ></path>
        <path
          class="hidden"
          d="M278.002 90.0339C278.002 90.9162 278.282 91 278.926 91.6438C279.282 92 280 93.5593 280
          94.0339C280 95.3333 277.5 96 277.5 96C277.5 96 275 95.2542 275 94.0339C275 93.5593 275.718
          92 276.074 91.6438C276.718 91 276.998 90.903 276.998 90.0339C276.998 89.1516 276.602
          88.8013 276.074 88.274C275.5 87.6996 275 86.6667 275 85.8983C275 84.6667 277.5 84 277.5
          84C277.5 84 280 84.6667 280 85.8983C280 86.6667 279.5 87.6997 278.926 88.274C278.398
          88.8013 278.002 89.1516 278.002 90.0339Z"
          fill="#FEB903"
        ></path>
        <path
          d="M281 90C281 91.6569 279.657 93 278 93C276.343 93 275 91.6569 275 90C275 88.3431 276.343
          87 278 87C279.657 87 281 88.3431 281 90Z"
          class="circle"
          fill="#FD4802"
        ></path>
      </g>
      <g>
        <ellipse class="hidden" cx="251" cy="89.5" rx="9" ry="88.5" fill="#FD4802"></ellipse>
        <ellipse class="hidden" cx="251" cy="89" rx="7" ry="60" fill="#FD4802"></ellipse>
        <ellipse class="hidden" cx="251" cy="89.5" rx="7" ry="36.5" fill="#FD4802"></ellipse>
        <ellipse class="hidden" cx="251" cy="89" rx="5" ry="18" fill="#FD4802"></ellipse>
        <ellipse class="hidden" cx="251" cy="90" rx="3" ry="6" fill="#FD4802"></ellipse>
        <circle class="circle" cx="251" cy="90" r="3" fill="#FD4802"></circle>
      </g>
      <g>
        <path
          class="hidden"
          d="M226.604 33.3174C226.604 59.8793 226.461 117.449 226.604 145C226.604 178 235.5 178 224
          178C212.5 178 221.396 178 221.396 145C221.539 117.449 221.396 59.8793 221.396
          33.3174C221.396 2.5 212.238 1 224 1C235.762 1 226.604 1 226.604 33.3174Z"
          fill="#FCAACE"
        ></path>
        <path
          class="hidden"
          d="M226.333 53C226.333 71.008 226.206 107.322 226.333 126C226.333 145.5 236.01 149 224.01
          149C212.01 149 221.687 145 221.687 126C221.815 107.322 221.687 74.5 221.687 53C221.687
          31.5 212.01 29 224.01 29C237.128 29 226.333 31.5 226.333 53Z"
          fill="#FCAACE"
        ></path>
        <path
          class="hidden"
          d="M226.185 70.5C226.185 81.4549 226.063 95.6373 226.185 107C226.185 121.5 231.349 126
          223.971 126C216.593 126 221.758 121.5 221.758 107C221.879 95.6373 221.758 81.4549 221.758
          70.5C221.758 56 216.593 53 223.971 53C231.349 53 226.185 56 226.185 70.5Z"
          fill="#FCAACE"
        ></path>
        <path
          class="hidden"
          d="M225.66 84C225.66 88.8021 225.569 91.2448 225.66 96.2257C225.66 103.5 229.534 107 224
          107C218.466 107 222.34 103.5 222.34 96.2257C222.431 91.2448 222.34 88.8021 222.34
          84C222.34 73 218.466 71 224 71C229.534 71 225.66 73 225.66 84Z"
          fill="#FCAACE"
        ></path>
        <path
          class="hidden"
          d="M224.83 87.8323C224.83 89.6645 224.785 90.6734 224.83 92.1677C224.83 94 226.767 96 224
          96C221.233 96 223.17 94 223.17 92.1677C223.216 90.6734 223.17 89.6645 223.17
          87.8323C223.17 86 221.233 84 224 84C226.767 84 224.83 86 224.83 87.8323Z"
          fill="#FCAACE"
        ></path>
        <path
          d="M227 90C227 91.6569 225.657 93 224 93C222.343 93 221 91.6569 221 90C221 88.3431 222.343
          87 224 87C225.657 87 227 88.3431 227 90Z"
          class="circle"
          fill="#FD4802"
        ></path>
      </g>
      <g>
        <path
          class="hidden"
          d="M206.055 90.1553C206.055 258.232 203.684 133.432 197.055 133.432C190.427 133.432
          187.555 258.5 188.055 90.1553C188.564 -80.94 190.972 45.8716 197.055 45.8716C203.139
          45.8716 206.055 -82.4505 206.055 90.1553Z"
          fill="#FEB903"
        ></path>
        <path
          class="hidden"
          d="M205.055 89.6741C205.055 195.5 202.948 127 197.055 127C191.163 127 189.055 195.5
          189.055 89.6741C189.055 -20.4998 192.389 52.5001 197.055 52.5001C201.722 52.5001 205.055
          -20.4998 205.055 89.6741Z"
          fill="#FEB903"
        ></path>
        <path
          class="hidden"
          d="M205.055 88.2416C205.055 149.408 201.916 117.873 197.427 117.873C192.939 117.873
          190.055 148.891 190.055 88.2416C190.055 29.9871 193.308 61.5225 197.427 61.5225C201.546
          61.5225 205.055 29.987 205.055 88.2416Z"
          fill="#FEB903"
        ></path>
        <path
          class="hidden"
          d="M202.055 90.1672C202.055 116.5 199.918 104 196.971 104C194.025 104 192.055 116.5
          192.055 90.1673C192.055 60.0005 193.14 74.5006 197.055 74.5006C200.971 74.5006 202.055
          60.0005 202.055 90.1672Z"
          fill="#FEB903"
        ></path>
        <path
          class="hidden"
          d="M202.055 89.8101C202.055 101.152 199.702 93.4393 197.492 93.4393C195.283 93.4393
          193.055 101.152 193.055 89.8101C193.055 79.6783 195.465 85.8783 197.492 85.8783C199.52
          85.8783 202.055 79.6783 202.055 89.8101Z"
          fill="#FEB903"
        ></path>
        <circle class="circle" cx="197.055" cy="90.0005" r="3" fill="#FD4802"></circle>
      </g>
      <g>
        <path
          class="hidden"
          d="M172 89.5C172 163 186.423 178 170.5 178C154.577 178 168 157 168 89.5C168 22 155.077 1
          170.5 1C185.923 1 172 22 172 89.5Z"
          fill="#FD4802"
        ></path>
        <path
          class="hidden"
          d="M172.063 89.7469C172.063 128.585 181.673 149 170.1 149C158.526 149 168.184 126.593
          168.184 89.7469C168.184 47.4232 157.6 29 170.1 29C183.649 29 172.063 44.9336 172.063
          89.7469Z"
          fill="#FD4802"
        ></path>
        <path
          class="hidden"
          d="M172.063 89.5C172.063 110 180.219 126 170.063 126C159.907 126 168.063 110.5 168.063
          89.5C168.063 64 161.162 53 170.351 53C179.54 53 172.063 65.5 172.063 89.5Z"
          fill="#FD4802"
        ></path>
        <path
          class="hidden"
          d="M171.013 89C171.013 102.135 176.131 107 170.063 107C163.995 107 168.907 102.622 168.907
          89C168.907 75.3784 164.573 71 170.063 71C175.553 71 171.013 75.3784 171.013 89Z"
          fill="#FD4802"
        ></path>
        <path
          class="hidden"
          d="M170.934 90C170.934 93.3137 176.157 96 170.063 96C163.969 96 168.902 93.3137 168.902
          90C168.902 86.6863 164.55 84 170.063 84C175.577 84 170.934 86.6863 170.934 90Z"
          fill="#FD4802"
        ></path>
        <path
          d="M172.952 90C172.952 91.6569 171.609 93 169.952 93C168.295 93 166.952 91.6569 166.952
          90C166.952 88.3431 168.295 87 169.952 87C171.609 87 172.952 88.3431 172.952 90Z"
          class="circle"
          fill="#FD4802"
        ></path>
      </g>
      <g>
        <path
          class="hidden"
          d="M146 90C146 148.5 151 169.5 151 178C147.5 178 146.375 167 143 167C139.625 167 138 178
          135 178C135 168.5 140 148.5 140 90C140 29 135 15.1012 135 0.999878C138.38 0.999981 140.652
          12 143 12C144.984 12 146.878 0.999861 151 0.999986C151 15.1012 146 29 146 90Z"
          fill="#FCAACE"
        ></path>
        <path
          class="hidden"
          d="M146 89.339C146 129 151 143.237 151 149C147.5 149 146.375 138.542 143 138.542C139.625
          138.542 138 149 135 149C135 142.559 140 129 140 89.339C140 47.9831 135 38.5602 135
          29C138.38 29.0001 140.652 39.4577 143 39.4577C146 39.4577 146.878 29 151 29.0001C151
          38.5602 146 47.9831 146 89.339Z"
          fill="#FCAACE"
        ></path>
        <path
          class="hidden"
          d="M146 89.2034C146 113 151 122.542 151 126C147 126 146.375 113 143 113C139.625 113 138
          126 135 126C135 122.136 140 113 140 89.2034C140 64.3899 136 58.5 135 53C138 53 140.652 66
          143 66C144.984 66 146.878 53 151 53C149.5 58.5 146 64.3899 146 89.2034Z"
          fill="#FCAACE"
        ></path>
        <path
          class="hidden"
          d="M146 88.8537C146 100.589 151 105.295 151 107C147 107 146.375 100.589 143
          100.589C139.625 100.589 138 107 135 107C135 105.094 140 100.589 140 88.8537C140 76.6169
          136 73.7123 135 71C138 71 140.652 77.411 143 77.411C144.984 77.411 146.878 71 151 71C149.5
          73.7123 146 76.6169 146 88.8537Z"
          fill="#FCAACE"
        ></path>
        <path
          class="hidden"
          d="M145.25 89.9512C145.25 93.863 149 95.4316 149 96C146 96 145.531 93.863 143
          93.863C140.469 93.863 139.25 96 137 96C137 95.3648 140.75 93.863 140.75 89.9512C140.75
          85.8723 137.75 84.9041 137 84C139.25 84 141.239 86.137 143 86.137C144.488 86.137 145.909
          84 149 84C147.875 84.9041 145.25 85.8723 145.25 89.9512Z"
          fill="#FCAACE"
        ></path>
        <circle class="circle" cx="143" cy="89.9996" r="3" fill="#FD4802"></circle>
      </g>
      <g>
        <path
          class="hidden"
          d="M121.47 90C121.47 103.014 118.798 114.872 118.486 126C118.249 134.461 122.976 142
          122.976 149C122.976 167 116 178 116 178C116 178 109.024 167 109.024 149C109.024 142
          113.747 134.487 113.514 126C113.211 115.002 110.53 102.819 110.53 90C110.53 76.9862
          113.514 64 113.514 53C113.514 44 108.617 36.0232 109.024 29C110.022 11.7673 116 1 116
          1C116 1 121.978 11.7673 122.976 29C123.383 36.0232 118.486 44 118.486 53C118.486 64 121.47
          76.9862 121.47 90Z"
          fill="#FEB903"
        ></path>
        <path
          class="hidden"
          d="M119.907 89.339C119.907 98.1619 117.999 106.201 117.776 113.746C117.607 119.482 120.983
          124.593 120.983 129.339C120.983 141.542 116 149 116 149C116 149 111.017 141.542 111.017
          129.339C111.017 124.593 114.391 119.499 114.224 113.746C114.008 106.289 112.093 98.03
          112.093 89.339C112.093 80.5161 114.224 71.7119 114.224 64.2542C114.224 58.1525 110.727
          52.7445 111.017 47.9831C111.73 36.2999 116 29 116 29C116 29 120.27 36.2999 120.983
          47.9831C121.273 52.7445 117.776 58.1525 117.776 64.2542C117.776 71.7119 119.907 80.5161
          119.907 89.339Z"
          fill="#FEB903"
        ></path>
        <path
          class="hidden"
          d="M119.126 89.7062C119.126 95.0735 117.599 94.9103 117.421 99.5C117.285 102.99 119.986
          111.153 119.986 114.04C119.986 121.463 116 126 116 126C116 126 112.014 121.463 112.014
          114.04C112.014 111.153 114.713 103 114.579 99.5C114.406 94.964 112.874 94.9932 112.874
          89.7062C112.874 84.3389 114.579 83.5367 114.579 79C114.579 75.2881 111.781 67.4446 112.014
          64.548C112.584 57.4408 116 53 116 53C116 53 119.416 57.4408 119.986 64.548C120.219 67.4446
          117.421 75.2881 117.421 79C117.421 83.5367 119.126 84.3389 119.126 89.7062Z"
          fill="#FEB903"
        ></path>
        <path
          class="hidden"
          d="M116.486 89.1017C116.486 91.7486 117.585 91.6681 117.407 93.9315C117.272 95.6524
          119.973 99.678 119.973 101.102C119.973 105 115.986 107 115.986 107C115.986 107 112 104.763
          112 101.102C112 99.678 114.699 95.6576 114.566 93.9315C114.392 91.6946 115.486 91.709
          115.486 89.1017C115.486 86.4548 114.566 86.0592 114.566 83.8219C114.566 81.9914 112 79 112
          76.6949C112 73 115.986 71 115.986 71C115.986 71 119.973 73 119.973 76.6949C119.973 79
          117.407 81.9914 117.407 83.8219C117.407 86.0592 116.486 86.4548 116.486 89.1017Z"
          fill="#FEB903"
        ></path>
        <path
          class="hidden"
          d="M116.002 90.0339C116.002 90.9162 116.282 91 116.926 91.6438C117.282 92 118 93.5593 118
          94.0339C118 95.3333 115.5 96 115.5 96C115.5 96 113 95.2542 113 94.0339C113 93.5593 113.718
          92 114.074 91.6438C114.718 91 114.998 90.903 114.998 90.0339C114.998 89.1516 114.602
          88.8013 114.074 88.274C113.5 87.6996 113 86.6667 113 85.8983C113 84.6667 115.5 84 115.5
          84C115.5 84 118 84.6667 118 85.8983C118 86.6667 117.5 87.6997 116.926 88.274C116.398
          88.8013 116.002 89.1516 116.002 90.0339Z"
          fill="#FEB903"
        ></path>
        <path
          d="M119 90C119 91.6569 117.657 93 116 93C114.343 93 113 91.6569 113 90C113 88.3431 114.343
          87 116 87C117.657 87 119 88.3431 119 90Z"
          fill="#FD4802"
          class="circle"
        ></path>
      </g>
      <g>
        <ellipse class="hidden" cx="89" cy="89.5" rx="9" ry="88.5" fill="#FD4802"></ellipse>
        <ellipse class="hidden" cx="89" cy="89" rx="7" ry="60" fill="#FD4802"></ellipse>
        <ellipse class="hidden" cx="89" cy="89.5" rx="7" ry="36.5" fill="#FD4802"></ellipse>
        <ellipse class="hidden" cx="89" cy="89" rx="5" ry="18" fill="#FD4802"></ellipse>
        <ellipse class="hidden" cx="89" cy="90" rx="3" ry="6" fill="#FD4802"></ellipse>
        <circle class="circle" cx="89" cy="90" r="3" fill="#FD4802"></circle>
      </g>
      <g>
        <path
          class="hidden"
          d="M64.6037 33.3174C64.6037 59.8793 64.4606 117.449 64.6037 145C64.6037 178 73.5 178 62
          178C50.5 178 59.3964 178 59.3964 145C59.5395 117.449 59.3964 59.8793 59.3964
          33.3174C59.3964 2.5 50.2376 1 62 1C73.7623 1 64.6037 1 64.6037 33.3174Z"
          fill="#FCAACE"
        ></path>
        <path
          class="hidden"
          d="M64.3332 53C64.3332 71.008 64.2056 107.322 64.3332 126C64.3332 145.5 74.0102 149
          62.0102 149C50.0102 149 59.6873 145 59.6873 126C59.8149 107.322 59.6873 74.5 59.6873
          53C59.6873 31.5 50.0102 29 62.0102 29C75.1281 29 64.3332 31.5 64.3332 53Z"
          fill="#FCAACE"
        ></path>
        <path
          class="hidden"
          d="M64.1846 70.5C64.1846 81.4549 64.063 95.6373 64.1846 107C64.1846 121.5 69.3494 126
          61.971 126C54.5926 126 59.7575 121.5 59.7575 107C59.8791 95.6373 59.7575 81.4549 59.7575
          70.5C59.7575 56 54.5927 53 61.971 53C69.3492 53 64.1846 56 64.1846 70.5Z"
          fill="#FCAACE"
        ></path>
        <path
          class="hidden"
          d="M63.6602 84C63.6602 88.8021 63.569 91.2448 63.6602 96.2257C63.6602 103.5 67.5338 107 62
          107C56.4662 107 60.3399 103.5 60.3399 96.2257C60.4311 91.2448 60.3399 88.8021 60.3399
          84C60.3399 73 56.4663 71 62 71C67.5337 71 63.6602 73 63.6602 84Z"
          fill="#FCAACE"
        ></path>
        <path
          class="hidden"
          d="M62.8301 87.8323C62.8301 89.6645 62.7845 90.6734 62.8301 92.1677C62.8301 94 64.7669 96
          62 96C59.2331 96 61.1699 94 61.1699 92.1677C61.2156 90.6734 61.1699 89.6645 61.1699
          87.8323C61.1699 86 59.2332 84 62 84C64.7668 84 62.8301 86 62.8301 87.8323Z"
          fill="#FCAACE"
        ></path>
        <path
          d="M65 90C65 91.6569 63.6569 93 62 93C60.3431 93 59 91.6569 59 90C59 88.3431 60.3431 87 62
          87C63.6569 87 65 88.3431 65 90Z"
          class="circle"
          fill="#FD4802"
        ></path>
      </g>
      <g>
        <path
          class="hidden"
          d="M44.0555 90.1553C44.0555 258.232 41.6844 133.432 35.0555 133.432C28.4266 133.432
          25.5555 258.5 26.0555 90.1553C26.5637 -80.94 28.9721 45.8716 35.0555 45.8716C41.1388
          45.8716 44.0555 -82.4505 44.0555 90.1553Z"
          fill="#FEB903"
        ></path>
        <path
          class="hidden"
          d="M43.0555 89.6741C43.0555 195.5 40.9478 127 35.0555 127C29.1631 127 27.0555 195.5
          27.0555 89.6741C27.0555 -20.4998 30.3888 52.5001 35.0555 52.5001C39.7221 52.5001 43.0555
          -20.4998 43.0555 89.6741Z"
          fill="#FEB903"
        ></path>
        <path
          class="hidden"
          d="M43.0555 88.2416C43.0555 149.408 39.9156 117.873 35.4273 117.873C30.939 117.873 28.0555
          148.891 28.0555 88.2416C28.0555 29.9871 31.3083 61.5225 35.4273 61.5225C39.5462 61.5225
          43.0555 29.987 43.0555 88.2416Z"
          fill="#FEB903"
        ></path>
        <path
          class="hidden"
          d="M40.0555 90.1672C40.0555 116.5 37.9175 104 34.9713 104C32.0251 104 30.0555 116.5
          30.0555 90.1673C30.0555 60.0005 31.1396 74.5006 35.0555 74.5006C38.9713 74.5006 40.0555
          60.0005 40.0555 90.1672Z"
          fill="#FEB903"
        ></path>
        <path
          class="hidden"
          d="M40.0555 89.8101C40.0555 101.152 37.702 93.4393 35.4924 93.4393C33.2827 93.4393 31.0555
          101.152 31.0555 89.8101C31.0555 79.6783 33.4645 85.8783 35.4924 85.8783C37.5201 85.8783
          40.0555 79.6783 40.0555 89.8101Z"
          fill="#FEB903"
        ></path>
        <circle class="circle" cx="35.0555" cy="90.0005" r="3" fill="#FD4802"></circle>
      </g>
      <g>
        <path
          class="hidden"
          d="M9.69315 89.5C9.69315 163 23.5344 178 7.61097 178C-8.3125 178 5.69317 157 5.69317
          89.5C5.69317 22 -7.31248 1 8.11099 1C23.5345 1 9.69315 22 9.69315 89.5Z"
          fill="#FD4802"
        ></path>
        <path
          class="hidden"
          d="M9.611 89.7469C9.611 128.585 19.2212 149 7.64773 149C-3.92572 149 5.73182 126.593
          5.73182 89.7469C5.73182 47.4232 -4.85228 29 7.64773 29C21.197 29 9.611 44.9336 9.611
          89.7469Z"
          fill="#FD4802"
        ></path>
        <path
          class="hidden"
          d="M9.61098 89.5C9.61098 110 17.7674 126 7.61099 126C-2.54543 126 5.61098 110.5 5.61098
          89.5C5.61098 64 -1.28984 53 7.89924 53C17.0884 53 9.61098 65.5 9.61098 89.5Z"
          fill="#FD4802"
        ></path>
        <path
          class="hidden"
          d="M8.56098 89C8.56098 102.135 13.6787 107 7.61099 107C1.5433 107 6.45527 102.622 6.45527
          89C6.45527 75.3784 2.12122 71 7.61099 71C13.1008 71 8.56098 75.3784 8.56098 89Z"
          fill="#FD4802"
        ></path>
        <path
          class="hidden"
          d="M8.48154 90C8.48154 93.3137 13.7049 96 7.61099 96C1.51713 96 6.45029 93.3137 6.45029
          90C6.45029 86.6863 2.09754 84 7.61099 84C13.1245 84 8.48154 86.6863 8.48154 90Z"
          fill="#FD4802"
        ></path>
        <path
          d="M10.5 90C10.5 91.6569 9.15685 93 7.5 93C5.84315 93 4.5 91.6569 4.5 90C4.5 88.3431
          5.84315 87 7.5 87C9.15685 87 10.5 88.3431 10.5 90Z"
          class="circle"
          fill="#FD4802"
        ></path>
      </g>
    </svg>

  </div>

</div>
