import firebase from 'firebase/app';
import 'firebase/firestore';

let firebaseConfig = {
  apiKey: firebaseApiKey,
  authDomain: firebaseAuthDomain,
  projectId: firebaseProjectId,
  appId: firebaseAppId
};

firebase.initializeApp(firebaseConfig);

export default firebase;
