import _ from 'lodash';

const regions = [
  5,
  7,
  9,
  12,
  14,
  18,
  21,
  23,
  26,
  32,
  35,
  43,
  46,
  48,
  51,
  53,
  56,
  59,
  61,
  63,
  65,
  68,
  71,
  74,
  77,
];

export function getRandomRegion() {
  return regions[_.random(0, regions.length)];
}

export function getRandomWaveEvent(onMessage, first = false) {
  const timeout = first ? 1000 : _.random(2, 4, true) * 1000;
  const region = getRandomRegion();

  setTimeout(() => {
    if (typeof onMessage !== 'function') {
      return;
    }

    onMessage({ region: region || 5 }, first);
    getRandomWaveEvent(onMessage);
  }, timeout);
}
