<style lang="scss">
  @import '../styles/variables.scss';

  .wrapper {
    @include flex;
    width: 100%;
    height: 100%;
    min-height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    // transform: translate(0, -50%);
    background-color: #ededed;
    overflow: hidden;
    .inner-content {
      @include flex(null, center);
      flex-wrap: nowrap;
      margin: auto;
      padding: 0 55px;
      width: 100%;
      height: 100%;
      background: #fafafa;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      // box-shadow: 0px -1px 10px rgba(182, 182, 182, 0.25);
      // border-radius: 20px;

      .text {
        @include flex(null, center);
        box-sizing: border-box;
        width: 55%;
        height: 100%;
        font-size: 12px;
        line-height: 150.5%;
      }

      .phones {
        margin-left: auto;
        box-sizing: border-box;
        width: 40%;
      }
    }
  }
</style>

<script>
  import gsap, { TweenMax, TimelineLite, Back, Elastic } from 'gsap';
  import { createEventDispatcher, onMount } from 'svelte';

  const dispatch = createEventDispatcher();
  let phoneFirst;
  let phoneSecond;
  let phoneFirstWeighty;
  let figureContainer;
  let timeline;
  let wrapperRef;

  export function setFigureInitialPosition() {
    const phoneFirstParams = {
      opacity: 0.2,
      transformOrigin: 'bottom right',
      rotate: 90,
      x: '-5.2%'
    };
    const phoneSecondParams = { opacity: 1 };
    const figureContainerParams = { x: '0' };

    const phoneFirstWeightyParams = { ...phoneFirstParams, opacity: 0 };

    TweenMax.set(wrapperRef, { zIndex: -1, opacity: 0 });
    TweenMax.set(figureContainer, figureContainerParams);
    TweenMax.set(phoneFirst, phoneFirstParams);
    TweenMax.set(phoneFirstWeighty, phoneFirstWeightyParams);
    TweenMax.set(phoneSecond, phoneSecondParams);
  }

  export function enterAnimation() {
    TweenMax.set(wrapperRef, { zIndex: 99999, opacity: 1 });

    if (timeline) {
      return timeline.restart();
    }

    timeline = new TimelineLite({
      onComplete() {
        timeline.restart();
      },
      useFrames: true,
      immediateRender: true
    });

    timeline
      .to([phoneFirst, phoneFirstWeighty], 0.8, {
        opacity: 1,
        rotate: 0,
        x: 0
      })
      .to(
        phoneSecond,
        0.8,
        {
          opacity: 0.2
        },
        0
      )
      .to(
        [phoneFirst, phoneFirstWeighty],
        0.6,
        {
          opacity: 0
        },
        0.8
      )
      .to(
        phoneSecond,
        0.6,
        {
          opacity: 1
        },
        0.8
      );
  }

  export function exitAnimation() {
    TweenMax.set(wrapperRef, { zIndex: -1, opacity: 0 });
    if (timeline) {
      timeline.pause();
    }
  }

  onMount(setFigureInitialPosition);
</script>

<div class="wrapper" bind:this="{wrapperRef}">
  <div class="inner-content">
    <span class="text">Переверніть, будь ласка, телефон вертикально для взаємодії з сайтом</span>
    <svg
      class="phones"
      width="200"
      height="200"
      viewBox="0 0 170 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g bind:this="{figureContainer}">
        <g bind:this="{phoneFirst}">
          <rect
            x="1.25"
            y="0.25"
            width="57.822"
            height="110.5"
            rx="9.75"
            fill="#FAFAFA"
            stroke="black"
            stroke-opacity="0.5"
          ></rect>
          <path
            d="M49.5204 107.238L10.8972 107.238C7.51097 107.238 4.7627 104.524 4.7627 101.18L4.7627
            9.82141C4.7627 6.47727 7.51097 3.76318 10.8972 3.76318L15.216 3.76318C15.7067 3.76318
            16.0993 4.10244 16.1975 4.5871C16.3938 5.45949 16.6883 6.28341 17.2281 6.96193C17.8661
            7.64045 18.6513 7.68892 19.6328 7.68892L40.9811 7.68892L42.2571 7.68892C43.533 7.20426
            43.9257 5.94415 44.0729 4.5871C44.122 4.10244 44.5146 3.76318 45.0053 3.76318L49.4222
            3.76318C52.8085 3.76318 55.5568 6.47727 55.5568 9.82141L55.5568 101.18C55.6549 104.524
            52.9066 107.238 49.5204 107.238Z"
            stroke="black"
            stroke-width="0.5"
            stroke-miterlimit="10"
          ></path>
        </g>
        <g bind:this="{phoneSecond}">
          <rect
            x="135.412"
            y="52.5898"
            width="57.822"
            height="110.5"
            rx="9.75"
            transform="rotate(90 135.412 52.5898)"
            fill="#FAFAFA"
            stroke="black"
            stroke-width="0.5"
          ></rect>
          <path
            d="M28.4248 100.86V62.2366C28.4248 58.8503 31.1389 56.1021 34.4831 56.1021L125.841
            56.1021C129.185 56.1021 131.899 58.8503 131.899 62.2366V66.5553C131.899 67.0461 131.56
            67.4387 131.075 67.5369C130.203 67.7332 129.379 68.0276 128.701 68.5675C128.022 69.2054
            127.974 69.9907 127.974 70.9722V92.3204V93.5964C128.458 94.8724 129.718 95.265 131.075
            95.4122C131.56 95.4613 131.899 95.8539 131.899 96.3447V100.762C131.899 104.148 129.185
            106.896 125.841 106.896L34.4831 106.896C31.1389 106.994 28.4248 104.246 28.4248 100.86Z"
            stroke="black"
            stroke-width="0.5"
            stroke-miterlimit="10"
          ></path>
        </g>
        <g bind:this="{phoneFirstWeighty}">
          <rect
            x="1.25"
            y="0.25"
            width="57.822"
            height="110.5"
            rx="9.75"
            fill="#FAFAFA"
            stroke="black"
            stroke-opacity="0.5"
          ></rect>
          <path
            d="M49.5204 107.238L10.8972 107.238C7.51097 107.238 4.7627 104.524 4.7627 101.18L4.7627
            9.82141C4.7627 6.47727 7.51097 3.76318 10.8972 3.76318L15.216 3.76318C15.7067 3.76318
            16.0993 4.10244 16.1975 4.5871C16.3938 5.45949 16.6883 6.28341 17.2281 6.96193C17.8661
            7.64045 18.6513 7.68892 19.6328 7.68892L40.9811 7.68892L42.2571 7.68892C43.533 7.20426
            43.9257 5.94415 44.0729 4.5871C44.122 4.10244 44.5146 3.76318 45.0053 3.76318L49.4222
            3.76318C52.8085 3.76318 55.5568 6.47727 55.5568 9.82141L55.5568 101.18C55.6549 104.524
            52.9066 107.238 49.5204 107.238Z"
            stroke="black"
            stroke-width="0.5"
            stroke-miterlimit="10"
          ></path>
        </g>
      </g>
    </svg>
  </div>
</div>
