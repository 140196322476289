<style lang="scss">
  .support {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    &-wrapper {
      margin-top: 80px;
      padding: 0 20px;
    }
    .arrow-back {
      position: absolute;
      left: 15px;
      top: 21px;
    }
  }
  .text-wrapper {
    font-size: calc(1.2vh + 1vw);
    line-height: calc(2vh + 2vw);
    letter-spacing: 0.05em;
    color: #1a1a1a;
    font-family: 'Transgender Grotesk';
    // max-width: 240px;
  }
  .links-wrapper {
    .links {
      font-size: calc(1.5vh + 1.2vw);
      line-height: 140%;
      letter-spacing: 0.05em;
      text-decoration-line: underline;
      color: #000000;
      margin-top: 19px;
      &:first-child {
        margin-top: 32px;
      }
    }
    a {
      display: block;
      text-decoration: none;
      color: #000;
    }
  }
</style>

<script>
  import gsap, { TweenMax, Back, TimelineLite } from 'gsap';
  import { createEventDispatcher, onMount } from 'svelte';
  import { respondH } from '../helpers/vh';
  import Button from './Button.svelte';
  import { getGtag } from '../tracking';
  import Menu from './HeaderMenu.svelte';
  import Footer from './Footer.svelte';

  const dispatch = createEventDispatcher();

  let wrapperRef;

  onMount(async () => {
    TweenMax.set(wrapperRef, { opacity: '0' });
  });

  export async function enterAnimation() {
    TweenMax.to(wrapperRef, 0.4, { opacity: '1' });
    gtag = await getGtag();
    gtag.page('/dont-support');
  }

  function goBack() {
    // return dispatch('back');
    history.back();
  }

  function goToMain() {
    dispatch('goToMain');
  }

  function goToAbout() {
    dispatch('goToAbout');
  }
  function goToRights() {
    dispatch('goToRights');
  }
  function goToMiths() {
    dispatch('goToMiths');
  }
  function goToCollection() {
    dispatch('goToCollection');
  }
  function goToMuseum() {
    dispatch('goToMuseum');
  }
</script>

<div bind:this="{wrapperRef}">
  <Menu
    on:goToMain="{goToMain}"
    on:goToAbout="{goToAbout}"
    on:goToRights="{goToRights}"
    on:goToMiths="{goToMiths}"
    on:goToCollection="{goToCollection}"
  />
  <div class="support">
    <div class="arrow-back" on:click="{goBack}">
      <img src="/images/arrow-back.svg" alt="arrow-back" />
    </div>
    <div class="support-wrapper">
      <div class="text-wrapper">
        <div class="text">
          Можливо, ти зміниш свою
          <br />
          думку, якщо дізнаєшся трохи більше
        </div>
      </div>
      <div class="links-wrapper">
        <div on:click="{goToRights}" class="links">Про права ЛГБТК+</div>
        <div on:click="{goToMiths}" class="links">Міфи про ЛГБТК+</div>
        <div on:click="{goToMuseum}" class="links">Музей прийняття</div>
      </div>
    </div>
    <Footer />
  </div>
</div>
