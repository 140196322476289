<style lang="scss">
  @import '../styles/main.scss';

  .wrapper {
    max-width: 1050px;
    margin: 0 auto;
    padding: 0 20px;
    .inner-content {
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      height: 100vh;
      width: 100%;
      justify-content: center;
      align-items: center;

      .logo {
        grid-column: span 10;
        width: 676px;
        height: 244px;
        margin: 0 auto 100px;

        img {
          display: block;
          margin: 0 auto;
          width: 100%;
          height: 100%;
        }
      }

      .content {
        grid-column: span 10;
        display: flex;
        flex-direction: column;
      }
    }
    .map-button {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin: 20px 0;
      width: 320px;
      height: 90px;
      border: 1px solid $black;
      border-radius: 83px;
      box-sizing: border-box;
      overflow: hidden;
      cursor: pointer;
      text-decoration: none;
      padding: 0px 10px;
      margin-left: 40px;
      &:first-child {
        margin-left: 0;
      }
      .map-button-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 40px;
        width: 96%;
        height: 80%;
        position: relative;
        border: 1px solid $black;
        font-family: 'Transgender Grotesk';
        justify-content: center;
        .map-button-text {
          font-size: 11px;
          line-height: 20px;
          letter-spacing: 0.25em;
          text-align: center;
          text-transform: uppercase;
          color: $black;
          align-items: center;
          white-space: nowrap;
          justify-content: center;
          text-align: center;
        }
      }
    }
  }
  .buttons-wrapper {
    display: flex;
  }
</style>

<script>
  import { initSocket } from '../helpers/websocket';
  import { onMount, createEventDispatcher } from 'svelte';
  import { getGtag } from '../tracking';
  import Footer from './Footer.svelte';

  let socket;
  let counter = '';
  let map = appUrl + `/map`;
  let about = appUrl + `/about`;
  let collection = appUrl + `/collection`;
  let gtag;

  const dispatch = createEventDispatcher();

  async function trackEventOnce(category, action) {
    if (typeof gtag === 'undefined') {
      gtag = await getGtag();
    }

    gtag.trackEventOnce(category, action);
  }
  async function trackEvent(category, action) {
    if (typeof gtag === 'undefined') {
      gtag = await getGtag();
    }

    gtag.trackEvent(category, action);
  }

  export async function enterAnimation() {
    gtag = await getGtag();
    gtag.page('/dontsupport/museum');
  }

  export async function connectToSocket() {
    socket = initSocket(onMessage);
    let gtag = await getGtag();
    gtag.page('/stub');
  }
  function goToMap() {
    trackEvent('home_desktop', 'watch_map');
    dispatch('goToMap');
  }
  function goToCollection() {
    trackEvent('home_desktop', 'about_collection');
    dispatch('goToCollection');
  }
  function goToAboutDesktop() {
    trackEvent('home_desktop', 'about_project');
    dispatch('goToAboutDesktop');
  }
  function goToDesktopForm() {
    trackEvent('home_desktop', 'to_form');
    dispatch('goToForm');
  }

  function onMessage(e) {
    if (e.type === 'counter') {
      counter = e.data;
    }
  }
</script>

<div class="wrapper">
  <div class="inner-content">
    <div class="content">
      <div class="logo">
        <img src="/images/big-logo.svg" alt="" />
      </div>
      <div class="buttons-wrapper">
        <div class="map-button" on:click="{goToCollection}">
          <div class="map-button-inner">
            <span class="map-button-text">Про колекцію рівності</span>
          </div>
        </div>
        <div class="map-button" on:click="{goToAboutDesktop}">
          <div class="map-button-inner">
            <span class="map-button-text">Про проєкт</span>
          </div>
        </div>
        <div class="map-button" on:click="{goToMap}">
          <div class="map-button-inner">
            <span class="map-button-text">
              поглянути на мапу
              <br />
              рівності {counter}
            </span>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
