import _ from 'lodash';

var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
var IW = window.innerWidth;
var IH = window.innerHeight;
export function vw(num) {
  return IW * (num / 100);
}
export function vh(num) {
  return IH * (num / 100);
}

var viewports = {
  xl: 896,
  l: 812,
  m: 736,
  s: 640,
  xs: 0
};

export function respondH(params, defaultValue) {
  const currentViewPort = _.entries(viewports).find(function([key, val]) {
    return (iOS ? screen.height : window.innerHeight) >= val;
  })[0];

  console.log('VIEWPORT ->', currentViewPort);
  return params[currentViewPort] || defaultValue;
}

export { iOS };
