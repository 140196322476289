<style lang="scss">
  .preload {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999999;
    background: white;
    &-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &-video {
      position: absolute;
      // opacity: 0;
      width: 100%;
      height: 100%;
      left: 0;
      // margin: 0 auto;
      // height: 100%;
      // background: white;
      top: 0;
      // left: 0;
      // bottom: 0;
      // right: 0;
      object-fit: cover;

      &.active {
        opacity: 1;
      }
    }
  }
</style>

<script>
  import gsap, { TweenMax, Back, TimelineLite } from 'gsap';
  import { createEventDispatcher, onMount, tick } from 'svelte';
  import { respondH } from '../helpers/vh';
  import { getGtag } from '../tracking';

  let gtag;
  let wrapperRef;
  let mounted = false;
  let loadImg;

  const dispatch = createEventDispatcher();

  onMount(() => {
    start();
  });

  function start() {
    setTimeout(() => {
      TweenMax.to(wrapperRef, 0.4, {
        opacity: '0',
        zIndex: '-1',
        onComplete: () => dispatch('completed')
      });
    }, 4000);
  }
  function hideImg() {
    TweenMax.to(loadImg, {
      opacity: '0',
      zIndex: '-1'
    });
  }
</script>

<div class="preload" bind:this="{wrapperRef}">
  <video
    on:playing="{hideImg}"
    playsinline
    autoplay
    muted
    src="/preload.mp4"
    class="preload-video"
  ></video>
  <img src="/zagluwka.jpg" class="preload-image" bind:this="{loadImg}" />
</div>
