<style>
  .map {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: initial;
  }
</style>

<script>
  import Map from './js/app.js';
  import { TweenMax } from 'gsap';
  import { onMount } from 'svelte';
  import { getGtag } from '../../tracking';

  let container;
  let map;
  let gtag;

  onMount(async() => {
    gtag = await getGtag();
  })

  export function setIndex() {
    TweenMax.set('.map', { zIndex: 2 });
  }

  export function showHistory(history) {
    if(!map) {
      return;
    }

    map.showHistory(history);
  }

  export function showLine(data, mock) {
    if (!map) {
      return;
    }
    map.showCityLine(data, mock);
  }

  export function init() {
    map = new Map(container);
  }

  export function setInitialPosition(data, history) {
    map.setStartPosition(data, 0.45, history);
  }

  export async function revealMap() {
    setIndex();
    map = new Map(container);
    map.setMapCenter();
    gtag = await getGtag();
    gtag.page('/map');
  }

  export function destroy() {
    if (!map) {
      return;
    }
    map.destroy();
    map = null;
  }

  export async function flyOut() {
    if (!map) {
      return;
    }

    await map.flyout();
  }

  export async function drawRay() {
    // if (!map) {
    //   return;
    // }
    gtag.page('/ukraine');
    return map.drawRay();
  }
</script>

<div class="map" bind:this="{container}"></div>
