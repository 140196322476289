<style lang="scss">
  @import '../styles/main.scss';
  @import '../styles/variables.scss';

  .popup {
    @include flex-column(center, center);
    padding: 27px 24px;
    position: fixed;
    left: 50%;
    top: 50%;
    width: 90vw;
    max-width: 380px;
    background: #fafafa;
    box-shadow: 0px -1px 10px rgba(182, 182, 182, 0.25);
    border-radius: 20px;

    &-title {
      text-align: center;
      font-size: 14px;
      margin-bottom: 27px;
      line-height: 23px;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #1a1a1a;
    }
    &-block {
      min-height: 100px;
    }
    &-text {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.05em;
      color: #1a1a1a;

      .number {
        grid-column: span 1;
        font-size: 34px;
        line-height: 41px;
        color: #fff;
        -webkit-text-stroke: 1px black;
        margin-right: 20px;
        width: 30px;
      }
      .text {
        grid-column: span 5;
        align-self: center;
      }
    }

    &-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(#3f3f3f, 0.8);
    }

    &-scroll-arrow {
      position: absolute;
      border-radius: 20px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      padding: 15px;
      top: -63px;
      right: 0;
      background: #fafafa;
    }

    &-header {
      &_text {
        text-align: center;
        max-width: 185px;
        font-size: 12px;
        line-height: 150.5%;
      }
    }

    &-body {
      min-width: 90%;
    }

    &-footer {
      margin-top: 20px;

      &_link {
        position: relative;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: $black;
        cursor: pointer;

        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          height: 1px;
          width: 100%;
          background: $black;
        }
      }
    }
  }
  .bold {
    font-weight: bold;
  }
</style>

<script>
  import { TweenLite, Linear, TimelineLite } from 'gsap';

  import Button from './Button.svelte';
  import { createEventDispatcher, onMount } from 'svelte';
  import { getGtag } from '../tracking';

  const dispatch = createEventDispatcher();

  let triggered;
  let popup;
  let popupOverlayRef;
  let opened = false;
  let arrowRef;
  let timelineArrow;
  export let voiceOnly;
  let gtag;

  async function trackEventOnce(category, action) {
    if (typeof gtag === 'undefined') {
      gtag = await getGtag();
    }

    gtag.trackEventOnce(category, action);
  }

  onMount(setFigureInitialPosition);

  export function animateArrow() {
    timelineArrow = new TimelineLite({
      onComplete() {
        timelineArrow.restart();
      }
    });

    setTimeout(() => {
      timelineArrow
        .to(arrowRef, 0.8, {
          y: '-12px',
          ease: Linear.easeInOut
        })
        .to(
          arrowRef,
          0.8,
          {
            y: '0',
            ease: Linear.easeInOut
          },
          0.4
        );
    }, 7000);
  }

  // export function openPopup() {
  //   setTimeout(() => {
  //     return enterAnimation();
  //   }, 15000);
  // }

  function stopArrowAnimation() {
    if (timelineArrow) {
      timelineArrow.restart();
      timelineArrow.pause();
      timelineArrow.kill();
    }
  }

  function onArrowClick() {
    if (!opened) {
      trackEventOnce('ukraine', 'arrow_map');
      dispatch('goToAbout');
      // return enterAnimation();
    }

    closeAnimation();
  }

  export function setFigureInitialPosition() {
    TweenLite.set(popupOverlayRef, { zIndex: -1, opacity: 0 });
    TweenLite.set(popup, {
      zIndex: -1,
      opacity: 0,
      translateY: '160%',
      translateX: '-50%'
    });
  }

  export function enterAnimation() {
    TweenLite.set(popup, { zIndex: 7 });
    TweenLite.set(popupOverlayRef, { zIndex: 3 });
    TweenLite.to(popup, 0.5, { opacity: 1, translateY: '-50%', translateX: '-50%' });
    TweenLite.to(arrowRef, 0.5, { rotate: '180%' });
    TweenLite.to(popupOverlayRef, 0.3, { opacity: 1 }, 0.3);
    opened = true;
    stopArrowAnimation();
  }

  function closeAnimation() {
    TweenLite.to(popup, 0.4, {
      translateY: '38vh',
      onComplete() {
        TweenLite.set(popupOverlayRef, { zIndex: -1 });
      }
    });
    TweenLite.to(arrowRef, 0.5, { rotate: '0%' });
    TweenLite.to(popupOverlayRef, 0.3, { opacity: 0 }, 0.3);
    opened = false;
  }

  export function enterScreen() {
    TweenLite.to(popup, 0.4, { opacity: 1, translateY: '35vh', zIndex: 4 });
  }

  function hidePopup() {
    TweenLite.set(popupOverlayRef, { opacity: 0, zIndex: -1 });
    TweenLite.set(popup, { opacity: 0, zIndex: -1 });
  }

  function goToAudio() {
    hidePopup();
    if (!triggered) {
      trackEventOnce('voice', 'goto_voice');
      dispatch('goToAudio');
    }

    triggered = true;
  }

  function goToForm() {
    // trackEventOnce('form', 'goto_form');
    dispatch('goToForm');
  }
</script>

<div bind:this="{popupOverlayRef}" class="popup-overlay"></div>
<div bind:this="{popup}" class="popup">
  {#if !voiceOnly}
    <div class="popup-scroll-arrow" on:click="{onArrowClick}">
      <svg
        width="47"
        height="42"
        viewBox="0 0 47 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        bind:this="{arrowRef}"
      >
        <g clip-path="url(#clip0)">
          <path
            d="M22.2322 1.13299C25.0904 1.13299 27.9657 2.40194 30.5523 4.95117L45.3869
            18.5867L42.5287 21.5835L23.9471 3.44429L24.2414 40.8387L20.8115 40.8613L20.5172
            3.44429L4.68076 19.7141L1.681 17.0232C1.681 17.0232 8.13897 11.0353 13.6517
            5.26275C16.2666 2.51524 19.2437 1.13299 22.2322 1.13299ZM22.2322 0C18.8758 0 15.627
            1.54653 12.831 4.47532C7.40318 10.1573 0.973507 16.1338 0.911247 16.1905L0
            17.0345L0.922567 17.8616L3.92233 20.5525L4.73169 21.2776L5.49012 20.5015L19.4079
            6.20313L19.6796 40.867L19.6909 42L20.8229 41.9943L24.2528 41.9717L25.3848 41.966L25.3734
            40.833L25.1018 6.15781L41.7363 22.3992L42.557 23.198L43.3493 22.3709L46.2076
            19.3742L47.0057 18.5414L46.1567 17.7596L31.3333 4.12975C28.5883 1.42757 25.4414 0
            22.2322 0Z"
            fill="#141414"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="47" height="42" fill="white"></rect>
          </clipPath>
        </defs>
      </svg>
    </div>
  {/if}
  <div class="popup-body">
    <div>
      <p class="popup-title">ПРО ПРОЄКТ</p>
    </div>
    <div class="popup-block"></div>
    <!--   <p class="popup-text">
        <span class="number">1</span>
        <span class="text">Заповнюй форму</span>
      </p>
      <p class="popup-text">
        <span class="number">2</span>
        <span class="text">
          Чекай – щосуботи рандом обиратиме 5 людей, яким ми відправлятимо речі
        </span>
      </p>
      <p class="popup-text">
        <span class="number">3</span>
        <span class="text">
          Коли отримаєш — публікуй в Insta фото з хештегом
          <span class="bold">#різнірівніколаба</span>
        </span>
      </p>
      <p class="popup-text">
        <span class="number">4</span>
        <span class="text">Відправляй річ із колекції назад, щоб ми передали ЇЇ далі</span>

      </p>
    </div> -->
    <!-- <Button width="200" text="заповнити форму" on:click="{goToForm}" /> -->
  </div>
</div>
