<style lang="scss">
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(#3f3f3f, 0.8);

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .arrow {
    position: absolute;
    top: 0;
  }
</style>

<script>
  import { TimelineLite, TweenLite } from 'gsap';
  import { onMount } from 'svelte';

  let phoneRef;
  let overlayRef;
  let timeline;

  onMount(setInitialPosition);

  function setInitialPosition() {
    TweenLite.set(overlayRef, { zIndex: -1, opacity: 0 });
  }

  export function exitAnimation() {
    if (timeline) {
      timeline.pause();
    }
    timeline = null;
    setInitialPosition();
  }

  export function enterAnimation() {
    return new Promise(resolve => {
      TweenLite.set(overlayRef, { zIndex: 1 });
      TweenLite.to(overlayRef, 0.3, {
        opacity: 1,
        onComplete() {
          timeline = new TimelineLite({
            onComplete() {
              setInitialPosition();
              resolve();
            }
          })
            .repeat(0)
            .to(phoneRef, 1, { rotate: '90deg' }, 0.2)
            .to(phoneRef, 0.3, { rotate: '0' }, 1.2);
        }
      });
    });
  }
</script>

<div class="overlay" bind:this="{overlayRef}">
  <svg
    bind:this="{phoneRef}"
    class="phone"
    width="59"
    height="111"
    viewBox="0 0 59 111"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.25"
      y="0.25"
      width="57.822"
      height="110.5"
      rx="9.75"
      fill="#232323"
      fill-opacity="0.7"
      stroke="white"
      stroke-width="0.5"
    ></rect>
    <path
      d="M48.5201 107.238L9.897 107.238C6.51073 107.238 3.76245 104.524 3.76245 101.179L3.76245
      9.82129C3.76245 6.47715 6.51073 3.76306 9.897 3.76306L14.2157 3.76306C14.7065 3.76306 15.0991
      4.10232 15.1972 4.58698C15.3936 5.45937 15.688 6.28329 16.2279 6.96181C16.8658 7.64033 17.6511
      7.6888 18.6326 7.6888L39.9808 7.6888L41.2568 7.6888C42.5328 7.20414 42.9254 5.94402 43.0726
      4.58698C43.1217 4.10232 43.5143 3.76306 44.0051 3.76306L48.422 3.76306C51.8082 3.76306 54.5565
      6.47715 54.5565 9.82129L54.5565 101.179C54.6547 104.524 51.9064 107.238 48.5201 107.238Z"
      stroke="white"
      stroke-width="0.5"
      stroke-miterlimit="10"
    ></path>
  </svg>

</div>
