<style lang="scss">
  .wrapper {
    .copy-textarea {
      position: absolute;
      z-index: -100;
      opacity: 0;
    }

    .intro-share-links {
      display: flex;
      max-height: 100px;

      @media (orientation: portrait) {
        justify-content: space-around;
      }

      &-link {
        margin-right: 24px;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .copy-success-text {
      font-size: 12px;
      color: #fcaace;

      @media (orientation: portrait) {
        text-align: center;
      }
    }
  }
</style>

<script>
  import { TweenMax, TimelineLite } from 'gsap';
  import { getGtag } from '../tracking';

  async function trackEventOnce(category, action) {
    if (typeof gtag === 'undefined') {
      gtag = await getGtag();
    }

    gtag.trackEventOnce(category, action);
  }

  let textAreaRef;
  let copySuccessTextRef;
  let animating;

  function copyLink() {
    if (animating) {
      return;
    }
    trackEventOnce('share', 'copylink');
    try {
      textAreaRef.focus();
      textAreaRef.select();
      document.execCommand('copy');
      copySuccessAnimation();
      textAreaRef.blur();
      window.getSelection().empty();
    } catch (e) {
      console.log(e);
      alert('Ваш пристрій не підтримує копіювання посилання.');
    }
  }

  function copySuccessAnimation() {
    animating = true;
    console.log('here?');
    new TimelineLite({ onComplete: () => (animating = false) })
      .to(copySuccessTextRef, { duration: 0.3, opacity: 1 })
      .to(copySuccessTextRef, { duration: 0.3, opacity: 0 }, 3);
  }

  export async function assignSupportLgbt() {
    textAreaRef.value = document.location.href;
    TweenMax.set(copySuccessTextRef, { opacity: 0 });
  }
</script>

<section class="wrapper">
  <div class="intro-share-links">
    <a
      class="intro-share-links-link"
      href="https://www.facebook.com/sharer/sharer.php?u={appUrl}/"
      on:click="{() => trackEventOnce('share', 'facebook')}"
      target="_blank"
    >
      <svg
        width="105"
        height="102"
        viewBox="0 0 105 102"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.33497 74.9689C1.33497 74.9689 19.4501 84.8934 29.3685 79.1435C44.4754 70.4023
          42.5285 57.0638 59.9457 61.7148C63.3716 62.6454 66.9252 64.1921 69.9192 64.9811C81.4914
          67.9094 93.14 59.2781 96.8773 47.8664C101.096 34.985 93.0873 23.4752 79.9949
          18.215C74.9532 16.1894 69.2872 15.5831 64.8899 15.8661C46.2639 17.3507 37.298 28.6067
          29.2498 28.9384C21.2015 29.2701 17.4054 25.3255 17.4054 25.3255L1.33497 74.9689Z"
          fill="#94B6C5"
        ></path>
        <path
          d="M68.9957 19.0718H21.2816C19.4848 19.0718 18.0283 20.5283 18.0283 22.325V70.0391C18.0283
          71.8359 19.4848 73.2924 21.2816 73.2924H46.223V55.9418H39.7166V47.2665H46.223V40.76C46.223
          34.771 51.0781 29.9159 57.0672 29.9159H63.5736V38.5912H59.236C56.8404 38.5912 54.8983
          40.5331 54.8983 42.9288V47.2665H63.5736L62.4892 55.9418H54.8983V73.2924H68.9957C70.7924
          73.2924 72.2489 71.8359 72.2489 70.0391V22.325C72.2489 20.5283 70.7924 19.0718 68.9957
          19.0718Z"
          stroke="black"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </svg>
    </a>
    <a
      class="intro-share-links-link"
      href="https://twitter.com/intent/tweet?text=Послухай мою власну музичну колаборацію з
      українськими зірками {appUrl}/"
      target="_blank"
      on:click="{() => trackEventOnce('share', 'twitter')}"
    >
      <svg
        width="84"
        height="88"
        viewBox="0 0 84 88"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M65.1398 62.1122C57.5549 76.1682 39.8783 81.3277 25.7593 73.7088C11.6404 66.0899
          6.34867 48.5363 13.9336 34.4803C21.5185 20.4243 57.8083 14.3172 57.8083 14.3172C57.8083
          14.3172 72.7247 48.0562 65.1398 62.1122Z"
          fill="#FEB903"
        ></path>
        <path
          d="M16.4427 24.8484C21.7083 31.2675 31.1358 37.3245 43.7888 37.9757C43.0909 35.1884
          43.0237 27.6232 48.5405 24.4791C51.008 23.0727 53.3438 22.2242 56.0955 22.2242C59.5529
          22.2242 62.3907 23.3621 65.4092 26.1302C67.8662 25.7868 72.4559 23.8932 73.5448
          23.1493C72.8358 25.7594 69.9992 29.1108 67.9499 29.9066C69.8317 29.901 73.9691 28.8221
          75.2367 28.1845C73.4779 30.8981 70.4066 33.4976 68.7261 34.5765C70.672 51.2987 55.147
          72.0863 32.8504 72.1072C26.483 72.1131 19.5635 70.8153 12.3408 66.8557C19.4658 67.6568
          27.0998 65.4411 31.483 61.6048C25.9551 61.4849 20.3562 57.9085 17.81 52.4157C20.5797
          53.2438 23.1333 53.6634 24.6465 52.4157C22.0669 52.1378 13.7081 47.7428 13.7081
          39.231C15.629 40.7568 18.3947 42.3335 20.5446 41.9139C17.0267 39.914 11.9142 32.2975
          16.4427 24.8484Z"
          stroke="black"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </svg>
    </a>
    <span class="intro-share-links-link" on:click="{copyLink}">
      <svg
        width="84"
        height="71"
        viewBox="0 0 84 71"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.7931 70.8705L17.1856 37.0622C17.3813 25.2614 23.4219 15.5431 32.6871
          12.2428C41.4838 9.10943 51.684 12.2215 59.2261 20.3864L83.5125 46.5771L16.7931 70.8705Z"
          fill="#FCAACE"
        ></path>
        <path
          d="M36.0236 42.9664C37.0559 44.3862 38.3729 45.561 39.8853 46.4111C41.3977 47.2612 43.0701
          47.7667 44.7891 47.8934C46.508 48.02 48.2334 47.7649 49.8481 47.1452C51.4628 46.5255
          52.9291 45.5558 54.1475 44.3018L61.3586 36.883C63.5479 34.5509 64.7593 31.4276 64.7319
          28.1856C64.7045 24.9436 63.4405 21.8423 61.2122 19.5498C58.9839 17.2573 55.9695 15.9569
          52.8183 15.9287C49.6671 15.9005 46.6312 17.1468 44.3644 19.3992L40.2301 23.6279M45.6384
          38.0205C44.6061 36.6007 43.2891 35.4259 41.7767 34.5758C40.2643 33.7257 38.5919 33.2202
          36.873 33.0936C35.154 32.9669 33.4286 33.2221 31.8139 33.8418C30.1992 34.4615 28.7329
          35.4312 27.5145 36.6851L20.3034 44.104C18.1142 46.436 16.9028 49.5594 16.9301
          52.8014C16.9575 56.0434 18.2215 59.1446 20.4498 61.4372C22.6781 63.7297 25.6925 65.0301
          28.8437 65.0583C31.995 65.0864 35.0309 63.8401 37.2976 61.5878L41.4079 57.359"
          stroke="black"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </svg>
    </span>
  </div>
  <textarea class="copy-textarea" bind:this="{textAreaRef}"></textarea>
  <p class="copy-success-text" bind:this="{copySuccessTextRef}">посилання скопійоване</p>
</section>
