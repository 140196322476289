/* eslint-disable */
/**
 * @author WestLangley / http://github.com/WestLangley
 *
 */

import {
  Box3,
  Float32BufferAttribute,
  InstancedBufferGeometry,
  InstancedInterleavedBuffer,
  InterleavedBufferAttribute,
  Sphere,
  Vector3,
  WireframeGeometry
} from 'three';

const LineSegmentsGeometry = function() {
  InstancedBufferGeometry.call(this);

  this.type = 'LineSegmentsGeometry';

  const positions = [-1, 2, 0, 1, 2, 0, -1, 1, 0, 1, 1, 0, -1, 0, 0, 1, 0, 0, -1, -1, 0, 1, -1, 0];
  const uvs = [-1, 2, 1, 2, -1, 1, 1, 1, -1, -1, 1, -1, -1, -2, 1, -2];
  const index = [0, 2, 1, 2, 3, 1, 2, 4, 3, 4, 5, 3, 4, 6, 5, 6, 7, 5];

  this.setIndex(index);
  this.setAttribute('position', new Float32BufferAttribute(positions, 3));
  this.setAttribute('uv', new Float32BufferAttribute(uvs, 2));
};

LineSegmentsGeometry.prototype = Object.assign(Object.create(InstancedBufferGeometry.prototype), {
  constructor: LineSegmentsGeometry,

  isLineSegmentsGeometry: true,

  applyMatrix(matrix) {
    const start = this.attributes.instanceStart;
    const end = this.attributes.instanceEnd;

    if (start !== undefined) {
      start.applyMatrix4(matrix);

      end.applyMatrix4(matrix);

      start.data.needsUpdate = true;
    }

    if (this.boundingBox !== null) {
      this.computeBoundingBox();
    }

    if (this.boundingSphere !== null) {
      this.computeBoundingSphere();
    }

    return this;
  },

  setPositions(array) {
    let lineSegments;

    if (array instanceof Float32Array) {
      lineSegments = array;
    } else if (Array.isArray(array)) {
      lineSegments = new Float32Array(array);
    }

    const instanceBuffer = new InstancedInterleavedBuffer(lineSegments, 6, 1); // xyz, xyz

    this.setAttribute('instanceStart', new InterleavedBufferAttribute(instanceBuffer, 3, 0)); // xyz
    this.setAttribute('instanceEnd', new InterleavedBufferAttribute(instanceBuffer, 3, 3)); // xyz

    //

    this.computeBoundingBox();
    this.computeBoundingSphere();

    return this;
  },

  setColors(array) {
    let colors;

    if (array instanceof Float32Array) {
      colors = array;
    } else if (Array.isArray(array)) {
      colors = new Float32Array(array);
    }

    const instanceColorBuffer = new InstancedInterleavedBuffer(colors, 6, 1); // rgb, rgb

    this.setAttribute(
      'instanceColorStart',
      new InterleavedBufferAttribute(instanceColorBuffer, 3, 0)
    ); // rgb
    this.setAttribute(
      'instanceColorEnd',
      new InterleavedBufferAttribute(instanceColorBuffer, 3, 3)
    ); // rgb

    return this;
  },

  fromWireframeGeometry(geometry) {
    this.setPositions(geometry.attributes.position.array);

    return this;
  },

  fromEdgesGeometry(geometry) {
    this.setPositions(geometry.attributes.position.array);

    return this;
  },

  fromMesh(mesh) {
    this.fromWireframeGeometry(new WireframeGeometry(mesh.geometry));

    // set colors, maybe

    return this;
  },

  fromLineSegements(lineSegments) {
    const { geometry } = lineSegments;

    if (geometry.isGeometry) {
      this.setPositions(geometry.vertices);
    } else if (geometry.isBufferGeometry) {
      this.setPositions(geometry.position.array); // assumes non-indexed
    }

    // set colors, maybe

    return this;
  },

  computeBoundingBox: (function() {
    const box = new Box3();

    return function computeBoundingBox() {
      if (this.boundingBox === null) {
        this.boundingBox = new Box3();
      }

      const start = this.attributes.instanceStart;
      const end = this.attributes.instanceEnd;

      if (start !== undefined && end !== undefined) {
        this.boundingBox.setFromBufferAttribute(start);

        box.setFromBufferAttribute(end);

        this.boundingBox.union(box);
      }
    };
  })(),

  computeBoundingSphere: (function() {
    const vector = new Vector3();

    return function computeBoundingSphere() {
      if (this.boundingSphere === null) {
        this.boundingSphere = new Sphere();
      }

      if (this.boundingBox === null) {
        this.computeBoundingBox();
      }

      const start = this.attributes.instanceStart;
      const end = this.attributes.instanceEnd;

      if (start !== undefined && end !== undefined) {
        const { center } = this.boundingSphere;

        this.boundingBox.getCenter(center);

        let maxRadiusSq = 0;

        for (let i = 0, il = start.count; i < il; i++) {
          vector.fromBufferAttribute(start, i);
          maxRadiusSq = Math.max(maxRadiusSq, center.distanceToSquared(vector));

          vector.fromBufferAttribute(end, i);
          maxRadiusSq = Math.max(maxRadiusSq, center.distanceToSquared(vector));
        }

        this.boundingSphere.radius = Math.sqrt(maxRadiusSq);

        if (isNaN(this.boundingSphere.radius)) {
          console.error(
            'THREE.LineSegmentsGeometry.computeBoundingSphere(): Computed radius is NaN. The instanced position data is likely to have NaN values.',
            this
          );
        }
      }
    };
  })(),

  toJSON() {
    // todo
  }
});

export { LineSegmentsGeometry };
