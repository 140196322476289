// canvas to draw
import FontFaceObserver from 'fontfaceobserver';
class GetText {
  constructor() {
    this.color = '#000';
    this.fontface = 'Transgender Grotesk';
    this.element = document.createElement('canvas');
    this.element.width = 1024;
    this.element.height = 1024;
    this.text = 'Київ';
    this.element.classList.add('canvas');
    this.ctx = this.element.getContext('2d');
    this.myFont = new FontFaceObserver(this.fontface);
  }

  draw() {
    return this.myFont.load().then(font => {
      this.font = '40px ' + this.fontface;
      this.ctx.clearRect(0, 0, this.element.width, this.element.height);
      this.ctx.font = this.font;
      this.ctx.fillStyle = this.color;
      this.ctx.textAlign = 'center';
      this.ctx.textBaseline = 'middle';

      this.size = 600;
      this.ctx.fillText(this.text, this.element.width / 2, this.element.height - this.size * 0.7);
    });
  }
  update(newtext) {
    this.text = newtext;
    this.draw();
  }
  fitText() {
    var size = this.measureTextBinaryMethod(this.text, this.fontface, 0, 600, this.element.width);
    return size;
  }

  measureTextBinaryMethod(text, fontface, min, max, desiredWidth) {
    if (max - min < 1) {
      return min;
    }
    var test = min + (max - min) / 2; //Find half interval
    this.ctx.font = test + 'px ' + this.fontface;
    this.measureTest = this.ctx.measureText(text).width;
    if (this.measureTest > desiredWidth) {
      var found = this.measureTextBinaryMethod(this.text, this.fontface, min, test, desiredWidth);
    } else {
      var found = this.measureTextBinaryMethod(this.text, this.fontface, test, max, desiredWidth);
    }
    return found;
  }
}

export default GetText;
