let gtag;
const trackingCache = {};
let gtagHelper;

function loadGtag() {
  return new Promise(resolve => {
    if (typeof gtag !== 'undefined') {
      return resolve();
    }

    const addGtag = () => {
      window.dataLayer = window.dataLayer || [];
      gtag = () => window.dataLayer.push(arguments);
      gtag('js', new Date());
      gtag('config', TRACKING_ID);
      gtagHelper = getGtagHelper();
      resolve();
    };
    // Adding the script tag to the head as suggested before
    const head = document.head;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://www.googletagmanager.com/gtag/js?id=${TRACKING_ID}`;

    // Then bind the event to the callback function.
    // There are several events for cross browser compatibility.
    script.onreadystatechange = addGtag;
    script.onload = addGtag;

    // Fire the loading
    head.appendChild(script);
  });
}

const getGtagHelper = () => {
  const page = page_path => {
    if (PRODUCTION == 'true') {
      gtag2('config', TRACKING_ID, {
        page_path,
        transport_type: 'beacon'
      });
    } else {
      console.log('trackPage -> ', page_path);
    }
  };

  const trackEvent = (event_category, event_action, event_label, event_value) => {
    if (!event_category) event_category = 'common';

    if (PRODUCTION == 'true') {
      gtag2('event', event_action, {
        event_category,
        event_label,
        event_value
      });
    } else {
      console.log('trackEvent -> ', event_action, {
        event_category,
        event_label,
        event_value
      });
    }
  };

  const trackEventOnce = (event_category, event_action, event_label, event_value) => {
    const key = `${event_category}:${event_action}`;
    if (trackingCache.hasOwnProperty(key)) {
      return;
    }

    trackEvent(event_category, event_action, event_label, event_value);
    trackingCache[key] = true;
  };

  return {
    page,
    trackEvent,
    trackEventOnce
  };
};

export async function getGtag() {
  if (typeof gtag !== 'undefined') {
    return gtagHelper;
  }
  await loadGtag();
  return gtagHelper;
}
