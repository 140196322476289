<style lang="scss">
  @import '../styles/variables.scss';

  .success-svg {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .submit-btn {
    border: none;
    background-color: white;
  }

  .wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ededed;

    .inner-content {
      position: relative;
      @media (orientation: portrait) {
        flex-direction: column;
        justify-content: space-around;
      }

      @include flex(space-between, center);
      flex-wrap: nowrap;
      height: 100%;
      padding: 62px 24px 25px 29px;

      .text-wrapper {
        margin-bottom: 60px;
        color: #000;

        .title {
          margin: 0;
          max-width: 230px;
          font-size: 20px;
          line-height: 24px;
          @media (min-width: 720px) and (orientation: landscape) {
            font-size: 26px;
            max-width: 292px;
          }
        }

        .text {
          display: inline-block;
          max-width: 210px;
          margin-top: 20px;
          font-size: 10px;
          line-height: 150.5%;
          @media (min-width: 720px) and (orientation: landscape) {
            font-size: 14px;
          }
        }
      }

      .form-wrapper {
        @include flex-column(center);
        position: relative;
        margin-left: 16px;
        width: 45%;

        @media (orientation: portrait) {
          flex-direction: column;
          justify-content: space-around;
          width: 100%;
          flex-grow: 1;
        }

        .email-label {
          @include flex-column;

          .label {
            margin-left: 10px;
            line-height: 12px;
            font-size: 10px;
            @media (min-width: 720px) and (orientation: landscape) {
              font-size: 14px;
            }
          }
        }

        .input-wrapper {
          margin-top: 8px;
          position: relative;
          background: #fff;
          border-radius: 8px;
          @include flex(null, center);

          .arrow {
            width: 30px;
            height: 26px;
            transform: rotate(90deg);
            cursor: pointer;
            margin-right: 12px;
          }

          .disable-arrow-btn {
            // visibility: hidden;
          }

          .email {
            padding-left: 12px;
            height: 56px;
            flex-grow: 1;
            outline: none;
            border: none;
            background: transparent;
            font-size: 10px;
            color: #050505;
            line-height: 12px;
            font-size: 16px;
          }
        }

        .checkbox-wrapper {
          @include flex(null, center);
          margin-top: 12px;

          .checkbox-input {
            display: none;
          }

          .label {
            position: relative;
            font-size: 6px;
            line-height: 150.5%;
            color: #000;
            cursor: pointer;
            @media (min-width: 720px) and (orientation: landscape) {
              font-size: 8px;
            }
            &:before {
              content: '';
              background: #fff;
              display: inline-block;
              border-radius: 2px;
              width: 13px;
              height: 13px;
              margin-right: 10px;
              vertical-align: middle;
            }
          }

          .checkbox-input:checked + .label:before {
            background: lightblue;
            box-shadow: inset 0 0 0 3px #fff;
          }
        }

        .skip {
          align-self: center;
          color: #737272;
          font-size: 10px;
          line-height: 12px;
          margin-top: 28px;
          border: none;
          background: none;
          outline: none;
          cursor: pointer;
          @media (min-width: 720px) and (orientation: landscape) {
            font-size: 12px;
          }
        }
      }
    }
  }
</style>

<script>
  import gsap, { TweenMax, TimelineMax } from 'gsap';
  import { createEventDispatcher, onMount } from 'svelte';
  import DrawSVGPlugin from '../vendor/gsap/DrawSVGPlugin';
  import { getGtag } from '../tracking';

  gsap.registerPlugin(DrawSVGPlugin);

  const dispatch = createEventDispatcher();
  let inputValue;
  let wrapper;
  let checkboxValue = true;
  let isValidEmail = false;
  let circleRef;
  let testRef;
  let secondRef;
  let lineRef;
  let innerContentRef;
  let successSvgRef;
  let gtag;

  function setFigureInitialPosition() {}

  function animateFigure(timeline) {}

  export function enterAnimation() {}

  export function exitAnimation() {}

  function skipSubmit() {
    trackEventOnce('record', 'email_skip');
    dispatch('skip-submit');
  }

  function sendEmail(email) {
    if (!isValidEmail) {
      return;
    }
    dispatch('form-submitted', email);
  }

  function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    isValidEmail = re.test(String(email).toLowerCase());
  }

  export function init() {
    TweenMax.set(wrapper, { zIndex: 0 });
    TweenMax.set(successSvgRef, { opacity: 0 });
    TweenMax.to(wrapper, 0.3, { opacity: 1 });
  }

  function handleSubmit() {
    if (!isValidEmail || !checkboxValue) {
      trackEventOnce('record', 'email_send_error');
      return;
    }
    sendEmail(inputValue);
    successAnimation();
  }

  async function trackEventOnce(category, action) {
    if (typeof gtag === 'undefined') {
      gtag = await getGtag();
    }

    gtag.trackEventOnce(category, action);
  }

  function successAnimation() {
    trackEventOnce('record', 'email_send_success');
    const tl = new TimelineMax({
      onComplete: () => {
        TweenMax.to(wrapper, 0.4, {
          delay: 1,
          opacity: 0,
          onComplete: () => {
            TweenMax.set(wrapper, { zIndex: -1 });
            dispatch('done');
          }
        });
      }
    });

    TweenMax.set(circleRef, { rotate: 30, transformOrigin: 'center center' });

    tl.to(innerContentRef, 0.3, {
      opacity: 0,
      onComplete: () => {
        TweenMax.set(innerContentRef, { zIndex: -1 });
      }
    })
      .to(successSvgRef, 0.2, { opacity: 1 })
      .fromTo(circleRef, 0.6, { drawSVG: '0%' }, { drawSVG: '100%' })
      .fromTo(lineRef, 0.3, { drawSVG: '0%' }, { drawSVG: '100%' })
      .to(lineRef, 0.3, { morphSVG: testRef })
      .fromTo(secondRef, 0.3, { drawSVG: '0%' }, { drawSVG: '100%' }, 1.4);
  }

  onMount(() => {
    TweenMax.set(successSvgRef, { opacity: 0 });
    TweenMax.set(wrapper, { opacity: 0, zIndex: -1 });
  });
</script>

<div class="wrapper" bind:this="{wrapper}">
  <div class="success-svg" bind:this="{successSvgRef}">
    <svg
      fill="none"
      height="158"
      viewBox="0 0 158 158"
      width="158"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="#000">
        <path
          d="M0.5,79a78.5,78.5 0 1,0 157,0a78.5,78.5 0 1,0 -157,0"
          bind:this="{circleRef}"
        ></path>
        <path d="m10.3536 40.6464 59 59" bind:this="{lineRef}"></path>
        <path class="hidden" d="m50.3536 80.6464 19 19" bind:this="{testRef}"></path>
        <path d="m69.2668 100.276 42.0082-42.0078" bind:this="{secondRef}"></path>
      </g>
    </svg>
  </div>
  <div class="inner-content" bind:this="{innerContentRef}">
    <div class="text-wrapper">
      <div>
        <h3 class="title">
          Дякуємо за
          <br />
          твій внесок!
        </h3>
        <span class="text">
          Після обробки запису, ми надішлемо тобі відео, але для цього залиш свій e-mail
        </span>
      </div>
    </div>
    <div class="form-wrapper">
      <form on:submit|preventDefault="{handleSubmit}">
        <label for="email" class="email-label">
          <span class="label">мій e-mail</span>
        </label>
        <div class="input-wrapper">
          <input
            id="email"
            type="email"
            class="email"
            on:focus="{() => trackEventOnce('record', 'email_focus')}"
            bind:value="{inputValue}"
            on:input="{validateEmail(inputValue)}"
          />
          <button class="submit-btn" type="submit">
            <svg
              class="arrow {isValidEmail && checkboxValue ? '' : 'disable-arrow-btn'}"
              width="47"
              height="42"
              viewBox="0 0 47 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <path
                  d="M22.2322 1.13299C25.0904 1.13299 27.9657 2.40194 30.5523 4.95117L45.3869
                  18.5867L42.5287 21.5835L23.9471 3.44429L24.2414 40.8387L20.8115 40.8613L20.5172
                  3.44429L4.68076 19.7141L1.681 17.0232C1.681 17.0232 8.13897 11.0353 13.6517
                  5.26275C16.2666 2.51524 19.2437 1.13299 22.2322 1.13299ZM22.2322 0C18.8758 0
                  15.627 1.54653 12.831 4.47532C7.40318 10.1573 0.973507 16.1338 0.911247 16.1905L0
                  17.0345L0.922567 17.8616L3.92233 20.5525L4.73169 21.2776L5.49012 20.5015L19.4079
                  6.20313L19.6796 40.867L19.6909 42L20.8229 41.9943L24.2528 41.9717L25.3848
                  41.966L25.3734 40.833L25.1018 6.15781L41.7363 22.3992L42.557 23.198L43.3493
                  22.3709L46.2076 19.3742L47.0057 18.5414L46.1567 17.7596L31.3333 4.12975C28.5883
                  1.42757 25.4414 0 22.2322 0Z"
                  fill="#141414"
                ></path>
              </g>
            </svg>
          </button>
        </div>
        <div class="checkbox-wrapper">
          <input
            bind:checked="{checkboxValue}"
            id="checkbox"
            type="checkbox"
            class="checkbox-input"
          />
          <label for="checkbox" class="label">Даю згоду на обробку моїх персональних даних</label>
        </div>
      </form>
      <button on:click="{skipSubmit}" class="skip">пропустити</button>
    </div>
  </div>
</div>
