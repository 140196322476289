<script>
  import AudioRecorder from './components/AudioRecorder/AudioRecorder.svelte';
  import AboutCollection from './components/AboutCollection.svelte';
  import DesktopNavigation from './components/DesktopNavigation.svelte';
  import FeedbackForm from './components/FeedbackForm.svelte';
  import Slider from './components/Slider.svelte';
  import SecondWave from './components/SecondWave.svelte';
  import About from './components/Final/About.svelte';
  import AboutDesktop from './components/AboutDesktop.svelte';
  import FormDesktop from './components/FormDesktop.svelte';
  import WaitlistDesktop from './components/WaitlistDesktop.svelte';
  import Final from './components/Final/Final.svelte';
  import Myths from './components/Final/Myths.svelte';
  import Museum from './components/Final/Museum.svelte';
  import Rights from './components/Final/Rights.svelte';
  import DontSupport from './components/DontSupport.svelte';
  import Timeline from './components/TimeLine.svelte';
  import Sharing from './components/Sharing.svelte';
  import FourthStep from './components/FourthStep.svelte';
  import ThirdStep from './components/ThirdStep.svelte';
  import Preload from './components/Preload.svelte';
  import Form from './components/Form.svelte';
  import Collection from './components/Collection.svelte';
  import { getSocket } from './helpers/websocket';
  import { onMount, tick } from 'svelte';
  import { getGtag } from './tracking';
  import gsap, { TweenMax, Back, TimelineLite } from 'gsap';
  import OrientationAlert from './components/OrientationAlert.svelte';

  let audioRecorder;
  let feedbackForm;
  let recordedAudio;
  let socket;
  let finalRef;
  let videoTimeline;
  let mapRef;
  let audioOnly;
  let isMobile;
  let fourthStepRef;
  let videoId;
  let hasSelf;
  let mapOnly;
  let mapStep;
  let voiceOnly;
  let gtag;
  let demoMapOnly;

  let secondWave;
  let about;
  let myths;
  let rights;
  let dontSupport;
  let thirdStep;
  let form;
  let collection;
  let museum;
  let desktopNavigation;
  let desktopAbout;
  let desktopCollection;

  let mode;

  let preloadRef;
  let timeLinePreload;
  // let isPreloadShowed = false;

  let mobileAlert;
  let restart = false;
  let orientationAlertRef;
  let orientation = 'vertical';
  let enableOrientation = true;

  export let successSubmit = false;

  async function trackEventOnce(category, action) {
    if (typeof gtag === 'undefined') {
      gtag = await getGtag();
    }

    gtag.trackEventOnce(category, action);
  }

  async function restartFlow() {
    trackEventOnce('share', 'restart');
    window.location.href = appUrl;
  }
  //
  function onRecorded(event) {
    if (event && event.detail) {
      recordedAudio = event.detail;
    }
    feedbackForm.init();
  }

  async function checkMobileDevice() {
    isMobile = !window.matchMedia('(min-width: 1024px)').matches;

    if (!isMobile && mode === 'index') {
      await tick();
      desktopNavigation.connectToSocket();
    }
  }

  function sendMessage(event) {
    socket = getSocket();

    if (!socket) {
      return;
    }

    socket.send({ action: 'record', email: event.detail, audio: recordedAudio });
  }

  function onWatch() {
    audioOnly = false;
    videoTimeline.enterAnimation();
  }

  function onRecordSound() {
    audioRecorder.init();
  }

  function notAllowed() {
    if (!audioOnly) {
      return onVideoEnd();
    }
    backToVideo();
  }

  function initMap() {
    mapStep.enterAnimation();
  }

  function onVideoEnd() {
    finalRef.enterAnimation();
    restartIntro();
  }

  function goToAudio() {
    // restartFlow();
    audioOnly = true;
    audioRecorder.init();
  }

  function backToVideo() {
    videoTimeline.enterAnimation();
  }

  function restartIntro() {
    finalRef.restartIntro();
  }

  function cancelRecordAudio() {
    if (!audioOnly) {
      restartIntro();
      return onVideoEnd();
    }
    backToVideo();
  }

  // function initVoice() {}
  //
  function done() {
    if (!audioOnly) {
      return onVideoEnd();
    }

    backToVideo();
  }

  function detectDeviceOrientation() {
    window.addEventListener(
      'resize',
      function() {
        const mql = window.matchMedia('(orientation: portrait)');
        if (mql.matches) {
          orientation = 'vertical';
          orientationAlertRef.exitAnimation();
        } else {
          orientation = 'horizontal';
          orientationAlertRef.enterAnimation();
          activeStep.setFigureInitialPosition();
        }
      },
      false
    );
  }
  function disableOrientation() {
    enableOrientation = false;
  }

  async function goToDontSupport() {
    mode = 'dontSupport';
    await tick();
    history.pushState(null, null, 'dont-support');
    return dontSupport.enterAnimation();
  }

  async function goToMain() {
    mode = 'index';
    await tick();
    history.pushState(null, null, '/');
    return checkMobileDevice();
  }

  async function goToAbout() {
    mode = 'about';
    await tick();
    history.pushState(null, null, 'about-us');
    checkMobileDevice();
    return about.enterAnimation();
  }
  async function goToRights() {
    mode = 'rights';
    await tick();
    history.pushState(null, null, 'lgbtq-rights');
    return rights.enterAnimation();
  }
  async function goToMiths() {
    mode = 'myths';
    await tick();
    history.pushState(null, null, 'lgbtq-myths');
    return myths.enterAnimation();
  }
  async function goToForm() {
    mode = 'form';
    await tick();
    history.pushState(null, null, 'form');
    checkMobileDevice();
    return form.enterAnimation();
  }
  async function goToWaitList() {
    mode = 'waitlist';
    await tick();
    history.pushState(null, null, 'waitlist');
    checkMobileDevice();
  }

  async function goToDesktopCollection() {
    mode = 'collection';
    await tick();
    history.pushState(null, null, 'riznirivni-collaba');
    return desktopCollection.enterAnimation();
  }

  async function goToDesktopNavigation() {
    mode = 'index';
    await tick();
    history.pushState(null, null, '/');
  }

  async function goToMuseum() {
    mode = 'museum';
    await tick();
    history.pushState(null, null, 'museum-of-acceptance');
    return museum.enterAnimation();
  }
  async function goToMap() {
    mode = 'map';
    await tick();
    history.pushState(null, null, 'map');
    return initMap();
  }

  window.onpopstate = routeChange;

  async function routeChange() {
    const { pathname, search } = window.location;

    if (pathname === '/' || pathname === '/voice') {
      mode = 'index';
      return checkMobileDevice();
    }

    if (pathname === '/demo-map') {
      mode = 'demoMap';
      await tick();
      return mapStep.enterAnimation();
    }

    if (pathname === '/map') {
      mode = 'map';
      await tick();
      return initMap();
    }

    if (pathname === '/museum-of-acceptance') {
      mode = 'museum';
      await tick();
      return museum.enterAnimation();
    }

    if (pathname === '/second-wave') {
      mode = 'secondWave';
      await tick();
      return secondWave.enterAnimation();
    }

    if (pathname === '/about-us') {
      mode = 'about';
      await tick();
      checkMobileDevice();
      return about.enterAnimation();
    }
    if (pathname === '/lgbtq-myths') {
      mode = 'myths';
      await tick();
      return myths.enterAnimation();
    }
    if (pathname === '/lgbtq-rights') {
      mode = 'rights';
      await tick();
      return rights.enterAnimation();
    }
    if (pathname === '/dont-support') {
      mode = 'dontSupport';
      await tick();
      return dontSupport.enterAnimation();
    }

    if (pathname === '/form') {
      mode = 'form';
      await tick();
      return form.enterAnimation();
    }
    if (pathname === '/riznirivni-collaba') {
      mode = 'collection';
      await tick();

      return desktopCollection.enterAnimation();
    }
    if (pathname === '/waitlist') {
      mode = 'waitlist';
      await tick();
      if (!collection) {
        return;
      }
      checkMobileDevice();
      return collection.enterAnimation();
    }
    if (pathname === 'mode' && !isMobile) {
      mode = 'index';
      return desktopNavigation.enterAnimation();
    }
    mode = 'index';
    return checkMobileDevice();
  }

  onMount(() => {
    detectDeviceOrientation();
    checkMobileDevice();
    routeChange();
    if (isMobile) {
      timeLinePreload = new TimelineLite({});
      setTimeout(() => {
        timeLinePreload.set(preloadRef, {
          opacity: 0,
          zIndex: '-1'
        });
      }, 4500);
    }
  });

  function completePreload() {
    if (isMobile) {
      TweenMax.set(preloadRef, { zIndex: -1, opacity: 0 });
    }
  }
</script>

{#if isMobile}
  <Preload bind:this="{preloadRef}" on:completed="{completePreload}" />
  <OrientationAlert bind:this="{orientationAlertRef}" />
{/if}
{#if mode === 'map'}
  <FourthStep mapOnly="{true}" bind:this="{mapStep}" on:back="{goToDesktopNavigation}" />
{/if}

{#if mode === 'video'}
  <Sharing videoParams="{videoId}" sharing="{!hasSelf}" />
{/if}

{#if mode === 'about' && isMobile}
  <About bind:this="{about}" on:back="{goToMain}" on:goToCollection="{goToWaitList}" />
{/if}

{#if mode === 'about' && !isMobile}
  <AboutDesktop
    bind:this="{about}"
    on:goToCollection="{goToWaitList}"
    on:back="{goToDesktopNavigation}"
  />
{/if}

{#if mode === 'myths'}
  <Myths bind:this="{myths}" on:back="{goToMain}" />
{/if}

{#if mode === 'rights'}
  <Rights bind:this="{rights}" on:back="{goToMain}" />
{/if}

{#if mode === 'dontSupport'}
  <DontSupport
    bind:this="{dontSupport}"
    on:goToRights="{goToRights}"
    on:goToMiths="{goToMiths}"
    on:goToMuseum="{goToMuseum}"
    on:goToAbout="{goToAbout}"
    on:goToCollection="{goToWaitList}"
    on:back="{goToMain}"
    on:goToMain="{goToMain}"
  />
{/if}

{#if mode === 'form' && isMobile}
  <Form bind:this="{form}" on:success="{goToMain}" on:goToMain="{goToMain}" />
{/if}

{#if mode === 'form' && !isMobile}
  <FormDesktop bind:this="{form}" on:success="{goToWaitList}" on:back="{goToAbout}" />
{/if}

{#if mode === 'waitlist' && isMobile}
  <Collection
    bind:this="{collection}"
    on:goToMain="{goToMain}"
    on:goToRights="{goToRights}"
    on:goToMiths="{goToMiths}"
    on:goToAbout="{goToAbout}"
    on:goToCollection="{goToWaitList}"
    {successSubmit}
  />
{/if}

{#if mode === 'waitlist' && !isMobile}
  <WaitlistDesktop
    bind:this="{collection}"
    on:goToMain="{goToMain}"
    on:goToRights="{goToRights}"
    on:goToMiths="{goToMiths}"
    on:goToAbout="{goToAbout}"
    on:goToCollection="{goToWaitList}"
    on:back="{goToMain}"
    {successSubmit}
  />
{/if}
{#if mode === 'collection'}
  <AboutCollection bind:this="{desktopCollection}" />
{/if}

{#if mode === 'museum'}
  <Museum bind:this="{museum}" on:back="{goToDontSupport}" />
{/if}

{#if mode === 'demoMap'}
  <FourthStep bind:this="{mapStep}" />
{/if}

{#if mode === 'index' && isMobile}
  <Slider
    on:restart-intro="{restartIntro}"
    on:watch="{onWatch}"
    on:goToAudio="{goToAudio}"
    on:decline="{goToDontSupport}"
    on:goToAbout="{goToAbout}"
    on:goToRights="{goToRights}"
    on:goToMiths="{goToMiths}"
    on:goToCollection="{goToWaitList}"
    on:goToForm="{goToForm}"
    on:goToMain="{goToMain}"
  />
  <AudioRecorder
    bind:this="{audioRecorder}"
    on:recorded="{onRecorded}"
    on:not-allowed="{notAllowed}"
    on:cancel-record="{cancelRecordAudio}"
  />
  <FeedbackForm
    bind:this="{feedbackForm}"
    on:form-submitted="{sendMessage}"
    on:done="{done}"
    on:skip-submit="{done}"
  />
  <Timeline
    alreadyRecorded="{audioOnly}"
    bind:this="{videoTimeline}"
    on:record-sound="{onRecordSound}"
    on:video-end="{onVideoEnd}"
  />
  <Final on:goToStart="{restartFlow}" bind:this="{finalRef}" />
{/if}

{#if mode === 'index' && !isMobile}
  <DesktopNavigation
    bind:this="{desktopNavigation}"
    on:goToMap="{goToMap}"
    on:goToCollection="{goToWaitList}"
    on:goToAboutDesktop="{goToAbout}"
    on:goToForm="{goToForm}"
  />
{/if}
