<style lang="scss">
  @import '../styles/main.scss';
  @import '../styles/variables.scss';

  .counter-wrapper {
    padding: 15px 16px;
    position: absolute;
    opacity: 0;
    background: #ffffff;
    width: 100%;
    @media (min-width: 800px) {
      background: transparent;
      position: relative;
      max-width: 1050px;
      margin: 0 auto;
      padding: 60px 20px 0;
    }

    &-inner {
      @include flex(center, center);
      flex-wrap: nowrap;
      max-width: 450px;
      margin: 0 auto;
      @media (min-width: 800px) {
        width: 100%;
        max-width: 100%;
        justify-content: flex-start;
      }
    }

    .count {
      font-size: 45px;
      line-height: 54px;
      text-align: right;
      text-transform: uppercase;
      color: #000000;
      // min-width: 50%;
      @media (max-width: 780px) {
        font-size: calc(3vw + 4vh);
        text-align: right;
      }
      @media (min-width: 800px) {
        font-size: 24px;
        line-height: 140%;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #000000;
        text-align: left;
      }
    }

    .description {
      margin-left: 14px;
      font-size: 8px;
      line-height: 12px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #000000;
      @media (min-width: 800px) {
        display: none;
      }
    }
  }
  .arrow-btn {
    font-size: 24px;
    line-height: 140%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #000000;
    white-space: nowrap;
    margin-right: 6px;
  }
</style>

<script>
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();
  import { TweenMax } from 'gsap';
  import ArrowBack from './ArrowBack.svelte';
  export let count = 0;

  let counterRef;
  let isMobile;
  isMobile = !window.matchMedia('(min-width: 1024px)').matches;

  export function enterAnimation() {
    TweenMax.set(counterRef, { zIndex: 3 });
    TweenMax.to(counterRef, 1, { opacity: 1 });
  }

  function back() {
    dispatch('back');
  }
</script>

<div class="counter-wrapper" bind:this="{counterRef}">
  <div class="counter-wrapper-inner">
    {#if !isMobile}
      <div class="arrow-btn">
        <ArrowBack on:back="{back}" title="Мапа рівності:" />
      </div>
    {/if}
    <div class="count">{count}</div>
    <div class="description">
      різних та рівних,
      <br />
      які підтримують
      <br />
      права ЛГБТК+
    </div>
  </div>
</div>
