import { v4 as uuid } from 'uuid';

let socket;
let socketRef;

function sendPing() {
  if (!socket) {
    return;
  }

  setInterval(() => socket.send({ action: 'ping' }), 1000 * 30);
}

function sendAction() {
  socket.send({ action: 'wave' });
}

function send(data) {
  socketRef.send(JSON.stringify(data));
}

function onErrorListeners() {
  socketRef.onerror = error => console.log('ERROR ->', error);

  socketRef.onclose = event => {
    if (event.wasClean) {
      console.log('Closed goof');
    } else {
      console.log('Lost connection');
    }
    console.log('code: ' + event.code + ' reason: ' + event.reason);
  };
}

function connectToSocket(url) {
  return new Promise(resolve => {
    socket = new WebSocket(url);
    socket.onopen = () => resolve(socket);
    socket.onerror = () => resolve(null);
  });
}

export function getSocket() {
  return socket;
}

function getUserToken() {
  const token = uuid();
  try {
    const storedToken = localStorage.getItem('userToken');

    if (!storedToken) {
      localStorage.setItem('userToken', token);
      return token;
    }

    return storedToken;
  } catch (e) {
    return token;
  }
}

export async function initSocket(onMessage) {
  // if (socket) {
  //   socket.onmessage = event => {
  //     const parsedData = JSON.parse(event.data);
  //     if (typeof onMessage === 'function') {
  //       onMessage(parsedData);
  //     }
  //   };
  //   return socket;
  // }

  const token = getUserToken();
  socketRef = await connectToSocket(`wss://${apiUrl}?token=${token}`);

  if (!socketRef) {
    return;
  }

  sendPing();
  onErrorListeners();

  socketRef.onmessage = event => {
    const parsedData = JSON.parse(event.data);
    if (typeof onMessage === 'function') {
      onMessage(parsedData);
    }
  };

  const once = (messageType, callback) => {
    return new Promise(resolve => {
      const onEvent = event => {
        const parsedData = JSON.parse(event.data);

        if (parsedData.type === messageType) {
          resolve(parsedData);
          socketRef.removeEventListener('message', onEvent);
        }
      };

      socketRef.addEventListener('message', onEvent);
    });
  };

  socket = {
    sendAction,
    send,
    once
  };

  console.log(socket);

  return socket;
}
