<style lang="scss">
  .header {
    position: absolute;
    z-index: 9999;
    width: 5vw;
    height: 5vw;
    right: 20px;
    top: 20px;

    img {
      width: 100%;
      height: 100%;
    }
    &-icon {
      position: absolute;
      z-index: 2;
    }
  }

  .navigation {
    position: absolute;
    z-index: 1001;
    background: #c6dae3;
    right: 0;
    top: 0;
    bottom: 0;
    width: 78vw;
    text-align: right;
    transform: translate(100%, 0%);
    transition: all 0.3s ease;
    padding: 60px 18px 0;
    @media (min-width: 600px) {
      padding-top: 100px;
    }
    &-content {
      font-size: calc(1vh + 1vw);
      line-height: calc(4vh + 3vw);
      text-align: right;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #000000;
      font-family: 'Transgender Grotesk';
      a {
        display: block;
        text-decoration: none;
        color: #000;
      }
    }
  }
  .substruction {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    opacity: 0;
    background: rgba(#000, 0.3);
    transition: all 0.3s ease;
  }
</style>

<script>
  import gsap, { TweenMax, Back, TimelineLite } from 'gsap';
  import { createEventDispatcher, onMount } from 'svelte';
  import { respondH } from '../helpers/vh';
  import { getGtag } from '../tracking';

  let header;
  let navigation;
  let substruction;
  let headerIcon = 'menu-open';
  let isOpened = false;
  let gtag;

  let dispatch = createEventDispatcher();

  async function trackEventOnce(category, action) {
    if (typeof gtag === 'undefined') {
      gtag = await getGtag();
    }

    gtag.trackEventOnce(category, action);
  }
  async function trackEvent(category, action) {
    if (typeof gtag === 'undefined') {
      gtag = await getGtag();
    }

    gtag.trackEvent(category, action);
  }

  function toggleMenu() {
    isOpened = !isOpened;
    substruction.style.opacity = isOpened ? 1 : 0;
    substruction.style.zIndex = isOpened ? 1000 : -1;
    headerIcon = isOpened ? 'menu-close' : 'menu-open';
    navigation.style.transform = isOpened ? 'translateX(0)' : 'translateX(100%)';
    trackEvent('menu', isOpened ? 'open_menu' : 'close_menu');
  }
  function goToMain() {
    trackEvent('menu', 'home_menu');
    dispatch('goToMain');
  }
  function goToAbout() {
    trackEvent('menu', 'project_menu');
    dispatch('goToAbout');
  }
  function goToRights() {
    trackEvent('menu', 'rights_menu');
    dispatch('goToRights');
  }
  function goToMiths() {
    trackEvent('menu', 'myths_menu');
    dispatch('goToMiths');
  }
  function goToCollection() {
    trackEvent('menu', 'collection_menu');
    dispatch('goToCollection');
  }
</script>

<div class="header" bind:this="{header}" on:click="{toggleMenu}">
  <img src="{`/images/${headerIcon}.svg`}" alt="menu" class="header-icon" />
</div>
<div class="substruction" bind:this="{substruction}" on:click="{toggleMenu}"></div>
<div class="navigation" bind:this="{navigation}">
  <div class="navigation-content">
    <div on:click="{goToMain}">Головна</div>
    <div on:click="{goToAbout}">Про проєкт</div>
    <div on:click="{goToCollection}">де зараз колекція?</div>
    <div on:click="{goToRights}">Права недоступні ЛГБТК+</div>
    <div on:click="{goToMiths}">Міфи про ЛГБТК+</div>
  </div>
</div>
